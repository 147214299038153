<script>
import AccountIntro from '@/admin/components/AccountIntro.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import ExportButton from '@/admin/components/ExportButton.vue';
import DebugLine from '@/admin/components/DebugLine.vue';
import InfoLine from '@/admin/components/InfoLine.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { AdminSpinner, AccountIntro, InfoLine, ExportButton, DebugLine },

  metaInfo() {
    return { title: this.account.name || 'Account' };
  },

  computed: {
    ready() {
      return this.account.id == this.$route.params.accountId;
    },

    ...mapState(['account']),
    ...mapGetters('user', ['isCreate', 'isAdmin']),
    ...mapGetters(['dashUri']),
  },

  methods: {
    fetch() {
      this.$store.dispatch('account/fetch', { id: this.$route.params.accountId, clear: true });
    },

    freeze() {
      this.$api(`accounts/${this.account.id}/freeze`).then(({ data }) => {
        this.$store.commit('account/patch', data);
        this.$store.commit('toast/info', `Account ${data.frozen ? 'frozen' : 'unfrozen'}`);
      });
    },
  },

  watch: {
    '$route.params.accountId'(to, from) {
      this.fetch();
    },
  },

  mounted() {
    if (!this.isCreate) this.$router.replace(this.dashUri);
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" class="loader-lg" hide>
    <div class="container pt-8">
      <div class="lg:flex items-start shrink-0">
        <div class="order-2 shrink-0">
          <div class="flex justify-end">
            <!-- real pe menu -->
            <div class="items-center justify-center px-2 mb-4 text-center bg-white rounded flex">
              <RouterLink :to="`${account.uri}`" class="ma-link exact">Membership</RouterLink>
              <RouterLink :to="`${account.uri}users`" class="ma-link">Users</RouterLink>
              <RouterLink v-if="account.hasHome" :to="`${account.uri}home`" class="ma-link">Home Learning</RouterLink>
              <RouterLink v-if="account.totalTraining" :to="`${account.uri}training`" class="ma-link">
                Training
              </RouterLink>
            </div>
            <!-- bursts menu -->
            <div
              v-if="account.hasBursts"
              class="items-center justify-center px-2 mb-4 text-center bg-white rounded flex ml-2"
            >
              <RouterLink :to="`${account.uri}bursts/groups`" class="ma-link2">Classes</RouterLink>
              <RouterLink v-if="account.wondeBursts" :to="`${account.uri}bursts/year`" class="ma-link2">
                BURSTS Data
              </RouterLink>
            </div>
          </div>

          <div class="btns4">
            <button v-if="isAdmin" class="btn4-clear" @click="freeze">
              {{ account.frozen ? 'Unfreeze' : 'Freeze' }} Account
            </button>
            <ExportButton v-if="isAdmin" label="Account" :path="`accounts/${account.id}`" />
            <a v-if="account.zohoUrl" :href="account.zohoUrl" class="btn4 bg-theme" target="_blank">View on Zoho</a>
          </div>
        </div>

        <AccountIntro :account="account" class="order-1 flex-grow mr-4" />
      </div>

      <hr />
    </div>

    <RouterView :key="$route.path" :account="account" @update="fetch" />

    <div class="container">
      <DebugLine type="account" :object="account" />
    </div>
  </AdminSpinner>
</template>
