<script>
import ToastItem from '@/global/components/ToastItem.vue';
import { mapState } from 'vuex';

export default {
  components: { ToastItem },
  computed: mapState('toast', ['toasts']),
};
</script>

<template>
  <div class="z-40 fixed inset-x-0 pt-16 pointer-events-none">
    <div class="container">
      <TransitionGroup name="toast" tag="div" class="ml-auto flex flex-col-reverse mt-2" style="width: 400px">
        <ToastItem v-for="toast in toasts" :key="toast.id" v-bind="toast" />
      </TransitionGroup>
    </div>
  </div>
</template>
