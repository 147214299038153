<script>
import AbilityGoals from '@/content/components/partials/AbilityGoals.vue';
import EliteButton from '@/content/components/buttons/EliteButton.vue';
import SkillGoals from '@/content/components/partials/SkillGoals.vue';
import UnitAbility from '@/content/components/lesson/UnitAbility.vue';
import TextBlock from '@/content/components/partials/TextBlock2.vue';
import UnitSkill from '@/content/components/lesson/UnitSkill.vue';
import Brand from '@/global/components/Brand.vue';
import Link from '@/global/components/Link2.vue';
import routeInfo from '@/mixins/routeInfo.js';
import { zip, undupe } from '@/scripts/filters.js';
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/ring-back.svg';

const lsKey = 'orgCurriculum';

export default {
  components: { UnitAbility, UnitSkill, TextBlock, AbilityGoals, SkillGoals, Brand, EliteButton, Link },
  props: ['unit', 'ability', 'uri'],
  mixins: [routeInfo],
  data: () => ({ currId: 'en' }),

  metaInfo() {
    return { title: `${this.productInfo.title}: Knowledge Organiser - ${this.title}` };
  },

  computed: {
    title() {
      return this.crumbsTitle.slice(1).join(', ');
    },

    learningColour() {
      return this.isGym ? 'gymcard' : 'physical';
    },
    yearLabel() {
      return this.productInfo.year[this.$route.params.year].title;
    },
    curriculum() {
      return this.currId === 'cy' ? this.unit.curriculum2 : this.unit.curriculum1;
    },

    skills() {
      const source = this.unit.koSkills || this.unit.funsStations || this.unit.lessons || [];
      const danceYear = [0, 0, 1, 2, 3, 4, 5][this.$route.params.year];

      return source.map((source, i) => {
        if (this.isPe) return { ...source.cards[0], ...source };
        if (this.isGym) return source;

        if (this.isDance) {
          const { displayTitle: title, label, skills } = source.skill?.[0] || {};
          return { ...source, title, label, ...skills?.[danceYear] };
        }
      });
    },

    ability$() {
      if (this.isPe) {
        const whats = this.ability.abilityWhat;

        const combined = this.unit.lessons.flatMap((lesson, i) => {
          const filtered = whats.filter(what => what[`l${i + 1}`]);
          return zip(filtered, lesson.abilityHow);
        });

        return {
          ...this.ability,
          abilityHow: whats.map(({ what: what2 }) => ({
            hows: [...new Set(combined.filter(({ what }) => what === what2).map(({ how }) => how))],
          })),
        };
      }

      return this.ability;
    },

    keywords() {
      const lesson = (this.unit.lessons || []).flatMap(l => l.keywords ?? []);
      const ability = this.ability.keywords || [];
      return undupe([...lesson, ...ability]).join(', ');
    },

    ...mapGetters('menu', ['crumbsTitle']),
  },

  methods: {
    setCurrId(id) {
      this.currId = id;
      localStorage.setItem(lsKey, id);
    },

    print: () => window.print(),
  },

  created() {
    this.currId = localStorage.getItem(lsKey) || 'en';
  },
};
</script>

<template>
  <div class="container">
    <TextBlock>
      <div class="flex justify-between items-center">
        <div>
          <div class="pre-title">Knowledge Organiser</div>
          <div class="text-4xl print:text-2xl font-bold text-pe">{{ title }}</div>
        </div>
        <div class="flex items-center -mx-1">
          <EliteButton :uri="uri" icon="ring-back" label="Back to Unit" class="mx-1" />
          <EliteButton icon="print" label="Print" class="mx-1" @click="print" />
          <Brand :slug="productSlug" class="h-12 mx-1 print:h-8" />
        </div>
      </div>
    </TextBlock>

    <TextBlock colour="pe" highlight>
      <h2 class="teacher-title">Purpose</h2>
      <div v-html="unit.purpose" class="rdct rdct-ko"></div>
    </TextBlock>

    <h2 class="teacher-title text-center mt-2">Learning Goals</h2>

    <div class="t-row">
      <UnitAbility :unit="unit" :ability="ability" />
      <UnitSkill :unit="unit" />
    </div>

    <h2 class="teacher-title text-center mt-2">Skills and Knowledge - Learning Behavour</h2>

    <TextBlock icon="cog" :colour="ability.slug">
      <h2 class="teacher-title text-center">{{ this.ability.title }} Skills</h2>
      <AbilityGoals :ability="ability$" />
    </TextBlock>

    <h2 class="teacher-title text-center mt-2">
      Skills and Knowledge
      <template v-if="isPe"> - FMS</template>
      <template v-else-if="isGym"> - gym Skills</template>
      <template v-else-if="isDance"> - dance Focus</template>
    </h2>

    <TextBlock v-for="skill in skills" :key="skill.id" :colour="learningColour" highlight>
      <SkillGoals :skill="skill">
        <div v-html="skill.challenges" class="rdct rdct-ko mb-4 border-b border-grey-l2 pb-4"></div>
      </SkillGoals>
    </TextBlock>

    <TextBlock colour="pe" highlight>
      <h2 class="teacher-title">Key Vocabulary</h2>
      <div v-html="keywords" class="rdct rdct-ko lowercase"></div>
    </TextBlock>

    <TextBlock colour="pe" highlight>
      <div class="flex items-start justify-between">
        <h2 class="teacher-title">Curriculum Links</h2>

        <div class="flex border-2 rounded-lg leading-none ml-6 text-sm overflow-hidden border-pe print:hidden">
          <button :class="currId === 'en' ? 'bg-pe-shade' : ''" class="px-3 py-2 rounded" @click="setCurrId('en')">
            English
          </button>
          <button :class="currId === 'cy' ? 'bg-pe-shade' : ''" class="px-3 py-2 rounded" @click="setCurrId('cy')">
            Welsh
          </button>
        </div>
      </div>

      <div v-html="curriculum" class="rdct rdct-ko"></div>
    </TextBlock>

    <TextBlock colour="pe" highlight>
      <h2 class="teacher-title">Wider Links</h2>
      <div v-html="unit.wider" class="rdct rdct-ko"></div>

      <div v-if="unit.links && unit.links.length" class="border-t border-grey-l2 pt-3 mt-3 print:hidden">
        <Link v-for="link in unit.links" :key="link.id" :uri="link.url" class="nm1">{{ link.title }}</Link>
      </div>
    </TextBlock>
  </div>
</template>
