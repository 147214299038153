<script>
import Dots from '@/admin/components/Dots.vue';
import { address } from '@/scripts/filters.js';
import '@/assets/svgs/icons/wonde.svg';

const sides = { 30: 'text-pe', 20: 'text-pe-shade', 10: 'text-blank' };

export default {
  components: { Dots },
  props: ['account'],

  computed: {
    side() {
      return sides[this.account.status] || 'text-blank-shade';
    },
    address() {
      return address(this.account);
    },
  },
};
</script>

<template>
  <RouterLink :to="`/accounts/${account.id}`" :class="side" class="flex flex-col p-4 text-sm card outlne h-full">
    <div
      :class="account.status > 10 ? 'text-pe' : 'text-blank'"
      class="flex justify-between mb-1 text-lg font-bold account-title"
    >
      {{ account.name }}
      <div class="shrink-0 flex -mx-0.5">
        <img v-if="account.wondeRealPe" src="/images/wonde-realpe.svg" title="Wonde: real PE" class="h-3 ml-2" />
        <img v-if="account.wondeBursts" src="/images/wonde-bursts.svg" title="Wonde: BURSTS" class="h-3 ml-2" />
      </div>
    </div>
    <div :class="account.status < 30 ? 'text-blank' : 'text-black'" class="mb-2">{{ address }}</div>
    <div class="flex-grow"></div>
    <div class="flex justify-between items-end">
      <Dots :access="account.accessProducts" :bursts="account.hasBursts" class="mt-2" />
      <div class="uppercase text-xs font-bold tracking-widest leading-none text-blank">
        {{ account.statusLabel }} • {{ account.accountType }}
      </div>
    </div>
  </RouterLink>
</template>
