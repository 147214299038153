import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import $gql from '@/scripts/gql.js';
import $api from '@/scripts/api.js';
import diceQuery from '@/queries/dice.gql';
import globalQuery from '@/queries/global.gql';

import menu from './menu.js';
import toast from './toast.js';
import account from './account.js';
import user from './user.js';

import jwt from '@/scripts/jwt.js';

Vue.use(Vuex);

const productSlugs = { core: 'pe', daily: 'home' };

export default new Vuex.Store({
  modules: { menu, toast, account, user },

  state: {
    s3: 'https://crstl-assets.s3-eu-west-2.amazonaws.com/jasmine/',
    view: localStorage.getItem('real-view') === 'true',
    passthrough: '',
    loggedIn: null,
    dice: [],
    dicePromise: null,
    ready: false,

    hasGlobal: false,
    support: [],
    dashboard: {},
    menuExtra: [],
    showTrialModal: 0,
  },

  mutations: {
    viewToggle(state, val = null) {
      state.view = val !== null ? val : !state.view;
      localStorage.setItem('real-view', state.view);
    },
    setDice(state, dice) {
      state.dice = dice;
    },
    setDicePromise(state, promise) {
      state.dicePromise = promise;
    },
    setGlobal(state, { support, dashboard, peYears, gymYears, danceYears }) {
      state.support = support.map(group => ({ ...group, product: productSlugs[group.product] || group.product }));
      state.menuExtra = { pe: peYears, gym: gymYears, dance: danceYears };
      state.dashboard = dashboard;
      state.hasGlobal = true;
    },

    setTrialModal(state, val) {
      state.showTrialModal = val;
    },
  },

  getters: {
    dashUri: ({ user }, getters) => {
      if (getters['user/isHome']) return '/home';
      if (!user.hasRealPe && user.hasBursts && !getters['user/isCreate']) return '/bursts';
      return '/';
    },

    forumUrl: () => process.env.VUE_APP_FORUM,
    lmsUrl: () => process.env.VUE_APP_LMS,
    pfwUrl: () => process.env.VUE_APP_PFW,

    support: (state, getters) => {
      const hasPartner = getters['user/isCreate'] || getters['user/isPartner'];

      return state.support
        .filter(group => {
          if (group.slug === 'partner') return hasPartner;
          if (group.product === 'bursts') return getters['user/hasBursts'];
          if (group.product) return getters['user/hasProduct'](group.product);
          return true;
        })
        .map(group => {
          const { children: trash, ...node } = group;
          group.children.unshift({ ...node, title: 'General' });

          return group;
        });
    },
  },

  actions: {
    async init({ state, dispatch, commit }) {
      if (state.route.meta.auth === false) {
        return; // ignore auth for password reset / user register / bursts tools page
      } else if (!jwt.get()) {
        dispatch('logOut', { passthrough: true });
      } else {
        dispatch('fetchAuth');
      }
    },

    async logIn({ state, dispatch, getters }, user) {
      jwt.set(user.token);

      dispatch('setAuth', user);

      if (state.passthrough) {
        router.push(state.passthrough);
        console.log('passthrough used', state.passthrough);
        state.passthrough = '';
      } else {
        router.push(getters.dashUri);
        console.log('redirected to dashboard (login)');
      }
    },

    logOut({ state, commit }, { redirect = true, passthrough, toast } = {}) {
      if (state.loggedIn === false) return;

      const path = state.route.path;
      const isLogin = path.startsWith('/login');

      if (passthrough && path && !isLogin && path !== '/') {
        state.passthrough = state.route.fullPath;
        console.log('passthrough set', state.passthrough);
      }

      state.loggedIn = false;
      jwt.remove();

      commit('user/clear');
      commit('account/clear');
      commit('menu/toggle', false);

      if (redirect && !isLogin) {
        router.replace('/login');
        console.log('redirected to login (logout)');
      }

      if (toast === 'expired') commit('toast/error', 'Your session has expired. Please log in again.');
    },

    async fetchAuth({ dispatch }) {
      return $api
        .get('auth/self')
        .then(response => {
          dispatch('setAuth', response.data);
        })
        .catch(() => {
          dispatch('logOut', {});
        });
    },

    async setAuth({ state, commit }, user) {
      commit('user/set', user);

      if (!state.hasGlobal)
        $gql(globalQuery, {}, { schema: 'global' }).then(res => {
          commit('setGlobal', res);
        });

      state.loggedIn = true;
      state.ready = true;
    },

    async loadDice({ state, commit }) {
      if (state.dice.length) return state.dice;
      if (state.dicePromise) return state.dicePromise;

      const site = state.route.params.product;
      const promise = $gql(diceQuery, { site }).then(res => {
        commit('setDice', res.dice);
      });

      commit('setDicePromise', promise);
      return promise;
    },
  },
});
