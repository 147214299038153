<script>
import routeInfo from '@/mixins/routeInfo.js';
import Hero from '@/content/components/partials/Hero.vue';
import PartSteps from '@/content/components/activities/PartSteps.vue';
import VimeoText from '@/content/components/partials/VimeoText.vue';
import AudioPlayer from '@/content/components/partials/AudioPlayer.vue';
import VideoButton from '@/content/components/buttons/VideoButton.vue';
import JasmineButton from '@/content/components/buttons/JasmineButton.vue';
import CogModal from '@/content/components/modal/CogModal.vue';
import DiceModal from '@/content/components/modal/DiceModal.vue';
import HowToModal from '@/content/components/modal/HowToModal.vue';
import ThinkModal from '@/content/components/modal/ThinkModal.vue';
import ReviewModal from '@/content/components/modal/ReviewModal.vue';
import PhysicalModal from '@/content/components/modal/PhysicalModal.vue';
import Song from '@/content/components/activities/Song.vue';

export default {
  // prettier-ignore
  components: {
    Song, Hero, PartSteps, VimeoText, AudioPlayer, VideoButton, JasmineButton, 
    CogModal, DiceModal, HowToModal, ThinkModal, ReviewModal, PhysicalModal, 
  },

  props: { lesson: {}, theme: {}, ability: {}, skill: {}, part: {} },
  data: () => ({ seconds: 0, showVideo: false, step: 0 }),
  mixins: [routeInfo],

  computed: {
    showPhysical() {
      return ['story', 'game', 'review'].includes(this.part.type);
    },
    entry() {
      const { stage, type } = this.$route.params;
      return stage === 'funs' && type === 'skill' ? this.theme : this.part;
    },
    page() {
      return this.entry.steps ? this.entry.steps[this.step] : this.entry;
    },
  },

  methods: {
    stepUpdate(step) {
      this.$emit('step', step);
      this.step = step;
    },
  },
};
</script>

<template>
  <div class="bg-white curves parts-offset">
    <div class="box-contain">
      <div class="box-title">
        <h1 :class="`text-${productSlug}`">{{ part.title }}</h1>
        <div class="pl-1 text-2xl font-medium">
          <div class="tune" v-if="part.tune">To the tune of: {{ part.tune }}</div>
          <div class="personal-best" v-else-if="part.pb">Personal Best Challenge</div>
        </div>
      </div>

      <div class="space-x-2 box-buttons">
        <VideoButton v-if="part.video && part.type !== 'song'" @click="showVideo = !showVideo" />
        <DiceModal :dice="part.dice" />
        <AudioPlayer :audio="part.audioFile" icon="voice" />
        <AudioPlayer :audio="part.audioMusic" @tick="seconds = $event" progress loop />

        <template v-if="isPlay">
          <ReviewModal :ability="ability" :number="part.reviewNumber" v-if="part.reviewNumber" />
          <PhysicalModal :skill="skill" v-if="showPhysical" />
          <CogModal :lesson="lesson" :ability="ability" v-if="lesson.id" />
        </template>

        <template v-else-if="isHome">
          <ThinkModal :think="page.thoughtAbout" v-if="page.thoughtAbout" />
          <HowToModal :how="page.howToPlay" v-if="page.howToPlay" />
          <JasmineButton :text="page.jasmineText" v-if="page.jasmineText" />
        </template>
      </div>
    </div>

    <template v-if="!showVideo">
      <Hero :asset="part.heroImage" />
      <PartSteps :part="entry" :key="part.id" @change="stepUpdate" />
    </template>
    <div v-else-if="part.video" class="mt-2 mb-12">
      <VimeoText :vid="part.video" :key="part.video" :lines="part.textLines" :colour="productSlug" autoplay />
    </div>

    <div v-if="part.pupilText && !theme?.id" v-html="part.pupilText" class="mt-4 px-8 rdct rdct-pupil"></div>

    <Song v-if="part.type === 'song'" :part="part" :seconds="seconds" />
  </div>
</template>
