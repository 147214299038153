import $api from '@/scripts/api.js';
import store from '@/store';

export default function (url, params = {}, filename) {
  return $api
    .get(url, { params, responseType: 'arraybuffer' })
    .then(response => {
      const link = document.createElement('a');
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const match = /filename="(.+)"/.exec(response.headers['content-disposition']);

      link.href = window.URL.createObjectURL(blob);
      link.download = filename || (match ? match[1] : 'export.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      store.commit('toast/error', 'There was a problem with the export');
    });
}
