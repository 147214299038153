<script>
import AdminModal from '@/admin/components/AdminModal.vue';
import Vimeo from '@/global/components/Vimeo2.vue';
import Brand from '@/global/components/Brand.vue';
import Link from '@/global/components/Link2.vue';

import { mapState, mapGetters } from 'vuex';

const plurals = { year: 'Years', stage: 'Stages', unit: 'Units', funs: 'FMS' };
const plural = type => plurals[type] || type;

export default {
  components: { Brand, Link, AdminModal, Vimeo },
  data: () => ({ l2Slug: null, showVideo: false }),

  computed: {
    support$() {
      return (this.support.find(s => s.product === this.current.slug) || {}).children;
    },

    products() {
      const access = this.$store.state.user.access;

      return ['pe', 'gym', 'dance', 'foundations', 'play', 'leaders', 'home'].map(slug => {
        const active = this.isCreate || !!(access[slug] && access[slug].length);
        const action = slug === 'leaders' ? { uri: '/leaders' } : { on: { click: () => this.selectL1({ slug }) } };
        return { slug, active, ...(active && action) };
      });
    },

    level2() {
      const extra = this.$store.state.menuExtra[this.current.slug] || [];
      return (this.current.children || []).map(child => {
        const part1 = ['play', 'foundations'].includes(this.current.slug) ? '' : `${child.title}`;
        const part2 = plural(child.children[0]?.type);

        return {
          ...child,
          group: plural(child.type),
          childGroup: `${part1} ${part2}`,
          mapGroup: `${part1} Guides`,
          mapLabel: this.current.slug === 'play' || child.type === 'year' ? 'Curriculum Map' : `${part2} Menu`,
          ...extra.find(e => e.number === child.slug),
        };
      });
    },

    currentL2() {
      const year = this.$route.params.year;
      return this.level2.find(l2 => l2.slug === this.l2Slug) || (this.level2.length > 1 ? {} : this.level2[0]);
    },

    level3() {
      if (this.currentL2.type === 'year' && this.current.abilitySelect) {
        return this.currentL2.children.map(child => ({
          ...child,
          ability: this.chosenAbilitySlug(child.cmsSlug, this.current.slug) || 'blank',
        }));
      }

      return this.currentL2.children || [];
    },

    videoTitle() {
      return `${this.currentL2?.title} Video Guide`;
    },

    ...mapState('menu', ['open']),
    ...mapGetters('menu', ['current']),
    ...mapGetters('user', ['isHome', 'isCreate', 'chosenAbilitySlug']),
    ...mapGetters(['support']),
  },

  methods: {
    selectL1(product) {
      this.l2Slug = null;
      this.$store.dispatch('menu/select', product);
    },
    selectL2(year) {
      this.l2Slug = year.slug;
    },

    logOut() {
      this.close();
      this.$store.dispatch('logOut');
    },
    close() {
      if (this.open) this.$store.commit('menu/toggle', false);
    },
  },

  watch: { $route: 'close' },
};
</script>

<template>
  <div>
    <transition name="fade">
      <div class="w-screen h-screen overflow-scroll pe-menu" v-show="open" @mousedown.self="close">
        <div class="container mt-4 mb-20 px-2">
          <div class="flex justify-center -mx-1 lg:-mx-2" v-if="!isHome">
            <Link
              v-for="product in products"
              :key="product.slug"
              :uri="product.uri"
              :class="[
                product.slug === current.slug ? `bg-blank-shadex` : '',
                product.active ? `border-${product.slug}` : '',
                // product.active && links ? 'hover:bg-blank-shade cursor-pointer' : '',
                // enhanced ? 'border-grey-l2' : '',
              ]"
              :active="product.active"
              class="dash-block w-auto mx-1 lg:mx-2 shdx"
              v-on="product.on"
            >
              <Brand
                :slug="product.slug"
                :class="product.active ? '' : 'opacity-40 grayscale'"
                alt="popup"
                class="h-12 lg:h-16"
              />
            </Link>
          </div>
          <transition name="fade-quick" mode="out-in">
            <div
              v-if="current.slug"
              :key="current.slug"
              class="p-3 learning-bodyx bg-blank-shade rounded-xl mt-4 shadow"
            >
              <div class="flex-wrap items-centerx justify-between lg:flex">
                <h1 :class="`text-${current.slug}`" class="shrink-0 mr-10 mb-4 mt-2 ml-1">{{ current.title }}</h1>
                <div class="flex mb-4 mt-1">
                  <div v-if="current.uri" class="flex p-2 ring-border mr-2">
                    <div class="p-1">
                      <RouterLink :to="`/${current.slug}`" class="pem4 outlne exact block text-base py-2">
                        Intro
                      </RouterLink>
                    </div>
                  </div>
                  <div class="flex p-2 ring-border">
                    <div class="ring-label">Support</div>
                    <div v-for="item in support$" class="p-1">
                      <RouterLink :key="item.slug" :to="item.uri" class="pem4 outlne exact block text-base py-2">
                        {{ item.title }}
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <pre>{{ json }}</pre> -->
              <div v-if="level2.length > 1" class="flex justify-center">
                <div class="ring-border max-w-full">
                  <div class="ring-label">{{ level2[0].group }}</div>
                  <div class="flex -mx-1 items-center justify-center">
                    <div v-for="l2 in level2" class="px-1 relative pem4-col flex-1">
                      <button class="pem4 outlne relative z-10 w-full" @click="selectL2(l2)">
                        {{ l2.title }}
                      </button>
                      <div class="absolute -bottom-7 left-1/2 -translate-x-1/2 text-center">
                        <div
                          class="bg-white rounded transition-opacity duration-200 inline-block py-1 pt-2 px-1 shadow -mt-2"
                          :class="{ 'opacity-0': currentL2 && currentL2.slug !== l2.slug }"
                        >
                          <svg class="w-6 h-3 fill-pe">
                            <use xlink:href="#chevron2" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fade-quick" mode="out-in">
                <div v-if="currentL2.title" :key="currentL2.slug">
                  <div class="flex justify-center mt-4">
                    <div class="ring-border">
                      <div class="ring-label">{{ currentL2.childGroup }}</div>
                      <div class="flex flex-wrap -m-1 justify-center">
                        <div v-for="l3 in level3" class="p-1 pem4-col">
                          <RouterLink :to="l3.uri" class="pem4 outlne flex items-center justify-center h-full">
                            <div>
                              <div class="text-xl">{{ l3.title }}</div>
                              <div :class="`text-${l3.ability}`" class="font-bold capitalize">{{ l3.ability }}</div>
                              <div v-if="l3.focus" class="mt-1">
                                <div v-for="focus in l3.focus" class="text-sm">{{ focus }}</div>
                              </div>

                              <!-- TODO: show funs skill on foundations themes ? -->
                            </div>
                          </RouterLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center">
                    <div class="ring-border max-w-full mt-4">
                      <div class="ring-label">{{ currentL2.mapGroup }}</div>
                      <div class="flex justify-center -mx-1">
                        <RouterLink :to="currentL2.uri" class="pem4 outlne w-64 mx-1 capitalize">
                          {{ currentL2.mapLabel }}
                        </RouterLink>
                        <button
                          v-if="currentL2.video"
                          :to="currentL2.uri"
                          class="pem4 outlne w-64 mx-1 capitalize"
                          @click="showVideo = true"
                        >
                          Video Guide
                          <svg class="btn4-icon text-gymcard"><use xlink:href="#video" /></svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <AdminModal :active="showVideo" :title="videoTitle" wide @close="showVideo = false">
      <Vimeo :vid="currentL2?.video" class="-m-2 rounded-br-xl" />
    </AdminModal>
  </div>
</template>
