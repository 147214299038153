// resource v2 used in admin area

import $api from '@/scripts/api.js';
import Vue from 'vue';
import store from '@/store';
import { parseLinkHeader } from '@web3-storage/parse-link-header';

class Collection {
  constructor(item, path, config = {}) {
    this.$path = path;
    this.$resolved = false;
    this.$fetched = false;
    this.$ready = true;
    this.$name = config.name;
    this.empty = false;
    this.totalItems = 0;
    this.totalPages = 0;
    this.currentPage = 0;
    this.link = {};
  }

  $get(params = {}) {
    this.$resolved = false;
    this.$ready = false;

    const { url, query } = this.$parseUrl({ ...this, ...params }, params);

    return $api
      .get(url, { params: query })
      .then(res => {
        this.$ready = true;
        this.$resolved = true;
        this.$fetched = true;
        this.totalItems = parseInt(res.headers['x-pagination-total-count']);
        this.totalPages = parseInt(res.headers['x-pagination-page-count']);
        this.currentPage = parseInt(res.headers['x-pagination-current-page']);
        this.link = res.headers.link ? parseLinkHeader(res.headers.link) : {};
        this.empty = !this.totalItems;

        return res.data;
      })
      .catch(this.$error.bind(this));
  }

  $error(error) {
    this.$ready = true;
    return Promise.reject(error);
  }

  $parseUrl(all, query) {
    const url = this.$path.replace(/(\/?){([a-zA-Z0-9]+)}/g, (match, slash, slug) => {
      if (all[slug]) {
        delete query[slug];
        return slash + all[slug];
      }
      return '';
    });

    return { url, query };
  }
}

class Resource {
  constructor(item, path, config = {}) {
    this.$path = path;
    this.$resolved = true;
    this.$ready = true;
    this.$fetched = false;
    this.$name = config.name;

    const base = { ...config.default, ...item };

    Object.keys(base).forEach(key => {
      this[key] = base[key];
    });

    (config.calls || []).forEach(call => {
      this[call.name] = (params = {}, data = {}) => {
        this.$resolved = false;
        this.$ready = false;

        return $api
          .request({ method: call.method, url: this.$parseUrl({ ...this, ...params }, call.uri), data })
          .then(expand.bind(this))
          .catch(this.$error.bind(this));
      };
    });
  }

  $load(data) {
    expand.bind(this)({ data });
  }

  $get(params = {}) {
    this.$resolved = false;
    this.$ready = false;

    return $api
      .get(this.$parseUrl({ ...this, ...params }))
      .then(expand.bind(this))
      .catch(this.$error.bind(this));
  }

  $create(params = {}) {
    this.$ready = false;

    return $api
      .post(this.$parseUrl({ ...this, ...params }), this)
      .then(expand.bind(this))
      .then(res => {
        this.$ready = true;
        store.commit('toast/info', `${this.$name} Added`);
        return res;
      })
      .catch(this.$error.bind(this));
  }

  $update(params = {}) {
    this.$ready = false;

    return $api
      .put(this.$parseUrl({ ...this, ...params }), this)
      .then(expand.bind(this))
      .then(res => {
        this.$ready = true;
        store.commit('toast/info', `${this.$name} Saved`);
        return res;
      })
      .catch(this.$error.bind(this));
  }

  $delete(params = {}) {
    this.$ready = false;

    return $api
      .delete(this.$parseUrl({ ...this, ...params }))
      .then(res => {
        this.$ready = true;
        store.commit('toast/info', `${this.$name} Deleted`);

        return res;
      })
      .catch(this.$error.bind(this));
  }

  $error(error) {
    this.$ready = true;
    return Promise.reject(error);
  }

  $parseUrl(params, extra = '') {
    return (this.$path + extra).replace(/(\/?){([a-zA-Z0-9]+)}/g, (match, slash, slug) =>
      params[slug] ? slash + params[slug] : ''
    );
  }
}

const expand = function (resource) {
  this.$ready = true;
  this.$resolved = true;
  this.$fetched = true;

  if (resource.data) {
    Object.keys(resource.data).forEach(key => {
      Vue.set(this, key, resource.data[key]);
    });
  }

  return resource;
};

export default class {
  constructor(path, config) {
    this.path = path;
    this.config = config;
  }

  one(params = {}) {
    return new Resource(params, this.path, this.config);
  }

  all(params = {}) {
    return new Collection(params, this.path, this.config);
  }
}
