<script>
import AccountIntro from '@/admin/components/AccountIntro.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import TrialModal from '@/global/components/TrialModal.vue';
import TrialBox from '@/global/components/TrialBox.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { AdminSpinner, AccountIntro, TrialBox, TrialModal },

  metaInfo() {
    return { title: '', titleTemplate: t => `My Account ${t ? '> ' + t : ''} | real PE` };
  },

  computed: {
    ...mapState(['account']),
    ...mapGetters('user', ['isCreate', 'isAdmin']),
    ...mapGetters(['dashUri']),
  },

  methods: {
    fetch() {
      this.$store.dispatch('account/fetch', { clear: true });
    },
  },

  mounted() {
    if (this.isCreate) this.$router.replace('/accounts');
    else if (!this.isAdmin) this.$router.replace(this.dashUri);
    else this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :ready="!!this.account.id" class="loader-lg" hide>
    <div class="container my-10">
      <div class="lg:flex items-start shrink-0">
        <div v-if="account.hasRealPeFull || account.hasBursts" class="order-2 shrink-0">
          <div class="px-2 mb-4 text-center bg-white rounded flex">
            <RouterLink to="/account/users" class="ma-link">Users</RouterLink>
            <RouterLink v-if="account.hasHome" to="/account/home" class="ma-link">Home Learning</RouterLink>
            <RouterLink v-if="account.totalTraining" to="/account/training" class="ma-link">Training</RouterLink>
          </div>
        </div>

        <AccountIntro :account="account" class="order-1 flex-grow mr-4" />

        <TrialBox v-if="account.hasRealPeTrial" class="bg-white rounded p-4 order-2 mt-4 lg:ml-4 lg:mt-0">
          <div class="text-lg font-bold text-pe mb-1">real PE Trial</div>
        </TrialBox>
      </div>

      <hr />
    </div>

    <RouterView :key="$route.path" :account="account" @update="fetch" />

    <TrialModal />
  </AdminSpinner>
</template>
