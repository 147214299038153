<script>
import AbilityGoals from '@/content/components/partials/AbilityGoals.vue';
import Modal from '@/content/components/partials/Modal.vue';
import Cog from '@/content/components/partials/Cog.vue';

export default {
  components: { AbilityGoals, Modal, Cog },
  props: ['lesson', 'ability', 'text'],
  data: () => ({ modal: false }),
};
</script>

<template>
  <div v-if="ability">
    <Modal :open="modal" :bg="ability.slug" icon="cog" wide @close="modal = false">
      <div v-if="text" v-html="text" class="rdct rdct-modal"></div>
      <AbilityGoals v-else :ability="ability" :lesson="lesson" class="text-3xl" />
    </Modal>

    <button class="rounded-full cog block" @click="modal = true">
      <Cog :ability="ability" shade />
    </button>
  </div>
</template>

<style lang="postcss" scoped>
.cog {
  /* margin-left: -2.5px; */
  margin-right: -2.5px;

  @screen lg {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
}
</style>
