<script>
import FormControl from '@/admin/components/FormControl.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

const user = { firstName: '', lastName: '', group: '' };

export default {
  components: { FormControl, AdminSpinner },
  props: ['active', 'account'],
  mixins: [validationMixin],
  data: () => ({ users: [{ ...user }], ready: true }),

  validations: {
    users: {
      $each: {
        firstName: { required: requiredIf(p => p.lastName) },
        lastName: { required: requiredIf(p => p.firstName) },
        group: {},
      },
    },
  },

  computed: {
    validUsers() {
      return Object.values(this.$v.users.$each.$iter)
        .filter(p => !p.$invalid && p.$model.firstName)
        .map(p => p.$model);
    },
  },

  methods: {
    addRow() {
      this.users.push({ ...user });
    },
    removeRow(row, { target }) {
      this.users.splice(parseInt(row), 1);
      this.$nextTick(() => target.blur());
    },
    close() {
      this.users = [{ ...user }];
      this.$v.$reset();
      this.$emit('close');
    },

    addUsers() {
      this.$v.$touch();

      if (this.ready && !this.$v.$invalid && this.validUsers.length) {
        this.ready = false;
        this.$api
          .post('users/home/bulk', this.validUsers, { params: { accountId: this.account.id } })
          .then(res => {
            this.ready = true;
            this.$emit('update', res.data);
            this.close();
          })
          .catch(error => {
            this.ready = true;
            console.error(error);
          });
      }
    },
  },

  watch: {
    validUsers(val) {
      if (val.length === this.users.length) {
        this.addRow();
      }
    },
  },
};
</script>

<template>
  <AdminSpinner v-if="active" :ready="ready">
    <form @submit.prevent="addUsers" class="bg-white rounded-lg p-5 my-6">
      <div class="flex font-bold -mx-2 mb-1">
        <label for="firstName" class="w-1/3 px-2">First Name</label>
        <label for="lastName" class="w-1/3 px-2">Last Name</label>
        <label for="group" class="w-1/3 px-2">Group</label>
        <div class="w-10"></div>
      </div>

      <div v-for="(v, i) in $v.users.$each.$iter" :key="i" class="flex -mx-2">
        <FormControl :v="v" slug="firstName" label="First Name" unlabelled class="w-1/3 px-2">
          <input type="text" id="firstName" v-model="v.firstName.$model" />
        </FormControl>
        <FormControl :v="v" slug="lastName" label="Last Name" unlabelled class="w-1/3 px-2">
          <input type="text" id="lastName" v-model="v.lastName.$model" />
        </FormControl>
        <FormControl :v="v" slug="group" label="Group" unlabelled class="w-1/3 px-2">
          <input type="text" slug="group" v-model="v.group.$model" />
        </FormControl>

        <div class="w-10">
          <button type="button" class="w-10 h-10 mt-0.5" @click="removeRow(i, $event)">
            <svg class="w-3 h-3 fill-blank mx-auto pointer-events-none">
              <use xlink:href="#cross" />
            </svg>
          </button>
        </div>
      </div>

      <div class="btns4 mt-6">
        <button type="button" class="btn4-clear" @click="close">Cancel</button>
        <button type="submit" class="btn4">Add Children</button>
      </div>
    </form>
  </AdminSpinner>
</template>
