<script>
import UserList from '@/admin/components/users/UserList.vue';
import UserAdd from '@/admin/components/users/UserAdd.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: { UserList, UserAdd, AdminModal },
  data: () => ({ showAdd: false }),
  metaInfo: { title: 'Create Users' },
  computed: mapGetters('user', ['isAdmin']),
};
</script>

<template>
  <div class="container pt-8">
    <div class="flex justify-between items-center">
      <div class="head-4xl">Create Users</div>
      <div v-if="isAdmin" class="btns4">
        <button class="btn4 bg-pe" @click="showAdd = true">
          Invite users
          <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
        </button>
      </div>
    </div>

    <UserList kind="create" filter="active" title="active" class="my-8" />
    <UserList kind="create" filter="pending" title="invited" class="my-8" ref="pending" hide />

    <AdminModal :active="showAdd" title="Invite users" @close="showAdd = false">
      <UserAdd kind="create" @update="$refs.pending.fetch()" @close="showAdd = false" />
    </AdminModal>
  </div>
</template>
