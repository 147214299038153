<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import SkillGoals from '@/content/components/partials/SkillGoals.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/fms.svg';

export default {
  components: { Modal, SkillGoals, HaloButton },
  props: { skill: {}, text: {}, entry: {}, colour: { default: 'physical' } },
  data: () => ({ modal: false }),
};
</script>

<template>
  <div>
    <Modal :open="modal" :bg="colour" icon="fms" wide @close="modal = false">
      <div v-if="text" v-html="text" class="rdct rdct-modal"></div>
      <SkillGoals v-else :skill="skill" :entry="entry" modal class="text-3xl" />
    </Modal>

    <HaloButton :colour="colour" icon="fms" label="Movement <br> Skills" @click="modal = true" />
  </div>
</template>
