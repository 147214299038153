<script>
import Link2 from '@/global/components/Link2.vue';

export default {
  components: { Link2 },
  props: { colour: {}, icon: {}, uri: {}, label: '', active: Boolean },

  computed: {
    colour$() {
      return this.colour || this.$route.params.product;
    },
    on() {
      return this.$listeners.click ? { click: () => this.$emit('click') } : {};
    },
  },

  methods: {},
};
</script>

<template>
  <Link2 :uri="uri" :class="[`bg-${colour$}-shade text-${colour$}`, { active }]" class="halo-btn" v-on="on">
    <div class="relative h-full w-full text-center">
      <svg :class="`fill-${colour$}`" class="w-full h-full"><use :xlink:href="`#${icon}`" /></svg>
      <slot :colour="colour$" />
      <div class="absolute bottom-0 left-0 w-full flex justify-center flex-shrink items-center">
        <div v-if="label" v-html="label" class="halo-label"></div>
      </div>
    </div>
  </Link2>
</template>
