import $resource from '@/scripts/resource.js';

export const $account = new $resource('accounts/{id}', {
  name: 'Account',
  default: { name: '', address: {}, access: {} },
});

export const $user = new $resource('users/{kind}/{id}', {
  name: 'User',
  default: { email: '' },
  calls: [{ name: '$invite', method: 'get', uri: '/invite' }],
});

export const $licence = new $resource('licences/{id}', {
  name: 'Licence',
  default: { years: [], products: [], licenceType: {}, startDate: '', endDate: '', status: '', live: 1, renew: 1 },
});

export const $group = new $resource('groups/{id}', {
  name: 'Class',
  default: { name: '' },
});

export const $student = new $resource('students/{id}', {
  name: 'Child',
  default: { firstName: '', lastName: '' },
  calls: [{ name: '$invite', method: 'post', uri: '/invite' }],
});

export const $bookmark = new $resource('bookmarks/{id}', {
  name: 'Bookmark',
  default: {},
});

export const $training = new $resource('training/{id}', {
  name: 'Activity',
  default: {},
});

export const $burstsEvents = new $resource('bursts/events');
