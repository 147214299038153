<script>
import ExportButton from '@/admin/components/ExportButton.vue';
import AccountFilter from '@/admin/components/accounts/AccountFilter.vue';
import AccountList from '@/admin/components/accounts/AccountList.vue';

export default {
  components: { ExportButton, AccountFilter, AccountList },
  data: () => ({ query: {} }),

  metaInfo() {
    return { title: 'Accounts' };
  },

  methods: {
    filter(query) {
      this.query = query;
    },
  },
};
</script>

<template>
  <div class="container pt-8">
    <div class="flex justify-between items-center mb-6">
      <div class="head-4xl leading-none">Accounts</div>
      <div class="btns4">
        <RouterLink to="/user-search" class="btn4-clear">User Search</RouterLink>
        <ExportButton label="accounts" path="accounts" :params="query" />
      </div>
    </div>

    <AccountFilter @filter="filter" />
    <AccountList :query="query" />
  </div>
</template>
