<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import ExportButton from '@/admin/components/ExportButton.vue';
import FormControl from '@/admin/components/FormControl.vue';

const base = { month: undefined, sortBy: 'minutes', dir: -1, groupBy: undefined };

export default {
  components: { AdminSpinner, ExportButton, FormControl },
  data: () => ({ ...base, sets$: [], ready: false, ready2: false, filters: [] }),

  computed: {
    params() {
      return { date: this.month, groupBy: this.groupBy };
    },
    sets() {
      return this.sets$.map(set => ({
        ...set,
        accounts: set?.accounts?.sort((a, b) => (a[this.sortBy] > b[this.sortBy] ? this.dir : -this.dir)),
      }));
    },
  },

  methods: {
    reset() {
      Object.keys(base).forEach(key => {
        this[key] = base[key];
      });

      this.fetch();
    },

    fetch() {
      this.ready = false;
      this.$api.get('/bursts/accounts/table', { params: this.params }).then(res => {
        this.ready = true;
        this.sets$ = res.data || [];
      });
    },

    sort(by, dir = -1) {
      this.dir = this.sortBy === by ? -this.dir : dir;
      this.sortBy = by;
    },
  },

  mounted() {
    this.fetch();
    this.$api.get('bursts/accounts/filters').then(res => {
      this.ready2 = true;
      this.filters = res.data;
    });
  },
};
</script>

<template>
  <div class="container">
    <div class="flex justify-between items-center mb-6">
      <div class="head-4xl text-theme">Account Activity</div>
      <div class="btns4">
        <ExportButton label="Accounts" path="bursts/accounts" :params="params" />
      </div>
    </div>

    <AdminSpinner :ready="ready2">
      <div class="p-3 pt-2 pb-1 mb-10 bg-white rounded-lg shadow my-5">
        <div class="flex flex-wrap -mx-1 items-center">
          <div class="px-1 w-1/3">
            <FormControl id="month" label="Month" theme="ring" :active="month">
              <select id="month" v-model="month">
                <option :value="undefined">-</option>
                <option v-for="month in filters.months" :key="month.key" :value="month.val">
                  {{ month.label }}
                </option>
              </select>
            </FormControl>
          </div>
          <div class="w-1/3">
            <FormControl id="month" label="Group By" theme="ring" :active="groupBy">
              <div class="flex flex-wrap -mx-1 py-1 px-2">
                <label :class="{ active: groupBy === undefined }" class="year-select text-xs">
                  <input type="radio" id="group" v-model="groupBy" :value="undefined" />None
                </label>
                <label :class="{ active: groupBy === 'regionId' }" class="year-select text-xs">
                  <input type="radio" id="group" v-model="groupBy" value="regionId" />Region
                </label>
                <label :class="{ active: groupBy === 'countyId' }" class="year-select text-xs">
                  <input type="radio" id="group" v-model="groupBy" value="countyId" />County
                </label>
                <label :class="{ active: groupBy === 'authorityId' }" class="year-select text-xs">
                  <input type="radio" id="group" v-model="groupBy" value="authorityId" />Authority
                </label>
              </div>
            </FormControl>
          </div>

          <div class="btns4 p-2 py-3 flex-grow">
            <button class="btn4-clear" @click="reset">Reset</button>
            <button class="btn4 bg-bursts" @click="fetch">Filter</button>
          </div>
        </div>
      </div>
    </AdminSpinner>

    <AdminSpinner :ready="ready" class="loader-lg" hide>
      <div class="container">
        <table class="my-6 mx-auto">
          <template v-for="(set, i) in sets">
            <tr v-if="set.name" class="text-centerx text-2xl">
              <td colspan="6">{{ set.name }}</td>
            </tr>
            <tr v-if="true" class="lbl4 text-center border-b-2 border-grey-l1 cursor-pointer">
              <th :class="sortBy === 'name' ? `s${dir}` : ''" class="sort-th" @click="sort('name', 1)">Account Name</th>
              <th :class="sortBy === 'groups' ? `s${dir}` : ''" class="sort-th" @click="sort('groups')">Classes</th>
              <th :class="sortBy === 'students' ? `s${dir}` : ''" class="sort-th" @click="sort('students')">
                Children
              </th>
              <th :class="sortBy === 'active' ? `s${dir}` : ''" class="sort-th" @click="sort('active')">
                Active<br />Children
              </th>
              <th :class="sortBy === 'minutes' ? `s${dir}` : ''" class="sort-th" @click="sort('minutes')">Minutes</th>
              <th :class="sortBy === 'minPerActive' ? `s${dir}` : ''" class="sort-th" @click="sort('minPerActive')">
                / Active<br />Child
              </th>
            </tr>
            <tr v-for="account in set.accounts" :key="account.id" class="border-t border-grey-l1 text-center">
              <td class="text-left">{{ account.name }}</td>
              <td>{{ account.groups }}</td>
              <td>{{ account.students }}</td>
              <td>{{ account.active }}</td>
              <td>{{ account.minutes }}</td>
              <td>{{ account.minPerActive }}</td>
            </tr>
            <!-- <pre>{{ set }}</pre> -->
            <tr class="text-center border-t-2 border-grey-l1">
              <td class="text-left"><strong>Totals:</strong> {{ set.totalAccounts }}</td>
              <td>{{ set.totalGroups }}</td>
              <td>{{ set.totalStudents }}</td>
              <td>{{ set.totalActive }}</td>
              <td>{{ set.totalMinutes }}</td>
              <td>{{ set.totalMinPerActive }}</td>
            </tr>
            <tr>
              <td class="pt-10"></td>
            </tr>
          </template>
        </table>
      </div>
      <!-- TW: s1 s-1 -->
    </AdminSpinner>
  </div>
</template>
