<script>
import Breadcrumbs from '@/global/components/Breadcrumbs.vue';
import Brand from '@/global/components/Brand.vue';
import routeInfo from '@/mixins/routeInfo.js';
import { mapGetters, mapActions } from 'vuex';
import '@/assets/svgs/icons/chevron.svg';
import '@/assets/svgs/icons/chevron2.svg';
import '@/assets/svgs/icons/warn.svg';
import '@/assets/svgs/buttons/home-challenge.svg';

export default {
  components: { Breadcrumbs, Brand },
  data: () => ({ showSettings: false }),
  mixins: [routeInfo],

  computed: {
    border() {
      return this.productSlug && this.productSlug !== 'real' ? `border-${this.productSlug}` : 'border-blank';
    },
    peActive() {
      return this.$store.state.menu.open;
    },

    ...mapGetters(['dashUri', 'forumUrl', 'lmsUrl']),
    ...mapGetters('user', ['isCreate', 'isAdmin', 'isHome', 'hasTraining', 'hasHome', 'hasRealPe', 'hasBursts']),
  },

  methods: {
    settingsButton({ target }) {
      this.showSettings = true;
      this.$nextTick(() => target.blur());
    },

    antiMenu(uri) {
      if (this.$store.state.menu.open) this.$store.commit('menu/toggle');
      this.$router.push(uri);
    },
    peButton({ target }) {
      if (this.hasRealPe) {
        this.$store.commit('menu/toggle');
      } else {
        this.$router.push('/real');
      }
      this.$nextTick(() => target.blur());
    },

    outer(event) {
      if (event.target.id !== 'openSettings') this.showSettings = false;
    },
  },

  watch: {
    $route() {
      this.showSettings = false;
    },
  },
};
</script>

<template>
  <div class="bg-white border-b-2 fixed-header print:hidden" :class="border">
    <div class="container h-full">
      <div class="flex items-center -mx-3 h-full">
        <button class="nm1" @click="antiMenu(dashUri)">
          <svg class="fill-current w-5 h-5"><use xlink:href="#home-challenge" /></svg>
        </button>
        <button v-if="isHome" id="peButton" :class="{ active: peActive }" class="nm1 tight" @click="peButton">
          <Brand slug="home" class="pointer-events-none h-11" />
        </button>
        <RouterLink v-else to="/real" class="nm1 tight">
          <Brand slug="full" class="pointer-events-none h-11" />
        </RouterLink>

        <template v-if="isHome">
          <Breadcrumbs class="flex-grow" />
          <RouterLink to="/support/daily" class="nm1">Support</RouterLink>
          <RouterLink to="/profile" class="nm1">My Profile</RouterLink>
          <button class="nm1" @click="$store.dispatch('logOut')">Sign Out</button>
        </template>

        <template v-else>
          <RouterLink to="/bursts" class="nm1">
            <img src="/brands/bursts.svg" alt="BURSTS" class="w-24" />
          </RouterLink>
          <RouterLink to="/learning" class="nm1 tight">
            <img src="/brands/learning.svg" alt="Learning Hub" class="h-11" />
          </RouterLink>

          <div class="flex-grow"></div>
          <!-- <RouterLink to="/support" class="nm1">Support</RouterLink> -->

          <template v-if="isCreate">
            <RouterLink to="/accounts" class="nm1">Accounts</RouterLink>
            <RouterLink to="/create" class="nm1">Create</RouterLink>
          </template>

          <template v-else>
            <template v-if="isAdmin">
              <RouterLink to="/account/users" class="nm1">My Account</RouterLink>
            </template>
          </template>

          <!-- <RouterLink to="/learning" class="nm1">Learning Hub</RouterLink> -->

          <!-- <a :href="forumUrl" class="nm1 hidden lg:block">Community</a> -->

          <div class="relative">
            <button id="openSettings" :class="{ active: showSettings }" class="nm1" @click="settingsButton">
              More
              <svg class="inline w-3 h-3 ml-2 fill-current back-arrow pointer-events-none">
                <use xlink:href="#chevron2" />
              </svg>
            </button>

            <div
              v-if="showSettings"
              class="flex flex-col bg-white absolute right-0 z-10 w-40 shadow border rounded p-1 border-blank-shade"
              v-outer="outer"
            >
              <a :href="forumUrl" class="nm1 lg:hidden">Community</a>
              <a class="nm1" href="https://create-dev.myshopify.com" target="_blank">Store ↗</a>

              <hr class="my-1 mx-3" />
              <template v-if="!isCreate && isAdmin">
                <RouterLink to="/account/users" class="nm1">Users</RouterLink>
                <RouterLink v-if="hasHome" to="/account/home" class="nm1">Home Learning</RouterLink>
                <RouterLink v-if="hasTraining" to="/account/training" class="nm1">Training</RouterLink>

                <hr class="my-1 mx-3" />
              </template>

              <RouterLink to="/support/faqs" class="nm1">real PE FAQs</RouterLink>
              <RouterLink v-if="hasBursts" to="/support/bursts/faqs" class="nm1">BURSTS FAQs</RouterLink>
              <hr class="my-1 mx-3" />

              <RouterLink to="/profile" class="nm1">My Profile</RouterLink>
              <button class="nm1" @click="$store.dispatch('logOut')">Sign Out</button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.fixed-header {
  height: 60px;
  transition: border-color 0.3s;
}
.back-arrow {
  transform: translate(-3px, -1px);
}
</style>
