<script>
import Link2 from '@/global/components/Link2.vue';

export default {
  components: { Link2 },
  props: { colour: {}, icon: {}, uri: {}, label: '', active: Boolean },

  computed: {
    classes() {
      const colour = this.colour || this.$route.params.product;
      return `hover:bg-${colour}-shade hover:text-${colour}`;
    },
    on() {
      return this.$listeners.click ? { click: () => this.$emit('click') } : {};
    },
  },

  methods: {},
};
</script>

<template>
  <Link2 :uri="uri" :class="[classes, { active }]" class="elite-btn" v-on="on">
    <svg class="fill-current h-5 w-5 mr-2 -ml-0.5"><use :xlink:href="`#${icon}`" /></svg>
    <div v-if="label" v-html="label" class="font-bold text-sm"></div>
  </Link2>
</template>
