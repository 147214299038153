<script>
import { mapGetters } from 'vuex';
import { address } from '@/scripts/filters.js';

export default {
  props: ['account'],

  computed: {
    address() {
      return address(this.account);
    },

    ...mapGetters('user', ['isCreate']),
  },
};
</script>

<template>
  <div>
    <div v-if="isCreate" class="uppercase tracking-widest leading-none font-bold mb-1 flex items-center">
      <img v-if="account.wondeRealPe" src="/images/wonde-realpe.svg" title="Wonde: real PE" class="h-4 mr-2" />
      <img v-if="account.wondeBursts" src="/images/wonde-bursts.svg" title="Wonde: BURSTS" class="h-4 mr-2" />
      <span v-if="account.frozen" class="text-fun-blue">Frozen</span>
      <template v-else>{{ account.statusLabel }}</template>
      <span class="mx-2">•</span>
      {{ account.accountType }}
    </div>

    <div class="trns-color text-3xl text-theme font-semibold leading-tight">{{ account.name }}</div>

    <div v-if="account.address" class="mt-1">
      {{ address }}
      <div class="-mx-0.5">
        <span v-if="account.county.name" class="mx-0.5">{{ account.county.name }}</span>
        <strong v-if="account.region.name" class="mx-0.5">{{ account.region.name }}</strong>
      </div>
    </div>

    <div v-if="!isCreate" class="text-sm mt-1">
      <div v-if="account.$renewalRealPe">
        <strong class="w-28 inline-block">real PE Renewal:</strong> {{ account.$renewalRealPe }}
      </div>
      <div v-if="account.$renewalBursts">
        <strong class="w-28 inline-block">Bursts Renewal:</strong> {{ account.$renewalBursts }}
      </div>
    </div>

    <RouterLink v-if="account.parentSchool" :to="`/accounts/${account.parentSchool.id}`" class="font-bold italic">
      {{ account.parentSchool.name }}
    </RouterLink>
  </div>
</template>
