<script>
import LessonPreview from '@/content/components/previews/LessonPreview.vue';
import EquipmentList from '@/content/components/partials/EquipmentList.vue';
import SkillLevels from '@/content/components/partials/SkillLevels.vue';
import HomePreview from '@/content/components/previews/HomePreview.vue';
import SkillGoals from '@/content/components/partials/SkillGoals.vue';
import CardSelect from '@/content/components/partials/CardSelect.vue';
import TextBlock from '@/content/components/partials/TextBlock2.vue';
import VimeoText from '@/content/components/partials/VimeoText.vue';
import AlsoButton from '@/content/components/buttons/AlsoButton.vue';
import routeInfo from '@/mixins/routeInfo.js';

const ttt = ['tricky', 'trickier', 'trickiest'];

export default {
  components: {
    TextBlock,
    EquipmentList,
    VimeoText,
    LessonPreview,
    HomePreview,
    SkillGoals,
    SkillLevels,
    AlsoButton,
    CardSelect,
  },
  mixins: [routeInfo],

  props: {
    part: {},
    parts: [],
    success: {},
    titleColour: {},
    lesson: { default: () => ({}) },
    ability: { default: () => ({}) },
    theme: { default: () => ({}) },
    skill: {},
    overview: Boolean,
  },

  computed: {
    cards() {
      if (this.isPlay && this.part.steps) {
        return this.part.steps
          .filter(step => step.funs.length)
          .map(step => step.funs[0])
          .map(card => ({ ...card, colour: `fun-${card.funsLevel}` }));
      } else if (this.isPe) {
        return this.part.cards?.map(card => ({ ...card, colour: `fun-${card.funsLevel}` }));
      } else if (this.isGym) {
        return this.part.levels?.map(card => ({ ...card, colour: 'gymcard' }));
      } else if (this.isDance) {
        const yearLevel = [0, 0, 0, 1, 1, 2][this.$route.params.year];
        const yearVideo = [0, 0, 1, 0, 1, 0][this.$route.params.year];

        return this.part.skills?.map(({ id, levels }) => {
          return { id, ...levels[yearLevel].videos[yearVideo], colour: 'dance' };
        });
      }
    },

    expectedCard() {
      if (this.isGym && this.part.levels) {
        const gymLevel = [0, 0, 0, 0, 1, 1, 2][this.$route.params.year];
        return this.cards[gymLevel];
      } else if (this.isPe) {
        return this.cards?.find(card => card.funsLevel === this.part.funsLevel);
      } else if (this.isDance) {
        const card = this.cards?.[0];
        return card ? { ...card, challenges: card.challenges2 } : null;
      }
    },

    showSkill() {
      return ['movements', 'funs', 'card', 'skill'].includes(this.$route.params.type);
    },

    keywords() {
      return (this.part.keywords || []).map(k => k.title).join(', ');
    },
    titleColour$() {
      return this.titleColour || `text-${this.productSlug}`;
    },

    useLessonSkillFields() {
      return ['funs', 'movements'].includes(this.$route.params.type);
    },
    skillText() {
      return this.useLessonSkillFields && this.lesson.skillText;
    },
    tips() {
      return this.part.tips || (this.useLessonSkillFields && this.lesson.skillTips);
    },
    cogIdeas() {
      if (this.isDance && this.part.type === 'application') return this.ability.appPoints;
      if (this.part.cogOverwrite) return this.part.cogOverwrite;
    },
    showLevel() {
      return this.part.type === 'skill' || this.part.funsLevel || this.part.type === 'card';
    },

    full() {
      return !this.overview;
    },

    steps() {
      if (this.part.steps?.some(step => step.text)) return this.part.steps;
      return this.part.warmUpSteps;
    },
  },
};
</script>

<template>
  <div>
    <template v-if="full">
      <HomePreview v-if="isFoundations" :menu="parts" class="mb-2" />
      <LessonPreview v-else :lesson="lesson" class="mb-2 print:hidden" />
    </template>

    <CardSelect v-if="isGym && part.type === 'card' && full" :lesson="lesson" :part="part" />

    <TextBlock>
      <div class="flex items-center">
        <div class="box-titlex">
          <div class="pre-title">{{ part.typeLabel }}</div>
          <h2 :class="titleColour$" class="font-bliss text-4xl font-bold">{{ part.displayTitle || part.title }}</h2>
          <div class="text-2xl font-medium" v-if="part.tune">To the tune of: {{ part.tune }}</div>

          <SkillLevels v-if="showLevel" single class="-mb-2" />
        </div>
        <div class="box-buttons print:hidden shrink-0 -my-2">
          <AlsoButton :lesson="lesson" />
          <slot name="buttons"></slot>
        </div>
      </div>
    </TextBlock>

    <TextBlock v-if="showSkill && full" colour="physical">
      <SkillGoals :skill="skill" />
    </TextBlock>

    <TextBlock v-if="keywords" class="text-2xl">
      <h2 class="teacher-title">Key Vocabulary</h2>
      <div class="lowercase">{{ keywords }}</div>
    </TextBlock>

    <div v-if="full" class="t-row">
      <TextBlock icon="equipment" colour="pe">
        <h2 class="teacher-title">Equipment</h2>
        <EquipmentList :list="part.equipment" class="rdct rdct-teacher" />
      </TextBlock>

      <!-- <TextBlock v-if="part.reviewNumber" icon="review-cloud" :colour="ability.slug">
        <h2 class="teacher-title">Review Question {{ part.reviewNumber }}</h2>
        <div v-html="ability.reviewQuestions[part.reviewNumber - 1]" class="rdct rdct-teacher"></div>
      </TextBlock> -->

      <TextBlock v-if="success && full" icon="success" colour="physical">
        <h2 class="teacher-title">Success</h2>
        <div v-html="success" class="rdct rdct-teacher"></div>
      </TextBlock>
    </div>

    <!-- organisation -->
    <TextBlock v-if="part.organisation && full" icon="organisation" colour="physical">
      <h2 class="teacher-title">Organisation</h2>
      <div v-html="part.organisation" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- purpose -->
    <TextBlock v-if="part.purpose && full">
      <h2 class="teacher-title text-black">Purpose</h2>
      <div v-html="part.purpose" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- teaching tips -->
    <TextBlock v-if="tips && full" class="t-wide">
      <h2 class="teacher-title">Teaching Tips</h2>
      <div v-html="tips" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- teacher text / detail -->
    <TextBlock v-if="part.teacherText && !isDance">
      <div v-html="part.teacherText" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- skill text -->
    <TextBlock v-if="skillText">
      <div v-html="skillText" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- video -->
    <div v-if="part.video && full" class="mb-2 overflow-hidden bg-white curves print:hidden">
      <VimeoText class="video" :vid="part.video" :key="part.video" :lines="part.textLines" colour="pe" />
    </div>

    <!-- warmup steps -->
    <div v-if="steps" class="flex flex-wrap -mx-1">
      <div v-for="(step, i) in steps" :key="step.id" class="w-1/3 px-1">
        <TextBlock class="h-full">
          <div
            :class="`text-${$route.params.product}`"
            class="text-2xl font-bold leading-none text-pe absolute top-0 right-0 pt-3 pr-4"
          >
            {{ step.end ? '?' : i + 1 }}
          </div>
          <h2 class="teacher-title capitalize mr-8" style="min-height: 10px">{{ step.subtitle }}</h2>
          <div v-if="step.teacherText" v-html="step.teacherText" class="rdct rdct-teacher"></div>
          <div v-else v-html="step.text" class="rdct rdct-teacher"></div>
        </TextBlock>
      </div>
    </div>

    <slot></slot>

    <!-- gym -->

    <template v-if="isGym && part.type === 'game'">
      <TextBlock>
        <h2 class="teacher-title text-black">How to play</h2>
        <div v-html="part.howToPlay" class="rdct rdct-teacher"></div>
      </TextBlock>

      <TextBlock icon="cog" :colour="ability.slug">
        <h2 class="teacher-title">{{ ability.title }}</h2>
        <div v-html="part.abilityText" class="rdct rdct-teacher"></div>
      </TextBlock>
    </template>

    <!-- dance -->
    <TextBlock v-if="isDance && part.teacherText">
      <template v-if="part.type === 'skill'">
        <h2 class="teacher-title">Skill Exploration</h2>
        <h3 class="teacher-title">Aim</h3>
        <div v-if="part.purpose" v-html="part.purpose" class="rdct rdct-teacher"></div>
        <h3 class="teacher-title">Quickfire Start</h3>
        <div v-html="part.teacherText" class="rdct rdct-teacher"></div>
      </template>

      <template v-else-if="part.type === 'application'">
        <template v-if="part.intro">
          <h2 class="teacher-title">How to create the dance</h2>
          <div v-html="part.intro" class="rdct rdct-teacher"></div>
        </template>
        <template v-if="part.teacherText">
          <h3 class="teacher-title">Ask Them To:</h3>
          <div v-html="part.teacherText" class="rdct rdct-teacher"></div>
        </template>
        <template v-if="part.teacherText2 && full">
          <h3 class="teacher-title">Encourage Them To:</h3>
          <div v-html="part.teacherText2" class="rdct rdct-teacher"></div>
        </template>
        <template v-if="part.teacherText3 && full">
          <h3 class="teacher-title">Performance:</h3>
          <div v-html="part.teacherText3" class="rdct rdct-teacher"></div>
        </template>
      </template>

      <template v-else-if="part.type === 'performance'">
        <div v-if="part.teacherText" v-html="part.teacherText" class="rdct rdct-teacher"></div>
        <h3 class="teacher-title">Ask Them To:</h3>
        <div v-if="part.teacherText2" v-html="part.teacherText2" class="rdct rdct-teacher"></div>
      </template>

      <div v-else-if="part.teacherText" v-html="part.teacherText" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- play -->
    <TextBlock v-if="part.involvement">
      <h2 class="teacher-title text-black">Engaging adults</h2>

      <h3 class="teacher-title">Involvement</h3>
      <div v-html="part.involvement" class="rdct rdct-teacher"></div>

      <h3 class="teacher-title">Interaction</h3>
      <div v-html="part.interaction" class="rdct rdct-teacher"></div>

      <h3 class="teacher-title">Instruction</h3>
      <div v-html="part.instruction" class="rdct rdct-teacher"></div>
    </TextBlock>

    <TextBlock v-if="part.supportingNotes">
      <h2 class="teacher-title text-black">Supporting Notes</h2>
      <div v-html="part.supportingNotes" class="rdct rdct-teacher"></div>
    </TextBlock>

    <TextBlock v-if="part.moreActive" icon="more-active" colour="pe" highlight>
      <h2 class="teacher-title text-pe">More Active</h2>
      <div v-html="part.moreActive" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- foundations -->
    <template v-if="theme.id">
      <div class="t-row">
        <TextBlock v-for="block in part.playCharacteristics" :key="block.id">
          <h2 class="teacher-title">Characteristics of Learning</h2>
          <h3 class="teacher-title text-pe">
            <strong>{{ block.focus }}</strong>
            <span v-if="block.subtitle">&nbsp;- {{ block.subtitle }}</span>
          </h3>
          <div v-html="block.teacherText" class="rdct rdct-teacher"></div>
        </TextBlock>

        <TextBlock v-if="part.positiveRelationships">
          <h2 class="teacher-title">Positive Relationships</h2>
          <div v-html="part.positiveRelationships" class="rdct rdct-teacher"></div>
        </TextBlock>
      </div>

      <div class="t-row">
        <TextBlock v-if="part.uniqueChild">
          <h2 class="teacher-title">Unique Child</h2>
          <div v-for="row in part.uniqueChild" :key="row.id">
            <h3 class="teacher-title text-pe">
              <strong>{{ row.focus }}</strong>
              <span v-if="row.subtitle">&nbsp;- {{ row.subtitle }}</span>
            </h3>
            <div v-html="row.teacherText" class="rdct rdct-teacher"></div>
          </div>
        </TextBlock>

        <TextBlock v-if="part.playThink" icon="think-about" colour="pe">
          <h2 class="teacher-title">Have you thought about?</h2>
          <div v-for="row in part.playThink" :key="row.id">
            <h3 class="teacher-title">
              <strong>{{ row.focus }}</strong>
              <span v-if="row.subtitle">&nbsp;- {{ row.subtitle }}</span>
            </h3>
            <div v-html="row.teacherText" class="rdct rdct-teacher"></div>
          </div>
        </TextBlock>
      </div>
    </template>

    <!-- videos cards -->
    <div v-if="cards && cards.length && !theme.id && full" class="flex flex-wrap -mx-1 print:hidden">
      <TextBlock class="w-1/2 px-1" v-for="card in cards" :key="card.id" :colour="`${card.colour}-shade`">
        <VimeoText
          :vid="card.video"
          :key="card.video"
          :lines="card.textLines"
          :colour="card.colour"
          size="small"
          class="-m-5 mb-5"
        />

        <div v-html="card.challenges" class="rdct rdct-teacher"></div>
      </TextBlock>
    </div>

    <!--  single card text (print only on activity)  -->
    <TextBlock
      v-if="expectedCard"
      :colour="`${expectedCard.colour}-shade`"
      :class="full ? 'hidden print:block' : ''"
      class=""
    >
      <div v-html="expectedCard.challenges" class="rdct rdct-teacher"></div>
    </TextBlock>

    <TextBlock v-if="cogIdeas && full" :colour="ability.slug" icon="cog" class="">
      <!-- <h2 class="teacher-title">Cog Ideas</h2> -->
      <div v-html="cogIdeas" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- tricky / trickier / trickiest -->
    <div v-if="part.tricky" class="t-row">
      <TextBlock v-if="part.tricky" colour="tricky" highlight icon="tricky">
        <h2 class="teacher-title">Tricky</h2>
        <div v-html="part.tricky" class="rdct rdct-teacher"></div>
      </TextBlock>
      <TextBlock v-if="part.trickier" colour="tricky" highlight icon="trickier">
        <h2 class="teacher-title">Trickier</h2>
        <div v-html="part.trickier" class="rdct rdct-teacher"></div>
      </TextBlock>
      <TextBlock v-if="part.trickiest" colour="tricky" highlight icon="trickiest">
        <h2 class="teacher-title">Trickiest</h2>
        <div v-html="part.trickiest" class="rdct rdct-teacher"></div>
      </TextBlock>
    </div>

    <!-- home challenge -->
    <TextBlock
      v-if="lesson.homeChallenge && part.type === 'review' && full"
      icon="home-challenge"
      colour="house"
      class="print:hidden"
    >
      <h2 class="teacher-title">Home Challenge</h2>
      <div v-html="lesson.homeChallenge" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- <pre>{{ part }}</pre> -->

    <slot name="end"></slot>
  </div>
</template>
