<script>
import { adventureStrings } from '@/scripts/strings.js';

export default {
  props: ['id'],

  computed: {
    img() {
      return adventureStrings.find(a => a.id == this.id)?.img;
    },
  },
};
</script>

<template>
  <img :src="img" alt="" />
</template>
