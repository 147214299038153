<script>
import DiceModal from '@/content/components/modal/DiceModal.vue';
import VideoButton from '@/content/components/buttons/VideoButton.vue';

export default {
  components: { DiceModal, VideoButton },
  props: { dice: Array, hasVideo: Boolean, active: String },
  data: () => ({ levels: ['tricky', 'trickier', 'trickiest'] }),
};
</script>

<template>
  <div class="bg-white">
    <div class="flex items-center justify-center mb-10 text-2xl leading-none">
      <button
        v-for="level in levels"
        :key="level"
        v-html="level"
        :class="{ active: active === level }"
        class="w-32 h-32 mx-2 text-white capitalize border-4 rounded-full level"
        @click="$emit('change', level)"
      ></button>

      <DiceModal :dice="dice" class="mx-2" />
      <VideoButton class="ml-2" v-if="hasVideo" @click="$emit('video')" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.level {
  @apply bg-tricky-shade border-tricky-shade;
  @apply print:text-black;
  transition: background 0.3s;

  &:focus {
    @apply border-tricky outline-none;
  }

  &.active {
    @apply bg-tricky border-tricky;
  }
}
</style>
