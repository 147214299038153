<script>
import FormControl from '@/admin/components/FormControl.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import { emailReg, emailGen } from '@/scripts/filters.js';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

const emailRex = RegExp('.+@.+\\.+.+');
const validEmails = (val, vm) => vm.users.length;

export default {
  components: { FormControl, AdminSpinner },
  mixins: [validationMixin],
  props: ['kind', 'account'],
  data: () => ({ emailString: '', users: [], ready: true }),
  validations: { emailString: { required, validEmails } },

  computed: {
    done() {
      return this.users.filter(u => !Object.keys(u.errors || {}).length).length;
    },
    ...mapGetters('user', ['isCdSuper']),
  },

  methods: {
    checkEmail(email) {
      if (this.isCdSuper || this.account.isPartner) return;
      if (emailReg.test(email)) return { email: ['Email cannot be a personal/home account'] };
      if (emailGen.test(email)) return { email: ['Email cannot be a generic user like head@ or info@'] };
    },

    split() {
      this.users = [...new Set(this.emailString.split(/[\s,]+/))]
        .filter(v => emailRex.test(v))
        .map(email => ({ email, role: this.userRole, errors: this.checkEmail(email) }));
    },

    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid && this.ready) {
        this.ready = false;
        this.$api
          .post(`users/${this.kind}/bulk`, this.users, { params: { accountId: this.account.id } })
          .then(res => {
            this.ready = true;
            this.emailString = '';
            this.$v.$reset();
            this.users = res.data;
            if (this.done) this.$emit('update');
          })
          .catch(error => {
            this.ready = true;
            console.error(error);
          });
      }
    },

    close() {
      this.users = [];
      this.emailString = '';
      this.$emit('close');
      this.$v.$reset();
    },
  },
};
</script>

<template>
  <form class="p-0 modal-body relative" @submit.prevent="submit()">
    <AdminSpinner :ready="ready">
      <FormControl :v="$v" slug="emailString" label="Email(s)" desc="One or more emails to invite (space seperated)">
        <input v-model.trim="$v.emailString.$model" id="emails" type="text" @input="split()" />
      </FormControl>

      <div class="flex justify-between" v-if="users.length">
        <div>Emails:</div>
        <div>Admin</div>
      </div>
      <div
        v-for="user in users"
        :key="user.email"
        class="h-auto px-2 py-1 my-1 overflow-scroll border rounded bg-grey-l3 border-grey-l2"
        :class="{ success: user.errors, errors: user.errors && Object.keys(user.errors).length }"
      >
        <div class="flex justify-between">
          {{ user.email }}
          <input v-if="!user.errors" v-model="user.admin" type="checkbox" class="cursor-pointer" />
        </div>
        <div v-for="(group, key) in user.errors" :key="key" class="text-sm">
          <div class="error" v-for="error in group" :key="error">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="btns4 mt-6">
        <button type="button" class="btn4-clear" @click="close()">Cancel</button>
        <button v-if="done && !emailString.length" type="button" class="btn4" @click="close()">Done</button>
        <button v-else type="submit" class="btn4 bg-pe">Add users</button>
      </div>
    </AdminSpinner>
  </form>
</template>

<style lang="postcss" scoped>
.success {
  border-color: #018003;
  background-color: #92bf8b;
}
.errors {
  border-color: #ff0100;
  background-color: #ff948e;
}
</style>
