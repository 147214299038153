<script>
import FormControl from '@/admin/components/FormControl.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import '@/assets/svgs/icons/lock-disc.svg';
import '@/assets/svgs/icons/user-disc.svg';

export default {
  components: { FormControl },
  metaInfo: { title: 'Login' },
  mixins: [validationMixin],
  validations: { userAuth: { required }, password: { required } },
  data: () => ({ userAuth: '', password: '', ready: true, note: '' }),

  computed: {
    user() {
      const isEmail = this.userAuth.includes('@');

      return {
        ...(!isEmail && { username: this.userAuth }),
        ...(isEmail && { email: this.userAuth }),
        password: this.password,
      };
    },
  },

  methods: {
    login() {
      this.$v.$touch();

      if (this.ready && !this.$v.$invalid) {
        this.ready = false;

        this.$api
          .post('auth/login', this.user)
          .then(response => {
            this.ready = true;
            this.$store.dispatch('logIn', response.data);
          })
          .catch(({ response }) => {
            this.note = response.data && response.data.label;
            this.ready = true;
          });
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="login" novalidate>
    <h2 class="head-2xl mb-5 text-center">Sign in</h2>

    <div v-if="note === 'frozen'" class="note note-error mb-6">
      <p>Your access has been frozen due to an outstanding payment.</p>
      <p>
        Please contact
        <a href="mailto:finance@realpe.co.uk">finance@realpe.co.uk</a>
        for assistance and to re-activate your account.
      </p>
    </div>
    <div v-else-if="note === 'expired'" class="note note-error mb-6">
      <p>Your access has expired.</p>
      <p>
        To re-activate your account or for further assistance, please contact
        <a href="mailto:info@realpe.co.uk">info@realpe.co.uk</a>
      </p>
    </div>

    <FormControl :v="$v" slug="userAuth" label="Username" icon="user-disc">
      <input type="text" v-model="$v.userAuth.$model" id="userAuth" name="userAuth" required />
    </FormControl>
    <FormControl :v="$v" slug="password" label="Password" icon="lock-disc">
      <input type="password" v-model="$v.password.$model" id="password" name="password" required />
    </FormControl>

    <div class="flex justify-between items-center mt-6">
      <RouterLink to="/login/reset" class="btn4-clear">Password reset</RouterLink>
      <button class="btn4 bg-pe">Sign in</button>
    </div>
  </form>
</template>
