<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import query from '@/queries/micro-learns.gql';
import '@/assets/svgs/dashboard/online-learning.svg';

export default {
  metaInfo: { title: 'Micro Learns' },
  components: { AdminSpinner },
  data: () => ({ ready: false, entries: [] }),

  computed: {
    categories() {
      const noCategory = this.entries.filter(entry => entry.type === 'storyline');

      return [
        ...this.entries.filter(entry => entry.type === 'category'),
        ...(noCategory.length ? [{ title: 'No Category', children: noCategory }] : []),
      ];
    },
  },

  methods: {
    fetch() {
      this.$gql(query, { slug: this.slug })
        .then(res => {
          this.entries = res.entries;
          this.ready = true;
        })
        .catch(() => {
          this.ready = true;
        });
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <main>
    <div class="container my-10">
      <div class="mb-10 flex justify-between">
        <div>
          <div class="flex mb-1">
            <svg class="w-8 h-8 mr-3 fill-learning shrink-0"><use xlink:href="#online-learning" /></svg>
            <h1 class="head-4xl leading-none font-bliss text-learning">Micro Learns</h1>
          </div>
          <p>Delve into bitesize learning opportunities.</p>
        </div>
      </div>

      <AdminSpinner :ready="ready" class="loader-lg">
        <div v-for="category in categories" class="mb-4">
          <h3 class="text-learning text-2xl font-medium mb-4">{{ category.title }}</h3>

          <div class="flex flex-wrap -mx-2">
            <div v-for="story in category.children" class="w-1/2 lg:w-1/3 px-2 pb-4">
              <a :href="story.url" target="_blank" class="bg-white rounded-xl p-5 h-full shadow outlne flex flex-col">
                <div v-if="story.image.length" class="-mx-4 -mt-4">
                  <img v-for="img in story.image" :src="img.url" alt="" class="rounded-lg mb-4" />
                </div>

                <div class="text-lg font-bold mb-2 text-learning leading-tight">{{ story.title }}</div>
                <div v-html="story.text" class="prose prose-sm grow"></div>
                <div v-if="story.time" class="italic text-blank text-right">~{{ story.time }} mins</div>
              </a>
            </div>
          </div>
        </div>

        <div v-if="ready && !categories.length" class="empty">No learning could be found</div>
      </AdminSpinner>
    </div>
  </main>
</template>
