<script>
import SupportPage from '@/admin/views/SupportPage.vue';
import Loader from '@/global/components/Loader.vue';
import query from '@/queries/leaders.gql';

export default {
  components: { SupportPage, Loader },
  data: () => ({ page: {} }),

  created() {
    this.$gql(query, { uri: 'support/leaders' }, { required: ['entry'] }).then(res => {
      this.page = res.page || {};
      this.page.product = 'leaders';
      this.page.colour = 'leaders';
    });
  },
};
</script>

<template>
  <SupportPage v-if="page.id" :page="page" :parent="page" class="container mt-6 max-w-4xl" />
</template>
