<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/more-active.svg';

export default {
  components: { Modal, HaloButton },
  props: ['part'],
  data: () => ({ modal: false }),
};
</script>

<template>
  <div v-if="part.moreActive">
    <Modal :open="modal" icon="more-active" title="More Active" @close="modal = false">
      <div v-html="part.moreActive" class="rdct rdct-modal"></div>
    </Modal>

    <HaloButton icon="more-active" label="More Active" @click="modal = true" />
  </div>
</template>
