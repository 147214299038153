<script>
import AdventureIcon from '@/bursts/components/AdventureIcon.vue';
import FormTypeSelect from '@/admin/components/FormTypeSelect.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import ExportButton from '@/admin/components/ExportButton.vue';
import FormControl from '@/admin/components/FormControl.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import Vimeo from '@/global/components/Vimeo2.vue';
import Bolts from '@/bursts/components/Bolts.vue';

// prettier-ignore
const base = {
  year: '', gender: '', ethnicity: '', nationality: '',
  ppEligible: 0, ppIndicator: 0, eal: 0, sen: [],
  region: '', county: '', authority: '',
};

const padArray = (array = []) => array.concat(Array(13 - array.length).fill('-'));

export default {
  components: { AdminSpinner, FormControl, AdventureIcon, ExportButton, FormTypeSelect, Vimeo, AdminModal, Bolts },
  props: ['accountId'],

  // prettier-ignore
  data: () => ({
    graphdata: { levels: Array(11).fill([]), totalActive: [], totalSeconds: [], activities: {} },
    filters: [], ready: false, ready2: false, showEdit: false, showVideo: false,
    ...base,
  }),

  computed: {
    params() {
      return {
        ...(this.accountId && { accountId: this.accountId }),
        ...(this.year && { year: this.year }),
        ...(this.gender && { gender: this.gender }),
        ...(this.ethnicity && { ethnicity: this.ethnicity }),
        ...(this.nationality && { nationality: this.nationality }),
        ...(this.ppEligible && { ppEligible: this.ppEligible }),
        ...(this.ppIndicator && { ppIndicator: this.ppIndicator }),
        ...(this.sen.length && { sen: this.sen }),
        ...(this.eal && { eal: this.eal }),
        ...(this.region && { regionId: this.region.id }),
        ...(this.county && { countyId: this.county.id }),
        ...(this.authority && { authorityId: this.authority.id }),
      };
    },

    chart() {
      const { totalActive, totalSeconds, levels, activities } = this.graphdata;

      // Turn array entries into an object (with padding). Only return levels with non-zero values
      const levelsobj = levels.map((element, index) => ({ level: index, vals: padArray(element) }));
      const filteredlevels = levelsobj.filter(
        element => element.level !== 0 && element.vals.some(arrVal => arrVal !== 0 && arrVal != '-')
      );

      // Only return activities with non-zero values. Can't filter objects; so turn into array;
      // filter; then turn back. Padding added below.
      const actArray = Object.entries(activities);
      const actfiltered = actArray.filter(([key, value]) => value.some(arrVal => arrVal > 0));
      const filteredactivities = Object.fromEntries(actfiltered);

      return {
        short: ['Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        totalActive: padArray(totalActive),
        totalSeconds: padArray(totalSeconds.map(s => Math.floor(s / 60))),
        levels: filteredlevels,
        activities: Object.keys(filteredactivities).map(slug => {
          const split = slug.split('-');
          return { slug, advId: split[0], level: split[1], vals: padArray(activities[slug]) };
        }),
      };
    },
  },

  methods: {
    fetch() {
      this.ready = false;
      this.$api.get('bursts/year/table', { params: this.params }).then(res => {
        this.ready = true;
        this.graphdata = res.data;
      });
    },

    fetch2() {
      this.$api.get('bursts/year/filters', { params: { accountId: this.accountId } }).then(res => {
        this.ready2 = true;
        this.filters = res.data;
      });
    },

    reset() {
      Object.keys(base).forEach(key => {
        this[key] = base[key];
      });

      this.fetch();
    },
  },

  mounted() {
    this.fetch();
    this.fetch2();
  },
};
</script>

<template>
  <div class="container">
    <div class="flex justify-between items-center mb-6">
      <div class="head-4xl text-theme">BURSTS Data</div>
      <div class="btns4">
        <button class="btn4 bg-gymcard" @click="showVideo = true">
          Data Guide
          <svg class="btn4-icon"><use xlink:href="#video" /></svg>
        </button>
        <ExportButton label="Year" path="bursts/year" :params="params" />
      </div>
    </div>

    <AdminSpinner :ready="ready2">
      <div class="p-3 pt-2 pb-1 mb-10 bg-white rounded-lg shadow my-5">
        <template v-if="!accountId">
          <div class="flex -mx-1">
            <div class="w-1/3 px-1">
              <FormControl id="region" label="Region" theme="ring" :active="region.id">
                <select id="region" v-model="region">
                  <option :value="{}">Any</option>
                  <option v-for="region in filters.regions" :key="region.key" :value="region">
                    {{ region.name }}
                  </option>
                </select>
              </FormControl>
            </div>
            <div class="w-1/3 px-1">
              <FormControl slug="county" label="County" theme="ring" :active="county.id">
                <FormTypeSelect v-model="county" :items="filters.counties" id="county" />
              </FormControl>
            </div>
            <div class="w-1/3 px-1">
              <FormControl slug="authority" label="Authority" theme="ring" :active="authority.id">
                <FormTypeSelect v-model="authority" :items="filters.authorities" id="authority" />
              </FormControl>
            </div>
          </div>
        </template>

        <div class="flex -mx-1">
          <div class="flex-grow px-1 w-1/4">
            <FormControl slug="year" label="Year group" theme="ring">
              <select v-model="year" id="year">
                <option value="">-</option>
                <option v-for="val in filters.year" :key="val" :value="val">{{ val }}</option>
              </select>
            </FormControl>
          </div>
          <div class="flex-grow px-1 w-1/4">
            <FormControl slug="gender" label="Gender" theme="ring">
              <select v-model="gender" id="gender">
                <option value="">-</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            </FormControl>
          </div>
          <div class="flex-grow px-1 w-1/4">
            <FormControl slug="ethnicity" label="Ethnicity" theme="ring">
              <select v-model="ethnicity" id="ethnicity">
                <option value="">-</option>
                <option v-for="val in filters.ethnicity" :key="val.code" :value="val.code">{{ val.label }}</option>
              </select>
            </FormControl>
          </div>
          <div class="flex-grow px-1 w-1/4">
            <FormControl slug="nationality" label="Nationality" theme="ring">
              <select v-model="nationality" id="nationality">
                <option value="">-</option>
                <option v-for="val in filters.nationality" :key="val" :value="val">{{ val }}</option>
              </select>
            </FormControl>
          </div>
        </div>

        <div class="flex flex-wrap -mx-1 items-center">
          <div class="px-1">
            <FormControl label="Pupil Premium" theme="ring">
              <div class="flex flex-wrap -mx-1 py-1 px-2">
                <label :class="{ active: ppIndicator }" class="year-select text-xs">
                  <input type="checkbox" v-model="ppIndicator" :true-value="1" :false-value="0" />
                  Indicator
                </label>
                <label :class="{ active: ppEligible }" class="year-select text-xs">
                  <input type="checkbox" v-model="ppEligible" :true-value="1" :false-value="0" />
                  Eligible
                </label>
              </div>
            </FormControl>
          </div>

          <div class="px-1">
            <FormControl label="Special Education Needs" theme="ring">
              <div class="flex flex-wrap -mx-1 py-1 px-2">
                <label :class="{ active: sen.includes('K') }" class="year-select text-xs">
                  <input type="checkbox" v-model="sen" value="K" />
                  SEN support
                </label>
                <label :class="{ active: sen.includes('E') }" class="year-select text-xs">
                  <input type="checkbox" v-model="sen" value="E" />
                  Education, health and care plan
                </label>
              </div>
            </FormControl>
          </div>

          <div class="px-1">
            <FormControl label="" theme="ring">
              <div class="flex flex-wrap -mx-1 py-1 px-2">
                <label :class="{ active: eal }" class="year-select text-xs">
                  <input type="checkbox" v-model="eal" :true-value="1" :false-value="0" />
                  English as another language
                </label>
              </div>
            </FormControl>
          </div>

          <div class="btns4 p-2 py-3 flex-grow">
            <button class="btn4-clear" @click="reset">Reset</button>
            <button class="btn4 bg-bursts" @click="fetch">Filter</button>
          </div>
        </div>
      </div>
    </AdminSpinner>

    <AdminSpinner :ready="ready">
      <table class="my-6 mx-auto">
        <tr class="">
          <td class="ytd-r"></td>
          <td v-for="(val, i) in chart.short" :key="i" class="ytd-cell ytd-b">{{ val }}</td>
        </tr>
        <tr>
          <td class="ytd-r py-1 px-2 text-center">
            <img src="/images/bursts/user-icon.svg" alt="Active Users" class="w-8 inline-block" />
          </td>
          <td v-for="(val, i) in chart.totalActive" :key="i" class="ytd-cell ytd-b">{{ val }}</td>
        </tr>
        <tr>
          <td class="ytd-r py-1 px-2 text-center">
            <img src="/images/bursts/minutes-icon.svg" alt="Active Minutes" class="w-8 inline-block" />
          </td>
          <td v-for="(val, i) in chart.totalSeconds" :key="i" class="ytd-cell ytd-b">{{ val }}</td>
        </tr>
        <tr v-for="(level, i) in chart.levels" :key="i">
          <td class="ytd-r py-1 px-2 text-xs text-center">
            <div class="ribbon inline-block">{{ level.level }}</div>
          </td>
          <td v-for="(val, i2) in level.vals" :key="i2" :class="{ 'ytd-b': i === 10 }" class="ytd-cell">
            {{ val }}
          </td>
        </tr>

        <tr v-for="act in chart.activities" :key="act.slug">
          <td class="ytd-r p-2 flex items-center">
            <AdventureIcon :id="act.advId" class="h-6 mr-2" />
            <Bolts :level="act.level" />
          </td>
          <td v-for="(val, i) in act.vals" :key="i" :class="{ 'ytd-t': act.level == 1 }" class="ytd-cell">
            {{ val }}
          </td>
        </tr>
      </table>
    </AdminSpinner>

    <AdminModal :active="showVideo" title="BURSTS: School Insights" wide @close="showVideo = false">
      <Vimeo vid="779690149?h=8e455f7960" class="-m-2 rounded-br-xl" />
    </AdminModal>
  </div>
</template>

<style lang="postcss" scoped>
.ytd-cell {
  @apply text-center p-1;

  @screen lg {
    min-width: 70px;
  }

  &:nth-child(even) {
    @apply bg-white;
  }
}
.ytd-r {
  @apply border-r border-grey-l1;
}
.ytd-b {
  @apply border-b border-grey-l1;
}
.ytd-t {
  @apply border-t border-grey-l1;
}
</style>
