<script>
import StudentReinvite from '@/bursts/components/StudentReinvite.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import StudentGrid from '@/bursts/components/StudentGrid.vue';
import StudentTable from '@/bursts/components/StudentTable.vue';
import UserList from '@/admin/components/users/UserList.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import GroupForm from '@/bursts/components/GroupForm.vue';
import Ribbons from '@/bursts/components/Ribbons.vue';
import Events from '@/bursts/components/Events.vue';
import { $group } from '@/scripts/models.js';
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/cross.svg';

export default {
  // prettier-ignore
  components: { AdminSpinner, AdminModal, StudentTable, StudentGrid, GroupForm, UserList, Events, Ribbons, StudentReinvite },
  data: () => ({ group: $group.one(), showEdit: false, showInvite: false }),
  props: ['account'],

  metaInfo() {
    return { title: this.group.name || 'Group' };
  },

  computed: {
    groupId() {
      return this.$route.params.groupId;
    },
    userParams() {
      return { groupId: this.groupId, accountId: this.account.id };
    },
    showTeachers() {
      return this.isCreate || this.isAdmin;
    },
    showReinvite() {
      return this.isAdmin && this.group.totalInactiveStudents;
    },
    studentUri() {
      return `${this.account.burstsUri}groups/${this.groupId}/students/`;
    },

    ...mapGetters('user', ['isCreate', 'isAdmin', 'isCdAdmin']),
  },

  methods: {
    fetch() {
      this.group.$get({ id: this.groupId });
    },

    sync() {
      this.$api.post(`wonde/${this.account.wondeId}/groups/${this.group.wondeId}`).then(res => {
        this.$store.commit('toast/info', res);
        this.fetch();
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <AdminSpinner :res="group" hide>
      <div class="title-flex">
        <h2 class="head-4xl text-theme">{{ group.name }}</h2>

        <div class="btns4">
          <button v-if="isCdAdmin" class="btn4-clear" @click="sync">Sync Class</button>
          <button v-if="isAdmin" class="btn4 bg-theme" @click="showEdit = true">Edit</button>
        </div>
      </div>
      <p>{{ group.subject }} {{ group.description }}</p>
      <hr />

      <Ribbons :record="group" />
      <hr />
    </AdminSpinner>

    <Events :groupId="groupId" />

    <UserList v-if="showTeachers" :params="userParams" title="Teachers" class="my-8" simple>
      There are currently no teachers connected to this class
    </UserList>

    <StudentTable :groupId="groupId" label="active" :status="[2, 1]" :uri="studentUri" class="my-8" />

    <StudentGrid :group="group" :uri="studentUri" label="inactive" class="my-8" @invite="showInvite = true" />

    <AdminModal :active="showEdit" title="Edit Class" @close="showEdit = false">
      <GroupForm v-model="group" :account="account" @close="showEdit = false" />
    </AdminModal>

    <StudentReinvite v-if="isAdmin" :group="group" :active="showInvite" @change="fetch()" @close="showInvite = false" />
  </div>
</template>
