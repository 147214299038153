<script>
import LicenceList from '@/admin/components/licences/LicenceList.vue';
import LicenceForm from '@/admin/components/licences/LicenceForm.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import MetaTitle from '@/admin/components/MetaTitle.vue';
import { mapGetters } from 'vuex';
import { $licence } from '@/scripts/models.js';

export default {
  components: { AdminSpinner, AdminModal, LicenceForm, MetaTitle, LicenceList },
  data: () => ({ licences$: $licence.all(), licences: [], types: [], showEdit: false, current: {} }),
  props: ['account'],

  metaInfo() {
    return { title: this.account.name || 'Account' };
  },

  computed: {
    modalTitle() {
      return this.current.id ? 'Edit Licence' : 'Add Licence';
    },

    now() {
      return this.$dayjs().format('YYYY-MM-DD');
    },

    futureLicences() {
      return this.licences.filter(licence => licence.startDate > this.now);
    },
    currentLicences() {
      return this.licences.filter(licence => licence.current);
    },
    pastLicences() {
      return this.licences.filter(licence => licence.endDate < this.now);
    },

    stats() {
      return [
        { label: 'real PE Trial', value: this.account.$trialExpRealPe },
        { label: 'real PE Renewal', value: this.account.$renewalRealPe },
        { label: 'Bursts Renewal', value: this.account.$renewalBursts },
      ];
    },

    ...mapGetters('user', ['isCdAdmin']),
  },

  methods: {
    async fetch() {
      this.licences = await this.licences$.$get({ accountId: this.account.id });
    },

    edit(licence = {}) {
      if (!this.types.length)
        this.$api.get('licence-types').then(res => {
          this.types = res.data;
        });

      this.current = licence;
      this.showEdit = true;
    },

    close(update) {
      if (update) {
        this.fetch();
        this.$emit('update');
      }
      this.showEdit = false;
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <AdminSpinner :res="licences$" hide>
      <MetaTitle title="Membership" :stats="stats" class="mb-3">
        <button v-if="isCdAdmin" class="btn4 bg-pe" @click="edit()">
          Add Licence
          <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
        </button>
      </MetaTitle>

      <LicenceList title="Current" :licences="currentLicences" @edit="edit" />
      <LicenceList title="Future" :licences="futureLicences" @edit="edit" />
      <LicenceList title="Previous" :licences="pastLicences" @edit="edit" />

      <div v-if="licences$.empty" class="empty">No licences have been added.</div>
    </AdminSpinner>

    <AdminModal :active="showEdit" :title="modalTitle" @close="close">
      <AdminSpinner :ready="!!types.length" hide>
        <LicenceForm v-model="current" :account="account" :types="types" @close="close" />
      </AdminSpinner>
    </AdminModal>
  </div>
</template>
