<script>
import CharacteristicsModal from '@/content/components/modal/CharacteristicsModal.vue';
import ActivityPlanning from '@/content/components/parts/ActivityPlanning.vue';
import UniqueChildModal from '@/content/components/modal/UniqueChildModal.vue';
import PlayPartPupil from '@/content/components/parts/PlayPartPupil.vue';
import PhysicalModal from '@/content/components/modal/PhysicalModal.vue';
import KeywordsModal from '@/content/components/modal/KeywordsModal.vue';
import HomePreview from '@/content/components/previews/HomePreview.vue';
import PrintButton from '@/content/components/buttons/PrintButton.vue';
import PageTitle from '@/content/components/partials/PageTitle.vue';
import DocButton from '@/content/components/buttons/DocButton.vue';
import ThinkModal from '@/content/components/modal/ThinkModal.vue';
import Loader from '@/global/components/Loader.vue';
import routeInfo from '@/mixins/routeInfo.js';
import queries from '@/queries/parts.js';
import { mapState } from 'vuex';

const fModals = part => {
  return ['playCharacteristics', 'uniqueChild', 'playThink'].reduce((carry, handle) => {
    if (part[handle]) {
      carry[handle] = part[handle].map(page => {
        const [focus, subtitle] = page.focus.split(' - ');
        return { ...page, focus, subtitle };
      });
    }

    return carry;
  }, {});
};

export default {
  // prettier-ignore
  components: {
    Loader, PageTitle, PlayPartPupil, ActivityPlanning, HomePreview,
    PhysicalModal, KeywordsModal, UniqueChildModal, CharacteristicsModal, ThinkModal, 
    DocButton, PrintButton,
  },

  props: ['item', 'parts'],
  data: () => ({ rawPart: {}, step: 0 }),
  mixins: [routeInfo],

  metaInfo() {
    return { title: this.part.title };
  },

  computed: {
    skill() {
      const card = this.part.steps
        ?.map((step, i) => ({ i, ...step.funs?.[0] }))
        .filter(step => step.id)
        .findLast((step, i) => !i || (this.step >= step.i && step?.id));

      return card ? { skillWhat: card.skillWhat, skillHow: card.skillHow } : '';
    },

    part() {
      if (this.rawPart.id) {
        return { ...this.rawPart, ...(this.isFoundations && fModals(this.rawPart)) };
      }
      return {};
    },
    ...mapState(['view']),
  },

  methods: {
    fetch() {
      const { product, part, stage, type } = this.$route.params;
      this.rawPart = {};
      if (!part) return;

      this.$gql(
        queries[`${stage}home${type}`] || queries[`home${type}`] || queries[type],
        { product, part, extra: this.isFoundations },
        { inc: { foundations: this.isFoundations }, required: ['part'] }
      ).then(response => {
        this.rawPart = response.part || {};
        this.$store.dispatch('user/last', { title: this.part.title, type: 'item' });
      });
    },
  },

  created() {
    this.fetch();
  },

  watch: { $route: 'fetch' },
};
</script>

<template>
  <Loader :res="part.id" class="container">
    <PageTitle :title="`${item.title} Theme`" toggle v-if="isFoundations">
      <template v-if="view">
        <DocButton :entry="part" />
        <PrintButton />
      </template>
      <template v-else>
        <ThinkModal :thinkList="part.playThink" colour="foundations" />
        <PhysicalModal v-if="skill" :skill="skill" colour="foundations" />
        <CharacteristicsModal :char="part.playCharacteristics" />
        <UniqueChildModal :unique="part.uniqueChild" />
        <KeywordsModal :keywords="part.keywords" />
      </template>
    </PageTitle>

    <ActivityPlanning v-if="view" :part="part" :theme="item" :parts="parts" />
    <template v-else>
      <PlayPartPupil :part="part" :theme="item" @step="step = $event" />
      <HomePreview :menu="parts" pupil class="px-2 mx-2 mt-5 lg:mx-4 xl:mx-16 border-6 border-grey-l3" />
    </template>
  </Loader>
</template>
