<script>
import Link2 from '@/global/components/Link2.vue';
import { mapGetters } from 'vuex';
import { startCase } from 'lodash';

const uris = { zohoAccountId: 'zohoUrl', zohoRealId: 'zohoRealPeUrl', zohoBurstsId: 'zohoBurstsUrl' };
const types = {
  account: ['id', 'urn', 'wondeId', 'zohoAccountId', 'zohoRealId', 'zohoBurstsId'],
  user: ['id', 'uid', 'wondeId', 'riseUpId'],
  parent: ['id', 'verified'],
};

export default {
  components: { Link2 },
  props: ['object', 'type'],

  computed: {
    filtered() {
      return (types[this.type] || [])
        .map(key => ({ key, label: startCase(key), value: this.object[key], uri: this.object[uris[key]] }))
        .filter(item => item.value);
    },

    ...mapGetters('user', ['debug']),
  },
};
</script>

<template>
  <div v-if="debug && filtered.length" class="border-t mt-5 pt-5 border-grey-l1">
    <div class="flex flex-wrap text-xs -mx-2">
      <div v-for="item in filtered" :key="item.key" class="debug-tag">
        <label>{{ item.label }}</label>
        <Link2 :uri="item.uri">
          <span>{{ item.value }}</span>
        </Link2>
      </div>

      <div class="ml-auto px-2">
        <slot />
      </div>
    </div>
  </div>
</template>
