<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import query from '@/queries/product-intro.gql';
import Brand from '@/global/components/Brand.vue';
import Vimeo from '@/global/components/Vimeo2.vue';

export default {
  components: { AdminSpinner, Brand, Vimeo },
  data: () => ({ ready: false, page: {} }),
  props: ['slug'],

  metaInfo() {
    return { title: this.page.title };
  },

  computed: {
    brand() {
      return this.page?.programme === 'pe' ? 'full' : this.page?.programme;
    },
  },

  created() {
    this.$gql(query, { slug: this.slug }).then(res => {
      this.page = res.page;
      this.ready = true;
    });
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" :class="`theme-${page.programme}`" class="loader-lg" hide>
    <div class="container mt-6">
      <template v-if="page.programme">
        <img v-if="page.programme === 'bursts'" src="/brands/bursts.svg" alt="BURSTS" class="h-24 mb-6 mx-auto" />
        <Brand v-else :slug="brand" class="h-24 mb-5 mx-auto" />
      </template>

      <div class="bg-white p-8 rounded-2xl max-w-3xl mx-auto">
        <div v-html="page.text" class="prose prose-lg max-w-none"></div>
        <Vimeo :vid="page.video" class="mt-8" />

        <div class="btns4 mt-10">
          <a v-for="link in page.hyperLinks" :href="link.url" target="_blank" class="btn4 bg-theme">{{ link.text }}</a>
        </div>
      </div>
    </div>
  </AdminSpinner>
</template>
