<script>
import ActiveMins from '@/bursts/components/ActiveMins.vue';
import ActiveUsers from '@/bursts/components/ActiveUsers.vue';

export default {
  components: { ActiveMins, ActiveUsers },
  props: ['record'],

  computed: {
    ribbons() {
      return (this.record.levels || [])
        .map((total, i) => ({ level: i, total }))
        .filter(ribbon => ribbon.level && ribbon.total);
    },
  },
};
</script>

<template>
  <div class="text-3xl -mx-3 text-bursts">
    <div class="flex items-center justify-center my-4">
      <ActiveUsers :record="record" class="px-3" full />
      <ActiveMins :record="record" class="px-3" full />
    </div>

    <div v-if="ribbons.length" class="w-full my-4">
      <div class="textr-05 text-center font-semibold leading-tight mb-2">XP level awards</div>
      <div class="flex justify-center">
        <div v-for="ribbon in ribbons" :key="ribbon.level" class="flex items-center text-base">
          <div class="ribbon">{{ ribbon.level }}</div>
          <span class="text-theme text-2xl font-semibold ml-4 mr-6">{{ ribbon.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
