<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import '@/assets/svgs/buttons/audio-play.svg';
import '@/assets/svgs/buttons/audio-pause.svg';
import '@/assets/svgs/buttons/audio-sound.svg';
import '@/assets/svgs/buttons/audio-voice.svg';

const c = 2 * Math.PI * 50;

export default {
  components: { HaloButton },
  props: { audio: {}, autoplay: Boolean, loop: Boolean, progress: Boolean, icon: { type: String, default: 'sound' } },
  data: () => ({ played: false, playing: false, seconds: 0, offset: c }),

  methods: {
    play() {
      this.$refs.player.play();
      this.played = true;
    },
    pause() {
      this.$refs.player.pause();
    },
    toggle() {
      return this.playing ? this.pause() : this.play();
    },

    update() {
      if (!this.played) this.played = true;
      if (this.$refs.player) {
        const currentTime = this.$refs.player.currentTime;
        this.offset = c * (1 - currentTime / this.$refs.player.duration);

        if (Math.floor(currentTime) > this.seconds) {
          this.seconds = Math.floor(currentTime);
          this.$emit('tick', currentTime);
        }
      }
    },
    reset() {
      this.playing = false;
      this.played = false;
      this.seconds = 0;
      this.offset = c;
    },

    onPlaying() {
      this.playing = true;
      this.ended = false;
    },
    onPause() {
      this.playing = false;
    },
    onEnded() {
      this.ended = true;
    },
  },

  computed: {
    single() {
      this.reset();
      return (Array.isArray(this.audio) ? this.audio[0] : this.audio) || {};
    },
    dash() {
      return `stroke-dasharray:${c}; stroke-dashoffset:${this.offset};`;
    },
    icon$() {
      return this.playing ? 'audio-pause' : `audio-${this.icon}`;
    },
    label() {
      return `${this.playing ? 'Pause ' : 'Play '} ${this.icon === 'voice' ? 'Voice' : 'Music'}`;
    },
  },
};
</script>

<template>
  <HaloButton v-if="single.url" v-slot="{ colour }" :icon="icon$" :label="label" class="relative" @click="toggle">
    <audio
      :src="single.url"
      :autoplay="autoplay"
      :loop="loop"
      ref="player"
      preload="auto"
      @playing="onPlaying"
      @pause="onPause"
      @ended="onEnded"
      @timeupdate="update"
    ></audio>

    <svg
      v-if="progress"
      :class="`stroke-${colour}`"
      class="-rotate-90 absolute inset-0 fill-clear p-0.5 w-full h-full"
      width="100"
      height="100"
      viewBox="0 0 104 104"
    >
      <circle v-if="played" class="opacity-10" cx="52" cy="52" r="50" stroke-width="4" />
      <circle :style="dash" class="trns-dash" cx="52" cy="52" r="50" stroke-width="4" />
    </svg>
  </HaloButton>
</template>
