<script>
import YearToDate from '@/bursts/components/YearToDate.vue';

export default {
  components: { YearToDate },
  props: ['account'],
};
</script>

<template>
  <YearToDate :accountId="account.id" />
</template>
