<script>
import { num } from '@/scripts/filters.js';

const dateRex = /^\d{4}-\d{2}-\d{2}/;

export default {
  props: ['title', 'stats', 'btns'],

  computed: {
    stats$() {
      return (this.stats || [])
        .map(item => {
          if (typeof item.value === 'number') return { ...item, value: num(item.value) };
          if (dateRex.test(item.value)) return { ...item, value: this.$djMed(item.value) };
          return item;
        })
        .filter(item => item.value);
    },

    showBtns() {
      return this.btns !== false;
    },
  },
};
</script>

<template>
  <div class="flex justify-between items-center flex-wrap -my-2">
    <div class="flex items-center my-2">
      <div class="head-4xl">{{ title }}</div>

      <div v-for="item in stats$" :key="item.label" class="ttl-meta">
        <label>{{ item.label }}</label>
        <div>{{ item.value }}</div>
      </div>
    </div>

    <div v-if="showBtns" class="btns4 my-2">
      <slot />
    </div>
  </div>
</template>
