<script>
import routeInfo from '@/mixins/routeInfo.js';
import Loader from '@/global/components/Loader.vue';
import { mapMutations, mapState } from 'vuex';
import themeQuery from '@/queries/home/theme.gql';
import funsQuery from '@/queries/home/funs.gql';

export default {
  components: { Loader },
  mixins: [routeInfo],
  data: () => ({ item: {}, playTypes: [] }),

  metaInfo() {
    return { title: `${this.productInfo.title} ${this.item.title || ''} Theme` };
  },

  computed: {
    homeItem() {
      if (!this.item.homeItem) return {};

      const item = this.item.homeItem[0] || {};

      if (this.item.funs) item.funs = this.item.funs[0];
      if (this.item.steps) item.steps = this.item.steps;

      return item;
    },

    stageSlug() {
      return this.$route.params.stage;
    },

    parts() {
      if (!this.item.homeParts) return [];

      if (this.stageSlug === 'themes') {
        const types = this.playTypes.reduce((carry, t) => {
          carry[t.slug] = { ...t, parts: [], uri: `${this.item.uri}/${t.slug}` };
          return carry;
        }, {});

        return Object.values(
          this.item.homeParts.reduce((carry, part) => {
            carry[part.type].parts.push({ ...part, uri: `${carry[part.type].uri}/${part.slug}` });
            return carry;
          }, types)
        ).map(type => ({ ...type, uri: type.parts.length === 1 ? type.parts[0].uri : type.uri }));
      } else if (this.stageSlug === 'funs') {
        return [this.homeItem, ...this.item.homeParts].map(part => {
          const type = part.type === 'funs' ? 'skill' : 'game';
          return { ...part, uri: `/home/funs/${this.homeItem.slug}/${type}/${part.slug}` };
        });
      }
    },

    ...mapState(['view']),
  },

  methods: {
    fetch() {
      this.item = {};
      const uri = this.$route.path.split('/').slice(0, 4).join('/');
      const query = this.stageSlug === 'themes' ? themeQuery : funsQuery;
      this.$gql(query, { product: this.productSlug, uri }).then(res => {
        this.playTypes = res.playTypes || [];
        this.item = res.item;
      });
    },
    ...mapMutations(['viewToggle']),
  },

  watch: {
    '$route.params.item': 'fetch',
  },

  created() {
    if (this.isHome && this.view) {
      this.viewToggle();
    }

    this.fetch();
  },
};
</script>

<template>
  <Loader :res="item.id">
    <transition name="fade" mode="out-in">
      <RouterView :item="homeItem" :parts="parts" :key="$route.params.part" />
    </transition>
  </Loader>
</template>
