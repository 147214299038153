<script>
import Loader from '@/global/components/Loader.vue';
import VimeoText from '@/content/components/partials/VimeoText.vue';
import query from '@/queries/dance/intro.gql';
import routeInfo from '@/mixins/routeInfo.js';

export default {
  components: { Loader, VimeoText },
  data: () => ({ intro: {} }),
  mixins: [routeInfo],

  metaInfo() {
    return { title: this.productInfo.title };
  },

  methods: {
    fetch() {
      return this.$gql(query).then(res => {
        this.intro = res.entry;
      });
    },
  },

  watch: { $route: 'fetch' },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <Loader :res="intro.id" class="container">
    <div class="pb-10 bg-white curves">
      <h1 class="text-dance p-8">{{ productInfo.title }}</h1>
      <VimeoText :vid="intro.video" colour="dance" autoplay />
    </div>
  </Loader>
</template>
