<script>
import FormControl from '@/admin/components/FormControl.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import { mapGetters } from 'vuex';

const base = { search: '' };

export default {
  components: { FormControl, AdminSpinner },
  data: () => ({ ready: false, ...base, results: {} }),
  metaInfo: { title: 'Users Search' },

  computed: {
    params() {
      return { search: this.search };
    },
    empty() {
      return this.ready && !this.accounts2.length && !this.parents2.length;
    },

    accounts2() {
      return (this.results.accounts || []).map(account => ({
        ...account,
        fixed: account.users.length <= 1 ? 'w-1/2 lg:w-1/3' : 'w-full',
        url: `/accounts/${account.id}/users`,
      }));
    },

    parents2() {
      return (this.results.parents || []).map(parent => ({
        ...parent,
        students: parent.students.map(student => ({
          ...student,
          url: `/accounts/${student.accountId}/bursts/groups/${student.groupId}/students/${student.id}`,
        })),
      }));
    },

    ...mapGetters('user', ['isCreate', 'isCdSuper']),
  },

  methods: {
    submit(manual = false) {
      if (manual) localStorage.setItem('userFilter', JSON.stringify(this.params));

      this.ready = false;
      this.$api.get('users/search', { params: this.params }).then(res => {
        this.results = res.data;
        this.ready = true;
      });
    },

    reset() {
      Object.keys(base).forEach(key => {
        this[key] = base[key];
      });
    },
  },

  created() {
    if (!this.isCreate) this.$router.push('/');

    const saved = JSON.parse(localStorage.getItem('userFilter')) || {};
    Object.keys(saved).forEach(key => {
      this[key] = saved[key];
    });

    this.submit(1);
  },
};
</script>

<template>
  <div class="container pt-8">
    <div class="flex justify-between items-center mb-6">
      <div class="head-4xl leading-none">User Search</div>
      <div class="btns4">
        <!-- <RouterLink to="/accounts" class="btn4">Account Search</RouterLink> -->
      </div>
    </div>

    <form @submit.prevent="submit" class="p-3 pt-2 pb-1 mb-8 bg-white rounded-lg shadow flex">
      <div class="flex-grow">
        <FormControl id="search" label="Search" theme="ring" :active="search.length" hasClear @clear="search = ''">
          <input type="text" id="search" v-model.trim="search" />
        </FormControl>
      </div>
      <div class="btns4 p-2 py-2">
        <!-- <button type="button" @click="reset()" class="btn4-clear">Reset</button> -->
        <button class="btn4 bg-pe" type="submit">Search</button>
      </div>
    </form>

    <AdminSpinner :ready="ready" class="loader-lg">
      <template v-if="accounts2.length">
        <h2 class="text-3xl text-pe mb-5 font-bold">Accounts</h2>
        <div class="flex flex-wrap -mx-2">
          <div v-for="account in accounts2" :key="account.id" :class="account.fixed" class="mb-4 px-2 min-w-1/3">
            <div class="border-2 border-grey-l1 p-2 rounded-lg">
              <RouterLink :to="account.url" class="card w-auto inline-block text-pe mb-2 max-w-full">
                <h3 class="text-black font-bold truncate">{{ account.name }}</h3>
              </RouterLink>
              <div class="flex flex-wrap -m-1">
                <div v-for="user in account.users" :key="user.id" class="p-1">
                  <div class="text-xs bg-grey-l2 py-1 px-2 rounded">
                    <div class="truncate font-bold">{{ user.name || '-' }}</div>
                    <div class="truncate">{{ user.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="parents2.length && isCdSuper">
        <h2 class="text-3xl text-bursts my-5 font-bold">Parents</h2>
        <div class="flex flex-wrap -mx-2">
          <div v-for="user in parents2" :key="user.id" class="w-1/3 lg:w-1/4 p-2">
            <div class="border-2 border-grey-l1 p-2 rounded-lg text-sm">
              <div class="bg-grey-l2 rounded px-2 py-1">
                <div class="truncate font-bold">{{ user.name }}</div>
                <div class="truncate">{{ user.email }}</div>
              </div>

              <div v-for="student in user.students" :key="student.id">
                <RouterLink :to="student.url" class="card mt-2 text-bursts">
                  <div class="font-medium text-black truncate">{{ student.name }}</div>
                </RouterLink>
              </div>

              <div v-if="!user.students.length" class="empty mt-2 p-2">No children</div>
            </div>
          </div>
        </div>
      </template>

      <div v-if="empty" class="empty my-4">No users were found</div>
    </AdminSpinner>
  </div>
</template>
