<script>
import routeInfo from '@/mixins/routeInfo.js';
import { mapState } from 'vuex';
import '@/assets/svgs/icons/half-disc.svg';

const eee = ['emerging', 'expected', 'exceeding'];
const ttt = ['tricky', 'trickier', 'trickiest'];

const peAction = ['funs', 'complete <strong>some</strong>', 'complete <strong>all</strong>'];
const peFuns = ['grey', 'yellow', 'green', 'red', 'blue', 'pink'];
const peColour = ['grey', 'fun-yellow', 'fun-green', 'fun-red', 'fun-blue', 'fun-pink'];

// prettier-ignore
const peGrid = [
  [[0, 0], [1, 1], [1, 2]],
  [[1, 1], [1, 2], [2, 1]],
  [[2, 1], [2, 2], [3, 1]],
  [[2, 2], [3, 1], [3, 2]],
  [[3, 1], [3, 2], [4, 1]],
  [[3, 2], [4, 1], [4, 2]],
  [[4, 1], [4, 2], [5, 1]],
];

const gymAction = ['explore', 'perform some', 'perform all'];
const gymFocus = ['floor', 'apparatus', 'floor and apparatus', 'floor, partner work and apparatus'];

// prettier-ignore
const gymGrid = [
  [[[0, 0, 0], [0, 0, 2], [1, 0, 0]], [[0, 0, 0], [0, 0, 2], [1, 0, 0]]],
  [[[0, 0, 2], [1, 0, 0], [1, 0, 1]], [[0, 0, 2], [1, 0, 0], [1, 0, 1]]],
  [[[1, 0, 2], [2, 0, 0], [2, 0, 2]], [[1, 0, 2], [2, 0, 0], [2, 0, 2]]],
  [[[2, 0, 0], [2, 0, 3], [1, 1, 0]], [[2, 0, 0], [2, 0, 2], [1, 1, 0]]],
  [[[2, 0, 3], [2, 1, 0], [2, 1, 3]], [[2, 0, 2], [2, 1, 0], [2, 1, 2]]],
  [[[2, 1, 0], [2, 1, 2], [2, 2, 2]], [[2, 1, 0], [2, 1, 2], [2, 2, 2]]],
];

const danceAction = ['explore', 'perform', 'perform and sequence'];

// prettier-ignore
const danceGrid = [
  [[0, 0, 1], [1, 0, 1], [2, 0, 1]],
  [[1, 0, 1], [2, 0, 1], [1, 0, 2]],
  [[1, 0, 2], [2, 0, 2], [1, 1, 1]],
  [[1, 1, 1], [2, 1, 1], [1, 1, 2]],
  [[1, 1, 2], [2, 1, 2], [1, 2, 1]],
  [[1, 2, 1], [2, 2, 1], [1, 2, 2]],
];

export default {
  props: { unit: {}, lesson: {}, single: Boolean, flex: Boolean, simple: Boolean },
  mixins: [routeInfo],

  computed: {
    levels() {
      if (this.isPe)
        return (peGrid[this.yearNum] || []).map(([colour, action], i) => {
          const bg = peColour[colour];
          const styles = `fill-${bg} text-${bg}${action === 1 ? '-shade' : ''}`;
          return { label: eee[i], action: peAction[action], level: peFuns[colour], styles, i };
        });

      if (this.isGym)
        return ((gymGrid[this.yearNum] || [])[this.unitNum - 1] || []).map(([action, tricky, focus], i) => {
          return { label: eee[i], action: gymAction[action], level: ttt[tricky], location: gymFocus[focus], i };
        });

      if (this.isDance)
        return (danceGrid[this.yearNum] || []).map(([action, tricky, num], i) => {
          return { label: eee[i], action: danceAction[action], level: ttt[tricky], num, i };
        });
    },

    level2() {
      return this.single ? [this.levels[1]] : this.levels;
    },

    funs() {
      return (this.unit?.funsStations || []).map(({ label }) => label);
    },
  },
};
</script>

<template>
  <div v-if="unitNum" :class="flex ? 'flex -mx-2' : ''">
    <div v-for="row in level2" :key="row.i" :class="flex ? 'w-1/3 px-2' : 'mt-2'" class="flex items-center">
      <svg :class="row.styles" class="w-12 h-12 mr-2 mb-1 bg-white rounded-full p-0.5 shrink-0">
        <use :xlink:href="`#${!isPe ? row.level : 'half-disc'}`" />
      </svg>

      <div>
        <div v-if="!simple" class="font-extrabold capitalize leading-none">{{ row.label }}</div>
        <template v-if="isPe && row.action === 'funs'">
          I can explore <strong>{{ funs[0] }}</strong> and <strong>{{ funs[1] }}</strong> movements
        </template>
        <template v-else>
          I can <span v-html="row.action"></span> <span class="capitalize">{{ row.level }}</span>
          {{ row.num }} challenges <span v-if="row.location">({{ row.location }})</span>
        </template>
      </div>
    </div>
  </div>
</template>
