<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/cofl.svg';

export default {
  components: { Modal, HaloButton },
  props: { char: { default: () => ({}) } },
  data: () => ({ modal: false }),
};
</script>

<template>
  <div>
    <Modal :open="modal" icon="cofl" title="Characteristics of Learning" @close="modal = false">
      <div v-for="block in char" :key="block.id">
        <h3 class="mb-3 text-3xl">
          <strong>{{ block.focus }}</strong>
          <span v-if="block.subtitle">&nbsp;- {{ block.subtitle }}</span>
        </h3>
        <div class="mb-3 text-3xl">Can you:</div>
        <div v-html="block.pupilText" class="rdct rdct-modal"></div>
      </div>
    </Modal>

    <HaloButton icon="cofl" label="Characteristics of Learning" @click="modal = true" />
  </div>
</template>
