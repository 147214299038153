<script>
import { undupe } from '@/scripts/filters.js';

export default {
  props: ['list'],

  computed: {
    equipment() {
      return undupe(this.list).join(', ');
    },
    string() {
      return this.equipment.length ? `You will need ${this.equipment}.` : 'No equipment needed.';
    },
  },
};
</script>

<template>
  <span>{{ string }}</span>
</template>
