<script>
import routeInfo from '@/mixins/routeInfo.js';
import Loader from '@/global/components/Loader.vue';
import Disc from '@/content/components/partials/Disc.vue';

export default {
  components: { Loader, Disc },
  mixins: [routeInfo],
  props: ['item', 'parts'],

  computed: {
    groups() {
      return [
        { items: this.parts.slice(0, 3), alt: 'even:pr-20', alt2: 'flex-row-reverse', order: 'order-1' },
        { items: this.parts.slice(3), alt: 'even:pl-20', alt2: '', order: 'order-3' },
      ];
    },
    selected() {
      return this.parts.find(item => item.slug === this.$route.params.type) || { parts: [] };
    },
    selectedParts() {
      return this.selected.parts.map(p => ({ ...p, playType: p.playType ? p.playType[0] : {} })) || [];
    },
  },

  mounted() {
    const { path, params } = this.$route;
    if (params.stage === 'funs') this.$router.push(path.replace(/\/$/, '') + '/skill/' + params.item);
    this.$store.dispatch('user/last', { title: this.item.title, type: 'item' });
  },

  beforeRouteEnter(to, from, next) {
    to.params.stage === 'funs' ? next(`${to.path}/skill/${to.params.item}`) : next();
  },
};
</script>

<template>
  <Loader :res="item.id && $route.params.stage !== 'funs'">
    <div class="container">
      <div class="p-4 text-center bg-white curves theme-box">
        <div class="text-center">
          <h1 :class="`text-${productSlug}`" class="mb-2">{{ item.title }} {{ selected.title }}</h1>
          <div v-if="item.funs">
            <div class="font-bold text-physical">{{ item.funs.category }}</div>
            <div>{{ item.funs.title }}</div>
          </div>
        </div>

        <div class="flex -mt-8" v-if="!selected.id">
          <div v-for="(group, i) in groups" :key="i" :class="group.order" class="w-5/12">
            <div v-for="part in group.items" :key="part.slug" :class="[group.alt, group.alt2]" class="flex">
              <RouterLink :to="part.uri" :class="group.alt2" class="lg:flex items-center hvr">
                <Disc :asset="part.previewImage" :colour="productSlug" class="w-44 hvr0" />
                <div :class="`text-${productSlug}`" class="font-bold text-lg mx-4 my-2">{{ part.title }}</div>
              </RouterLink>
            </div>
          </div>
          <div class="w-2/12 flex items-center justify-center mb-12 order-2">
            <img v-for="img in item.point" :key="img.id" :src="img.url" class="theme-point" alt />
          </div>
        </div>

        <template v-else-if="!$route.params.part">
          <div class="flex justify-center items-center mb-8">
            <RouterLink v-for="part in parts" :key="part.slug" :to="part.uri" class="mini-link trns-trfm hvr mx-2">
              <Disc :asset="part.previewImage" :colour="productSlug" class="w-16 hvr0 border-3" />
            </RouterLink>
          </div>
          <div class="flex justify-center flex-wrap mx-auto max-w-4xl">
            <RouterLink v-for="part in selectedParts" :key="part.slug" :to="part.uri" class="mb-2 hvr w-44 mx-3">
              <Disc :asset="part.playType.previewImage" :colour="productSlug" class="hvr0 mb-2 w-44" />
              <div :class="`text-${productSlug}`" class="font-bold label">{{ part.title }}</div>
              <div v-if="part.playType">{{ part.playType.title }}</div>
            </RouterLink>
          </div>
        </template>
      </div>
    </div>
  </Loader>
</template>

<style lang="postcss" scoped>
.theme-box {
  min-height: 650px;
}

.theme-point {
  height: 260px;
  width: 260px;
  max-width: none;
}

.mini-link.router-link-active {
  transform: scale(1.3);
}
</style>
