import Dashboard from '@/global/views/Dashboard.vue';
import Accounts from '@/admin/views/Accounts.vue';
import UsersCreate from '@/admin/views/UsersCreate.vue';
import Account from '@/admin/account/Account.vue';
import MyAccount from '@/admin/account/MyAccount.vue';
import Purchase from '@/admin/account/Purchase.vue';
import Membership from '@/admin/account/Membership.vue';
import Training from '@/admin/account/Training.vue';
import UsersAccount from '@/admin/account/UsersAccount.vue';
import UsersHome from '@/admin/account/UsersHome.vue';
import UserSearch from '@/admin/views/UserSearch.vue';
import UserProfile from '@/admin/views/UserProfile.vue';
import Support from '@/admin/views/Support.vue';
import Groups from '@/bursts/views/Groups.vue';
import Bursts from '@/bursts/views/Bursts.vue';
import BurstsDash from '@/bursts/views/Dash.vue';
import DashAccounts from '@/bursts/views/DashAccounts.vue';
import YearStats from '@/bursts/views/YearStats.vue';
import Student from '@/bursts/views/Student.vue';
import Group from '@/bursts/views/Group.vue';
import Learning from '@/global/views/Learning.vue';
import MicroLearns from '@/global/views/MicroLearns.vue';
import Programmes from '@/global/views/Programmes.vue';
import Real from '@/global/views/Real.vue';

import YearToDate from '@/bursts/components/YearToDate.vue';

export default [
  // shared
  { path: '', component: Dashboard },
  { path: '/profile', component: UserProfile },
  { path: '/user-search', component: UserSearch },
  { path: '/create', component: UsersCreate },
  { path: '/support/:slug1?/:slug2?', component: Support },

  { path: '/accounts', component: Accounts },
  {
    path: '/accounts/:accountId(\\d+)?',
    component: Account,
    children: [
      { path: '', component: Membership },
      { path: 'users', component: UsersAccount },
      { path: 'home', component: UsersHome },
      { path: 'training', component: Training },

      { path: 'bursts', redirect: 'bursts/groups' },
      { path: 'bursts/groups', component: Groups, meta: { theme: 'bursts' } },
      { path: 'bursts/groups/:groupId', component: Group, meta: { theme: 'bursts' } },
      { path: 'bursts/groups/:groupId/students/:studentId', component: Student, meta: { theme: 'bursts' } },
      { path: 'bursts/year', component: YearStats, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/account',
    component: MyAccount,
    children: [
      // { path: '', component: Membership },
      { path: '/', redirect: 'users' },
      { path: 'users', component: UsersAccount },
      { path: 'home', component: UsersHome },
      { path: 'training', component: Training },
      { path: 'purchase', component: Purchase },
    ],
  },

  {
    path: '/bursts/stats',
    component: BurstsDash,
    meta: { theme: 'bursts' },
    children: [
      { path: '/', redirect: 'year' },
      { path: 'year', component: YearToDate, meta: { theme: 'bursts' } },
      { path: 'accounts', component: DashAccounts, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/bursts',
    component: Bursts,
    meta: { theme: 'bursts' },
    children: [
      { path: 'groups', component: Groups, meta: { theme: 'bursts' } },
      { path: 'groups/:groupId', component: Group, meta: { theme: 'bursts' } },
      { path: 'groups/:groupId/students/:studentId', component: Student, meta: { theme: 'bursts' } },
      { path: 'year', component: YearStats, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/real',
    component: Real,
    children: [{ path: '/', component: Programmes }],
  },

  { path: '/learning', component: Learning },
  { path: '/learning/micro', component: MicroLearns },
];
