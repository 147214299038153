import Vue from 'vue';
import router from './router';
import store from './store';

import Meta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';
import { sync } from 'vuex-router-sync';
import '@/scripts/outer.js';

import $api from '@/scripts/api.js';
import $gql from '@/scripts/gql.js';
import $products from '@/scripts/products.js';
import $dayjs from '@/scripts/day.js';
import App from '@/App.vue';

import '@/assets/vueperslides.css';
import '@/assets/main.css';

sync(store, router);

Vue.config.productionTip = false;

Vue.prototype.$api = $api;
Vue.prototype.$gql = $gql;
Vue.prototype.$products = $products;

Vue.use($dayjs);
Vue.use(Meta);
Vue.use(VueGtm, { id: 'GTM-NXKPN3MP', debug: true, vueRouter: router });

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.dispatch('init');
  },
}).$mount('#real');
