T
<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import VimeoText from '@/content/components/partials/VimeoText.vue';
import Hero from '@/content/components/partials/Hero.vue';
import '@/assets/svgs/buttons/video-l1.svg';
import '@/assets/svgs/buttons/video-l2.svg';

const levelLabels = ['Tricky', 'Trickier', 'Trickiest'];

export default {
  components: { Hero, VimeoText, HaloButton },
  props: ['part', 'lesson'],
  data: () => ({ currentPage: {}, currentLevel: null }),

  computed: {
    combined() {
      return [
        { title: 'Intro', slug: 'intro', video: this.part.intro },
        { title: 'Exploration', slug: 'exploration', heroImage: this.part.heroImage, pupilText: this.part.pupilText },
        ...this.part.skills.map((skill, i) => {
          const levels = skill.levels.reduce((carry, { heroImage, videos }, i) => {
            const label = levelLabels[i];
            return [...carry, { heroImage, label, videos: this.mapVideos(videos, label, i === this.yearLevel) }];
          }, []);

          return {
            title: `Skill ${i + 1} - ${skill.title}`,
            levels: this.$route.params.year < 3 ? levels.slice(0, 2) : levels,
          };
        }),
      ];
    },

    current() {
      return this.currentLevel ? this.currentLevel : this.currentPage;
    },

    yearLevel() {
      return [0, 0, 0, 1, 1, 2][this.$route.params.year];
    },
    yearVideo() {
      return [0, 0, 1, 0, 1, 0][this.$route.params.year];
    },
  },

  methods: {
    mapVideos(videos, label, yearLevel) {
      return videos.map((video, i) => {
        const colour = yearLevel && i === this.yearVideo ? 'tricky' : 'dance';
        return { ...video, num: i + 1, label, icon: `video-l${i + 1}`, colour };
      });
    },

    selectPage(page) {
      this.currentPage = page;
      this.currentLevel = page.levels ? page.levels[this.yearLevel] : null;
    },

    selectLevel(level) {
      this.currentLevel = level;
    },
  },

  mounted() {
    this.selectPage(this.combined[0]);
  },
};
</script>

<template>
  <div>
    <div class="flex justify-start mx-6 mb-6 skill-select2">
      <div v-for="page in combined" :key="page.slug">
        <button :class="{ active: page.slug === currentPage.slug }" class="dsk-btn" @click="selectPage(page)">
          {{ page.title }}
        </button>
      </div>
    </div>

    <VimeoText v-if="current.video" :vid="current.video" :lines="current.textLines" colour="dance" />
    <Hero v-if="current.heroImage" :asset="current.heroImage" />

    <div v-if="current.pupilText" class="px-12 py-8 rdct rdct-pupil">
      <h2>Quickfire Start</h2>
      <div v-html="current.pupilText"></div>
    </div>

    <div v-if="currentPage.levels" class="flex justify-center mt-4 text-2xl">
      <div v-for="level in currentPage.levels" :key="level.label" class="flex flex-wrap justify-center w-36 mx-2">
        <button :class="{ active: level.label === current.label }" class="tricky-select" @click="selectLevel(level)">
          {{ level.label }}
        </button>

        <HaloButton
          v-for="video in level.videos"
          :key="video.video"
          :icon="video.icon"
          :colour="video.colour"
          :active="video.video === current.video"
          :class="level.label !== current.label ? 'opacity-50 pointer-events-none' : ''"
          class="w-16 h-16 m-1"
          @click="selectLevel(video)"
        />
      </div>
    </div>

    <div
      v-if="lesson.unit && lesson.unit.skillText"
      v-html="lesson.unit.skillText"
      class="px-8 mt-5 mb-3 rdct rdct-pupil text-center"
    ></div>
  </div>
</template>

<style lang="postcss" scoped>
.dsk-btn {
  @apply bg-dance-shade2 text-white rounded-4xl py-2 px-4 mx-1 hover:bg-dance;
  transition: background-color 0.3s ease-in-out;

  &.active {
    @apply bg-dance;
  }
}

.tricky-select {
  @apply bg-tricky-shade rounded-full w-32 h-32 py-2 px-4 mx-1 text-white justify-center items-center flex;
  @apply hover:bg-tricky;
  transition: background-color 0.3s ease-in-out;

  &.active {
    @apply bg-tricky;
  }
}
</style>
