<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/unique-child.svg';

const slugs = { pdv: 'PD', pse: 'PSED', cal: 'C&L' };

export default {
  components: { Modal, HaloButton },
  props: { unique: { default: () => [] } },
  data: () => ({ modal: false, pageNo: 0 }),

  computed: {
    pages() {
      return this.unique.map(page => ({ ...page, slug: slugs[page.focusHandle.split(['-'])[0]] }));
    },
    page() {
      return this.pages[this.pageNo] || {};
    },
  },
};
</script>

<template>
  <div>
    <Modal :open="modal" icon="unique-child" title="Unique Child" @close="modal = false">
      <div class="inline-flex mb-8 text-3xl border-2 border-white rounded-md">
        <button
          v-for="(row, i) in pages"
          :key="row.id"
          :class="pageNo === i ? 'text-foundations bg-white' : 'text-white'"
          class="px-5 py-1 border-l-2 border-white focus:outline-none first:border-l-0"
          @click="pageNo = i"
        >
          {{ row.slug }}
        </button>
      </div>

      <h3 class="mb-3 text-3xl">
        <strong>{{ page.focus }}</strong>
        <span v-if="page.subtitle">&nbsp;- {{ page.subtitle }}</span>
      </h3>
      <div class="mb-3 text-3xl">Can you:</div>
      <div v-html="page.pupilText" class="rdct rdct-modal"></div>
    </Modal>

    <HaloButton icon="unique-child" label="Unique Child" @click="modal = true" />
  </div>
</template>
