<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Roulette from '@/content/components/partials/Roulette.vue';
import Modal from '@/content/components/partials/Modal.vue';
import Die from '@/content/components/partials/Die.vue';
import { mapState } from 'vuex';
import '@/assets/svgs/buttons/dice-button.svg';
import '@/assets/svgs/buttons/roulette-button.svg';

export default {
  components: { Modal, Die, Roulette, HaloButton },
  props: { dice: { default: () => [] }, part: {}, ability: {}, big: Boolean },
  data: () => ({ modal: false }),

  computed: {
    dice$() {
      if (!this.allDice.length) return [];

      const slugs = (this.dice || []).map(die => die.slug);

      if (this.part && this.part.type === 'review' && this.ability) {
        if (this.ability.slug !== 'fitness') slugs.unshift('fitness');
        slugs.unshift(this.ability.slug);
      }

      const dice = slugs.map(slug => this.allDice.find(die => die.slug === slug));
      const luckyDip = dice.filter(die => die.roulette).flatMap(({ sides }) => sides);

      return dice.map(die => (die.slug === 'lucky-dip' ? { ...die, sides: luckyDip } : die));
    },

    hasRoulette() {
      return this.dice$.some(die => die.roulette);
    },
    icon() {
      return this.hasRoulette ? 'roulette-button' : 'dice-button';
    },

    ...mapState({ allDice: 'dice' }),
  },

  async created() {
    await this.$store.dispatch('loadDice');
  },
};
// http://localhost:8080/gym/year/4/unit/1/lesson/2/game/sequences
</script>

<template>
  <div v-if="dice$.length">
    <Modal :open="modal" :icon="icon" bg="dice" wide @close="modal = false">
      <div class="text-5xl -mt-12 mb-8 font-medium">
        Click a {{ this.hasRoulette ? 'roulette' : 'die' }} to {{ this.hasRoulette ? 'spin' : 'roll' }}
      </div>
      <div class="z-10 relative flex flex-wrap -mx-4 pt-1 justify-center">
        <div v-for="die in dice$" :key="die.id" :class="dice$.length > 3 ? 'w-1/4' : 'w-1/3'" class="px-4">
          <component :is="die.roulette ? 'Roulette' : 'Die'" :die="die" @roll="$emit('roll', $event)" />
        </div>
      </div>
    </Modal>

    <HaloButton :class="big ? 'h-24 w-24 lg:h-36 lg:w-36' : ''" :icon="icon" label="Dice" @click="modal = true" />
  </div>
</template>
