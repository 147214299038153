<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import Pagination from '@/admin/components/Pagination2.vue';
import WondeGroup from '@/admin/components/users/WondeGroup.vue';
import AdminModal from '@/admin/components/AdminModal.vue';

export default {
  components: { AdminModal, Pagination, AdminSpinner, WondeGroup },
  data: () => ({ groups: [], group: {}, page: 1, count: 24, showDone: false, showAdd: false, ready: false }),
  props: ['account'],

  computed: {
    uri() {
      return `wonde/${this.account.wondeId}/groups`;
    },

    slice() {
      const end = this.page * this.count;
      return this.groups.slice(end - this.count, end);
    },

    students() {
      return this.group.students || [];
    },
    newStudents() {
      return this.students.filter(student => !student.groupId && !student.parents);
    },
    inOtherGroup() {
      return this.students.filter(student => student.groupId);
    },
    withParents() {
      return this.students.filter(student => student.parents && !student.groupId);
    },
  },

  methods: {
    select(group) {
      this.showAdd = true;
      if (group.id !== this.group.id) this.fetchGroup(group.id);
    },

    invite() {
      if (this.ready) {
        this.ready = false;
        this.$api.post(`${this.uri}/${this.group.id}`).then(() => {
          const index = this.groups.findIndex(i => i.id === this.group.id);
          this.$set(this.groups, index, { ...this.groups[index], synced: true });
          this.$emit('update');
          this.showAdd = false;
          // this.showDone = true;
          this.ready = true;
        });
      }
    },

    fetch() {
      this.$api.get(this.uri).then(({ data }) => {
        this.groups = data;
        this.ready = true;
      });
    },
    fetchGroup(id) {
      this.ready = false;
      this.$api.get(`${this.uri}/${id}`).then(({ data }) => {
        this.group = data;
        this.ready = true;
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" hide class="x-m-6 xp-6">
    <div v-if="!showAdd" class="px-4 py-3 rounded-br-md bg-grey-l3 shadow-inner -m-3">
      <div class="note note-warn mb-3">
        <svg class="note-icon"><use xlink:href="#info" /></svg>
        <p>
          This class information is generated by your school's Management Information System (MIS). <br />Any
          ammendments should be made by your MIS administrator.
        </p>
      </div>

      <div style="min-height: 435px">
        <div class="flex flex-wrap -mx-3">
          <div v-for="group in slice" :key="group.wondeId" class="w-1/2 px-3 my-2 md:w-1/3">
            <WondeGroup :group="group" @add="select" />
          </div>
        </div>
      </div>
      <div v-if="ready && !groups.length" class="empty mb-4">No classes can be found</div>

      <Pagination :total="groups.length" :limit="count" :page="page" class="mt-3" @change="page = $event" />
    </div>

    <template v-else>
      <p class="mb-3">
        Do you want to add the class called <strong>"{{ group.name }}"</strong>?
      </p>

      <template v-if="newStudents.length">
        <p>
          The following <strong>{{ newStudents.length }}</strong> new children will be added to the class.
        </p>
        <div class="overflow-auto max-h-44 px-4 py-3 rounded-br-md bg-grey-l3 shadow-inner mt-2 mb-3">
          <div class="flex flex-wrap -mx-1">
            <span v-for="student in newStudents" :key="student.id" class="rounded px-1 m-1 bg-white">
              {{ student.name }}
            </span>
          </div>
        </div>
      </template>

      <template v-if="withParents.length">
        <p>
          The following <strong>{{ withParents.length }}</strong> children already have connected parents. They will be
          added to the class, but they will not get another invite.
        </p>
        <div class="overflow-auto max-h-44 px-4 py-3 rounded-br-md bg-grey-l3 shadow-inner mt-2 mb-3">
          <div class="flex flex-wrap -mx-1">
            <span v-for="student in withParents" :key="student.id" class="rounded px-1 m-1 bg-white">
              {{ student.name }}
            </span>
          </div>
        </div>
      </template>

      <ul v-if="ready" class="err-list max-h-64 overflow-auto">
        <li v-if="group.groupType === 'YEAR'">
          This class is labelled as a <strong>"year"</strong>, do you have seperate classes within this year to add
          instead?
        </li>
        <li v-else-if="group.groupType !== 'REGISTRATION'">
          This class is labelled as <strong class="lowercase">"{{ group.groupType }}"</strong>, are you sure this is the
          correct class?
        </li>
        <li v-if="students.length > 100">
          This class contains <strong>over a hundred children</strong>, do you have smaller classes you could add
          instead?
        </li>
        <li v-if="!inOtherGroup.length && !newStudents.length">
          No children were found in this class, are you sure you want to add it?
        </li>
        <template v-if="inOtherGroup.length">
          <li>
            The following <strong>{{ inOtherGroup.length }}</strong> children are already in other classes. Having the
            same children in multiple classes is not recommended.
          </li>
          <div class="flex flex-wrap -mx-1 mt-2">
            <span v-for="student in inOtherGroup" :key="student.id" class="rounded px-1 m-1 bg-white-5">
              {{ student.name }}
            </span>
          </div>
        </template>
      </ul>

      <div class="btns4 mt-6">
        <button type="button" class="btn4-clear" @click="showAdd = false">Cancel</button>
        <button type="button" class="btn4 bg-theme" @click="invite">Import Class</button>
      </div>
    </template>

    <!-- <AdminModal :active="showDone" title="Class Import Started" inline @close="showDone = false">
      <p>
        This class is now being imported, this should only take a minute or two.
        <br />
        You are safe to leave this page.
      </p>

      <div class="btns4 mt-6">
        <button type="button" class="btn4 bg-theme" @click="showDone = false">Done</button>
      </div>
    </AdminModal> -->
  </AdminSpinner>
</template>
