<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/organisation.svg';

export default {
  components: { Modal, HaloButton },
  props: ['how'],
  data: () => ({ modal: false }),
};
</script>

<template>
  <div>
    <Modal :open="modal" icon="organisation" title="How to play" @close="modal = false">
      <div v-html="how" class="rdct rdct-modal"></div>
    </Modal>

    <HaloButton icon="organisation" label="How To" @click="modal = true" />
  </div>
</template>
