<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import '@/assets/svgs/buttons/docs.svg';

export default {
  components: { HaloButton },
  props: ['entry'],

  computed: {
    uri() {
      return this.entry?.printDoc?.[0]?.url;
    },
  },
};
</script>

<template>
  <HaloButton v-if="uri" :uri="uri" target="_blank" label="Support Doc" icon="docs" />
</template>
