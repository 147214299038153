<script>
import TrialModal from '@/global/components/TrialModal.vue';
import Link2 from '@/global/components/Link2.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { TrialModal, Link2 },

  computed: {
    ...mapGetters('user', ['isAdmin', 'trialExpRealPe', 'hasRealPeTrial', 'hasRealPeFull']),

    trialDaysRealPe() {
      return this.$dayjs(this.trialExpRealPe).diff(this.$dayjs(), 'day');
    },
  },

  methods: {
    ...mapMutations(['setTrialModal']),
  },
};
</script>

<template>
  <div v-if="!hasRealPeFull && trialExpRealPe">
    <slot />
    <template v-if="hasRealPeTrial">
      <strong>{{ trialDaysRealPe }} days remaining </strong> - {{ trialExpRealPe }}
    </template>
    <div v-else class="font-bold">Your trial has ended</div>
    <div v-if="isAdmin && hasRealPeTrial" class="flex flex-wrap -m-1 mt-4">
      <RouterLink to="/account/purchase" class="db1">Purchase</RouterLink>
      <button class="db1" @click="setTrialModal(2)">End trial</button>
    </div>
  </div>
</template>
