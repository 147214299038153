<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import FormControl from '@/admin/components/FormControl.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import Brand from '@/global/components/Brand.vue';
import Link from '@/global/components/Link2.vue';
import '@/assets/svgs/icons/eh-arrow.svg';
import { mapState, mapMutations } from 'vuex';

const reasons = [
  { title: 'real PE is too ambitious for our teaching team right now' },
  { title: "We've found something else that better suits our needs" },
  { title: 'We found the real PE platform tricky to use' },
  { title: 'I am looking for something more sports specific' },
  { title: "It's too expensive for me right now" },
  { title: 'Something else', showOther: true },
];

export default {
  components: { AdminSpinner, AdminModal, Brand, Link, FormControl },

  data: () => ({
    reasons,
    reason: reasons[0],
    reason2: '',
    ready: true,
    success: false,
    failure: false,
  }),

  computed: {
    title() {
      return this.showTrialModal === 1 ? 'Purchase real PE membership' : 'End real PE trial';
    },
    ...mapState(['showTrialModal']),
  },

  methods: {
    submit() {
      if (!this.ready) return;
      this.ready = false;

      this.$api
        .post('membership/trial-cancel', { reason: this.reason.title, reason2: this.reason2 })
        .then(() => {
          this.success = true;
          this.$store.dispatch('fetchAuth');
          this.ready = true;
        })
        .catch(() => {
          this.failure = true;
          this.ready = true;
        });
    },

    close() {
      this.setTrialModal(0);
      this.failure = false;
      this.ready = true;
      this.success = false;
    },

    ...mapMutations(['setTrialModal']),
  },
};
</script>

<template>
  <AdminModal :active="!!showTrialModal" title="End real PE trial" @close="close">
    <AdminSpinner :ready="ready">
      <template v-if="success">
        <p class="text-xl mb-4">Your <strong>real PE</strong> trial has now ended.</p>
        <p>
          We appreciate you taking the time to experience what
          <strong>real PE</strong> has to offer. Keep checking our website for FREE professional learning and if you'd
          like to restart your trial, please contact us.
        </p>
      </template>

      <template v-else-if="failure"> There was a problem processing your request. </template>

      <form v-else @submit.prevent="submit">
        <FormControl slug="reason" label="Reason">
          <select id="current1" v-model="reason">
            <option v-for="type1 in reasons" :key="type1.title" :value="type1">{{ type1.title }}</option>
          </select>
        </FormControl>

        <FormControl v-if="reason.showOther" slug="reason2" label="Other Reason">
          <input v-model="reason2" type="text" />
        </FormControl>

        <div class="btns4 mt-6">
          <div class="flex-grow"></div>
          <button class="btn4-clear" type="button" @click="close">Cancel</button>
          <button class="btn4 bg-theme" type="submit">End Now</button>
        </div>
      </form>
    </AdminSpinner>
  </AdminModal>
</template>
