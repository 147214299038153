<script>
import WondeEmployees from '@/admin/components/users/WondeEmployees.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import UserList from '@/admin/components/users/UserList.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import UserAdd from '@/admin/components/users/UserAdd.vue';
import MetaTitle from '@/admin/components/MetaTitle.vue';
import Vimeo from '@/global/components/Vimeo2.vue';
import { mapGetters } from 'vuex';

export default {
  components: { UserList, UserAdd, AdminModal, WondeEmployees, AdminSpinner, MetaTitle, Vimeo },
  data: () => ({ showWondeAdd: false, showEmailAdd: false, showSync: false, readySync: true, showVideo: false }),
  metaInfo: { title: 'Users' },
  props: ['account'],

  computed: {
    params() {
      return { accountId: this.account.id };
    },

    stats() {
      return [
        { label: 'Total Logins', value: this.account.totalAccountLogins },
        { label: 'Latest Login', value: this.account.lastAccountLogin },
      ];
    },

    ...mapGetters('user', ['isAdmin', 'isCdAdmin', 'isCdSuper', 'hasRealPeFull']),
  },

  methods: {
    sync() {
      this.readySync = false;
      this.$api
        .post(`wonde/${this.account.wondeId}/employees`)
        .then(res => {
          this.$refs.active.fetch();
          this.$refs.pending.fetch();
          this.$refs.temp.fetch();
          this.showSync = false;
          this.$store.commit('toast/info', res);
        })
        .finally(() => (this.readySync = true));
    },
  },
};
</script>

<template>
  <div class="container">
    <MetaTitle title="Users" :stats="stats" :btns="isAdmin">
      <button v-if="account.hasWonde" class="btn4-clear" @click="showSync = true">Sync Users</button>
      <button class="btn4 bg-gymcard" @click="showVideo = true">
        Users Guide
        <svg class="btn4-icon"><use xlink:href="#video" /></svg>
      </button>

      <template v-if="isCdAdmin || hasRealPeFull || account.hasBursts">
        <button v-if="!account.hasWonde || isCdSuper" class="btn4 bg-pe" @click="showEmailAdd = true">
          Invite {{ isCdSuper ? 'Email' : 'users' }}
          <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
        </button>
        <button v-if="account.hasWonde" class="btn4 bg-pe" @click="showWondeAdd = true">
          Invite {{ isCdSuper ? 'Wonde' : 'users' }}
          <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
        </button>
      </template>
    </MetaTitle>

    <UserList :params="params" :account="account" filter="active" title="active" class="my-8" ref="active" />
    <UserList :params="params" :account="account" filter="pending" title="Invited" class="my-8" ref="pending" hide />
    <template v-if="account.hasWonde">
      <UserList :account="account" :params="params" filter="temp" title="Temporary" ref="temp" hide />
    </template>

    <AdminModal :active="showWondeAdd" wide title="Invite users" @close="showWondeAdd = false">
      <WondeEmployees :account="account" @update="$refs.pending.fetch()" />
    </AdminModal>

    <AdminModal :active="showEmailAdd" title="Invite users" @close="showEmailAdd = false">
      <UserAdd :account="account" kind="account" @update="$refs.pending.fetch()" @close="showEmailAdd = false" />
    </AdminModal>

    <AdminModal :active="showSync" title="Sync users" @close="showSync = false">
      <AdminSpinner :ready="readySync" class="-m-6 p-6">
        <p>This will check for any updated users since the last manual or scheduled sync.</p>
        <div class="btns4 mt-6"><button class="btn4 bg-pe" @click="sync">Sync</button></div>
      </AdminSpinner>
    </AdminModal>

    <AdminModal :active="showVideo" title="Users Guide" wide @close="showVideo = false">
      <Vimeo vid="900759279?h=61ede8e847" class="-m-2 rounded-br-xl" />
    </AdminModal>
  </div>
</template>
