<script>
import routeInfo from '@/mixins/routeInfo.js';
import { zip } from '@/scripts/filters.js';
import { mapState } from 'vuex';

export default {
  props: { ability: { default: () => ({ slug: 'blank' }) } },
  mixins: [routeInfo],

  computed: {
    goals() {
      return zip(this.ability.abilityWhat, this.ability.abilityHow || []);
    },
    ...mapState(['view']),
  },
};
</script>

<template>
  <div>
    <div class="text-center font-extrabold">
      Developing my <span class="lowercase">{{ ability.title }}</span> skills and knowledge so&hellip;
    </div>

    <div class="flex fsc-mx font-bold fsc-sx">
      <div v-if="isPlay" class="fsc-px fsc-lg">{{ ability.strapline }}</div>
      <template v-else>
        <div class="w-1/2 fsc-px"><span class="fsc-lg">What</span></div>
        <div class="w-1/2 fsc-px"><span class="fsc-lg">How</span></div>
      </template>
    </div>

    <!-- <div v-for="goal in goals" class="border-t border-white-3 fsc-sx print:border-grey-l2"> -->
    <div v-for="goal in goals" class="border-t border-grey-l2 fsc-sx print:border-grey-l2">
      <div class="flex fsc-mx">
        <div :class="{ 'w-1/2': !isPlay }" class="fsc-px">
          <span v-if="view && goal.level" class="font-bold capitalize">{{ goal.level }} -</span>
          {{ goal.what }}
        </div>
        <div v-if="!isPlay" class="fsc-px w-1/2">
          {{ goal.how }}
          <div v-for="how in goal.hows">{{ how }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
