import $api from '@/scripts/api.js';
import { status } from '@/scripts/strings.js';
import { djMed, djMedT, $dayjs } from '@/scripts/day';

const zoho = process.env.VUE_APP_ZOHO;

// prettier-ignore
const baseAccount = {
  id: '', name: '', urn: '', accountType: '', status: 0, frozen: 0, wheelLink: '',
  access: {}, accessProducts: [],
  address: {}, region: {}, county: {},
   
  hasRealPe: 0, hasRealPeFull: 0, hasRealPeTrial: 0, 
  manager: '', authority: '', 
    
  totalAccountLogins: 0, totalHomeLogins: 0, totalTraining: 0, totalHome: 0,
  lastAccountLogin: '', lastHomeLogin: '',
  renewalRealPe: '', trialExpRealPe: '',
  totalMinutes: 0, previousMinutes: 0, 
  hasHome: false,
  statusLabel: '', parentSchool: {},

  hasBursts: 0, renewalBursts: '', totalActive: 0, previousActive: 0, levels: [], // bursts 
  zohoAccountId: '', zohoRealId: '', zohoBurstsId:'', // zoho 
  wondeId: '', wondeRealPe: 0, wondeBursts: 0, // wonde 
  childSchools: [],
};

export default {
  namespaced: true,

  state: {
    ...baseAccount,
    $fetched: false,
    $ready: false,
    $self: false,
  },

  mutations: {
    set(state, account = {}) {
      const base = {
        ...baseAccount,
        uri: account.self ? '/account/' : `/accounts/${account.id}/`,
        burstsUri: account.self ? '/bursts/' : `/accounts/${account.id}/bursts/`,
        hasHome: account.accessProducts.includes('home'),
        $renewalRealPe: djMed(account.renewalRealPe),
        $renewalBursts: djMed(account.renewalBursts),
        $trialExpRealPe: djMed(account.trialExpRealPe),
        hasWonde: !!(account.wondeId && (account.wondeRealPe || account.wondeBursts)),
        isPartner: account.accountType === 'partner',
        zohoUrl: account.zohoAccountId ? `${zoho}tab/Accounts/${account.zohoAccountId}` : '',
        zohoRealPeUrl: account.zohoRealId ? `${zoho}tab/CustomModule3/${account.zohoRealId}` : '',
        zohoBurstsUrl: account.zohoBurstsId ? `${zoho}tab/CustomModule11/${account.zohoBurstsId}` : '',
      };

      Object.keys(base).forEach(field => {
        state[field] = account[field] || base[field];
      });

      state.$self = account.self;
      state.$fetched = true;
      state.$ready = true;
    },

    patch(state, account) {
      Object.keys(account).forEach(field => {
        state[field] = account[field];
      });
    },

    clear(state) {
      const base = { ...baseAccount };

      Object.keys(base).forEach(field => {
        state[field] = base[field];
      });

      state.$fetched = false;
      state.$ready = false;
    },
  },

  actions: {
    fetch({ state, commit, rootState }, { id, clear = false } = {}) {
      id = id || rootState.user.accountId;

      if (id == state.id) return Promise.resolve();
      if (clear) commit('clear');

      return $api.get(`accounts/${id}`).then(response => {
        commit('set', { ...response.data, self: rootState.user.accountId });
      });
    },
  },
};
