<script>
import Disc from '@/content/components/partials/Disc.vue';
import routeInfo from '@/mixins/routeInfo.js';
import '@/assets/svgs/icons/repeat.svg';

export default {
  components: { Disc },
  mixins: [routeInfo],
  props: { lesson: Object, pupil: Boolean },

  computed: {
    parts() {
      return ((this.lesson || {}).parts || []).map((part, i) => {
        part = { ...part, arrow: this.pupil && i !== 0 };
        part.uri = `${this.lesson.uri}/${part.type}/${part.slug}`;

        if (part.type === 'skill' && this.isDance && part.skillArea && part.skillArea.length) {
          part.previewImage = part.skillArea[0].previewImage; // dance skill uses skill area preview
        } else if (part.type === 'funs' && this.isPe && part.cards && part.cards.length) {
          part.previewImage = part.cards[0].previewImage; // pe funs preview from card
        } else if (part.type === 'review' && this.lesson.lessonType === 'repeatGame') {
          part.repeat = true; // show repeat icon
        } else if (part.type === 'card' && part.context) {
          // gym card
          const ctx = part.context[0];
          part.title = this.yearNum == 5 ? ctx.title : `${part.parent.title} (${ctx.title})`;
          part.previewImage = this.yearNum == 5 ? ctx.previewImage : part.parent.previewImage;
        }

        return part;
      });
    },

    repeatUrl() {
      return (this.parts.find(p => p.type === 'game') || {}).uri;
    },
  },
};
</script>

<template>
  <div class="relative flex text-center curves bg-white py-5 print:py-0">
    <div v-for="part in parts" :key="part.slug" :class="{ 'preview-border': !pupil }" class="relative w-1/4">
      <RouterLink :to="part.uri" :class="`text-${productSlug}`" class="z-10 block mx-1 hvr">
        <Disc :asset="part.previewImage" :class="{ 'lg:w-40': pupil }" class="mb-2 w-20 outlne print:hidden" />
        <div class="text-lg font-bold hvr0">{{ part.typeLabel }}</div>
        <div class="text-black">{{ part.displayTitle || part.title }}</div>
      </RouterLink>

      <div :class="pupil ? 'top-20' : 'top-1/2'" class="absolute left-0 -m-6">
        <RouterLink v-if="part.repeat" :to="repeatUrl">
          <svg :class="`fill-${productSlug}`" class="w-12 h-12"><use xlink:href="#repeat" /></svg>
          <span class="absolute leading-none top-4 left-0 w-full text-sm">Game</span>
        </RouterLink>
        <svg v-else-if="part.arrow" class="fill-grey-l1 w-12 h-12"><use xlink:href="#arrow" /></svg>
      </div>
    </div>
  </div>
</template>
