<script>
import ReviewModal from '@/content/components/modal/ReviewModal.vue';
import PhysicalModal from '@/content/components/modal/PhysicalModal.vue';
import VimeoText from '@/content/components/partials/VimeoText.vue';
import KeywordsModal from '@/content/components/modal/KeywordsModal.vue';
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import routeInfo from '@/mixins/routeInfo.js';
import '@/assets/svgs/buttons/story.svg';

export default {
  components: { ReviewModal, PhysicalModal, VimeoText, HaloButton, KeywordsModal },
  props: ['lesson', 'ability', 'part'],
  data: () => ({ selected: undefined }),
  mixins: [routeInfo],

  computed: {
    cards() {
      return this.part.cards.filter(card => this.funsLevel.includes(card.funsLevel));
    },

    card() {
      const initial = [0, 0, 0, 1, 0, 1, 0][this.$route.params.year];
      return this.cards[this.selected !== undefined ? this.selected : initial];
    },

    story() {
      const slug = this.part.story && this.part.story[0] ? this.part.story[0].slug : '';
      return slug && this.$route.params.year < 2 ? `${this.lesson.uri}/story/${slug}` : '';
    },

    skill() {
      return { label: this.part.label, skillWhat: this.card.skillWhat, skillHow: this.card.skillHow };
    },
  },
};
</script>

<template>
  <div class="bg-white curves parts-offset">
    <div class="box-contain">
      <div class="box-title">
        <h1>{{ part.title }}</h1>
        <div class="text-2xl font-medium">
          <span class="mr-1 font-bold text-physical">{{ part.category }}</span>
          {{ part.title }}
        </div>
      </div>

      <div class="box-buttons">
        <ReviewModal :ability="ability" :number="2" />
        <PhysicalModal :skill="skill" :text="part.physicalOverwrite" />
      </div>
    </div>

    <VimeoText :key="card.video" :vid="card.video" :colour="`fun-${card.funsLevel}`" :lines="card.textLines" autoplay />

    <div class="flex items-center justify-center my-6 select">
      <button
        v-for="(card, index) in cards"
        :key="card.funsLevel"
        :class="[`bg-fun-${card.funsLevel}`, `focus:border-fun-${card.funsLevel}-shade`]"
        class="w-16 h-16 lg:w-20 lg:h-20 mx-1 rounded-full"
        @click="selected = index"
      >
        <span class="text-xl lg:text-2xl font-extrabold text-white capitalize">{{ card.funsLevel }}</span>
      </button>

      <HaloButton v-if="story" :uri="story" icon="story" label="Story" class="ml-3" />
    </div>

    <div
      v-if="lesson.unit && lesson.unit.skillText"
      v-html="lesson.unit.skillText"
      class="px-8 mb-3 rdct rdct-pupil text-center"
    ></div>
  </div>
</template>
