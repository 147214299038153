<script>
import FormControl from '@/admin/components/FormControl.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import { $group } from '@/scripts/models.js';

export default {
  components: { AdminSpinner, AdminModal, FormControl },
  props: ['value', 'account'],
  data: () => ({ group: $group.one(), showDelete: false }),

  methods: {
    remove() {
      if (this.group.$ready) {
        this.showDelete = false;
        this.group.$delete().then(() => {
          this.$router.replace(this.account.burstsUri);
        });
      }
    },
  },

  mounted() {
    this.group.$load(this.value);
  },

  watch: {
    value(val) {
      this.group = val;
    },
  },
};
</script>

<template>
  <div>
    <AdminSpinner :res="group">
      <form @submit.prevent="submit" class="">
        <div class="flex flex-wrap items-center justify-between">
          <div>
            <h4 class="font-bold">Remove Class</h4>
            <p class="text-sm">This class will be deleted. Children in this class will also be deleted.</p>
          </div>
          <div>
            <button type="button" class="btn4" @click="showDelete = true">Delete</button>
          </div>
        </div>
        <div class="flex justify-between mt-8">
          <div></div>
          <div class="btns4">
            <button class="btn4-clear" type="button" @click="$emit('close')">Cancel</button>
          </div>
        </div>
      </form>
    </AdminSpinner>

    <AdminModal :active="showDelete" inline title="Confirm Delete?" @close="showDelete = false">
      <div class="btns4">
        <button type="button" class="btn4-clear" @click="showDelete = false">Cancel</button>
        <button type="button" class="btn4 bg-fun-red" @click="remove()">Delete Class</button>
      </div>
    </AdminModal>
  </div>
</template>
