<script>
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import Modal from '@/content/components/partials/Modal.vue';
import '@/assets/svgs/buttons/review-cloud.svg';

export default {
  components: { Modal, HaloButton },
  props: { ability: { default: () => ({}) }, number: { default: 1 } },
  data: () => ({ modal: false }),

  computed: {
    question() {
      const index = this.number - 1 || 0;
      return this.ability && this.ability.reviewQuestions ? this.ability.reviewQuestions[index] : '';
    },
    title() {
      return `Review Question ${this.number}`;
    },
  },
};
</script>

<template>
  <div>
    <Modal :open="modal" :title="title" icon="review-cloud" @close="modal = false">
      <div v-html="question" class="rdct rdct-modal whitespace-pre-line"></div>
    </Modal>

    <HaloButton icon="review-cloud" label="Review <br> Question" class="relative text-center" @click="modal = true">
      <div class="absolute inset-0 font-bold leading-none mt-5 text-white lg:text-2xl">{{ number }}</div>
    </HaloButton>
  </div>
</template>
