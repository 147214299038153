<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import TrainingCard from '@/admin/components/TrainingCard.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import FormControl from '@/admin/components/FormControl.vue';
import MetaTitle from '@/admin/components/MetaTitle.vue';
import Vimeo from '@/global/components/Vimeo2.vue';
import { address, num } from '@/scripts/filters.js';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

const licences = [
  { id: 1, title: '1 Year', price: 695 },
  { id: 3, title: '3 Years', price: 1695, save: 390 },
  { id: 5, title: '5 Years', price: 2495, save: 980 },
];

export default {
  components: { AdminSpinner, TrainingCard, AdminModal, Vimeo, MetaTitle, FormControl },
  mixins: [validationMixin],
  props: ['account'],

  data: () => ({
    ready: true,
    success: false,
    failure: false,
    dropSchool: null,

    membership: 1,
    poNumber: '',
    schools: [],
    finance: { firstName: '', lastName: '', email: '' },
  }),

  computed: {
    isMat() {
      return this.account && this.account.childSchools?.length;
    },

    schools0() {
      return this.account.childSchools.map(school => ({ ...school, address: address(school), email: '' }));
    },

    selectable() {
      const selected = this.schools.map(school => school.id);
      return this.schools0.filter(school => !selected.includes(school.id));
    },

    matError() {
      return this.isMat && this.$v.$dirty && !this.schools.length;
    },

    licences() {
      const count = this.schools.filter(school => !school.hasRealPeFull).length || 1;

      return licences.map(licence => ({
        ...licence,
        price: `£${num(licence.price * count)}`,
        save: licence.save && `£${num(licence.save * count)}`,
      }));
    },

    payload() {
      return {
        childAccounts: this.schools.map(school => ({ id: school.id, email: school.email })),
        accountId: this.account.id,
        length: this.membership,
        finance: this.finance,
        poNumber: this.poNumber,
      };
    },
  },

  validations() {
    return {
      schools: {
        $each: { email: { required, email } },
      },

      finance: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
      },
    };
  },

  methods: {
    addSchool() {
      this.schools.push(this.dropSchool);
      this.autoSelect();
    },
    removeSchool(id) {
      this.schools = this.schools.filter(school => school.id !== id);
      this.autoSelect();
    },
    autoSelect() {
      if (this.selectable.length) this.dropSchool = this.selectable[0];
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.ready = false;

      this.$api
        .post('membership/realpe-purchase', this.payload)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.failure = true;
        })
        .finally(() => {
          this.ready = true;
        });
    },
  },

  mounted() {
    // TODO: redirect if school has real pe (and not mat)
    this.autoSelect();
  },
};
</script>

<template>
  <div class="container">
    <h2 class="text-4xl mb-6 font-semibold text-pe">Purchase real PE membership</h2>

    <AdminSpinner :ready="ready">
      <div v-if="success" class="prose bg-white mx-auto p-6 rounded-xl">
        <h3>Thank you for your order.</h3>
        <p>
          Our team will process your order as quickly as possible, and you will be emailed confirmation and instructions
          when this has been completed. Please note that our core hours are Monday-Friday 9am-5pm; orders received
          outside of these hours will be processed on the next working day.
        </p>
        <p>
          If you have any queries in the meantime, please use our Live Chat, email
          <a href="mailto:info@realPE.co.uk">info@realPE.co.uk</a> or call
          <a href="tel:0208 863 0304">0208 863 0304</a>.
        </p>
        <p>We're looking forward to supporting you to make a proven impact for EVERY child.</p>
      </div>

      <template v-else-if="success === 2">
        <p class="text-xl mb-4">Your <strong>real PE</strong> trial has now ended.</p>
        <p>
          We appreciate you taking the time to experience what
          <strong>real PE</strong> has to offer. Keep checking our website for FREE professional learning and if you'd
          like to restart your trial, please contact us.
        </p>
      </template>

      <template v-else-if="failure"> There was a problem processing your request. </template>

      <form v-else class="flex -mx-2 justify-center" @submit.prevent="submit">
        <div v-if="isMat" class="w-1/2 px-2">
          <div class="bg-white p-6 rounded-xl">
            <div class="flex items-end">
              <FormControl slug="schools" label="Member School" class="grow mr-4">
                <select id="schools" :disabled="!selectable.length" v-model="dropSchool">
                  <option v-for="school in selectable" :key="school.id" :value="school">
                    {{ school.name }}
                  </option>
                </select>
              </FormControl>

              <div class="pb-3">
                <button :disabled="!selectable.length" class="btn4 h-11 w-28" type="button" @click="addSchool">
                  Add School
                </button>
              </div>
            </div>

            <div v-for="(v, i) in $v.schools.$each.$iter" class="pt-6 mt-4 border-t border-grey-l2">
              <div class="flex items-center mb-3">
                <div class="grow">
                  <div class="text-xl font-semibold">{{ v.$model.name }}</div>
                  <div>{{ v.$model.address }}</div>
                </div>
                <button class="db1 m-0 ml-4 h-10 w-10" type="button" @click="removeSchool(v.$model.id)">
                  <svg class="w-3 h-3 mx-auto fill-current"><use xlink:href="#cross" /></svg>
                </button>
              </div>

              <div v-if="v.$model.hasRealPeFull" class="note note-warn mt-3">
                <svg class="note-icon"><use xlink:href="#warn" /></svg>
                <p class="">
                  This school already has a real PE membership. <br />It will not be included as part of this purchase.
                </p>
              </div>
              <template v-else>
                <FormControl :v="v" slug="email" label="PE Lead Email" class="">
                  <input type="text" :id="`schoolEmail${i}`" v-model.trim="v.email.$model" />
                </FormControl>
              </template>
            </div>

            <div
              v-if="!schools.length"
              :class="matError ? 'border-fun-red border-2' : 'border-grey'"
              class="empty border border-dashed py-6 mt-4"
            >
              <strong>No schools are currently selected.</strong> <br />Use the drop-down above to add schools
            </div>
          </div>
        </div>

        <div class="w-1/2 px-2">
          <div class="bg-white p-6 rounded-xl">
            <FormControl slug="membership" label="Membership length">
              <div v-for="licence in licences" :key="licence.title" class="">
                <label
                  :class="{ 'bg-pe-shade': membership === licence.id }"
                  class="flex items-center mb-3 outlne rounded px-6 py-4 border border-grey-l1 cursor-pointer"
                >
                  <div class="font-medium text-lg w-18">{{ licence.title }}</div>
                  <div class="text-pe text-xl font-medium w-18">{{ licence.price }}</div>
                  <div v-if="licence.save">saving {{ licence.save }}</div>
                  <input type="radio" v-model="membership" :value="licence.id" class="ml-auto" />
                </label>
              </div>
            </FormControl>

            <hr />

            <h2 class="text-2xl text-pe font-medium mb-3">Finance Contact</h2>

            <div class="flex -mx-3">
              <div class="px-3 w-1/2">
                <FormControl :v="$v.finance" slug="firstName" label="First Name" class="">
                  <input type="text" id="firstName" v-model.trim="$v.finance.firstName.$model" />
                </FormControl>
              </div>
              <div class="px-3 w-1/2">
                <FormControl :v="$v.finance" slug="lastName" label="Last Name" class="">
                  <input type="text" id="lastName" v-model.trim="$v.finance.lastName.$model" />
                </FormControl>
              </div>
            </div>

            <FormControl :v="$v.finance" slug="email" label="Email" class="">
              <input type="text" id="email" v-model.trim="$v.finance.email.$model" />
            </FormControl>

            <FormControl slug="poNumber" label="PO Number (optional)" class="">
              <input type="text" id="poNumber" v-model.trim="poNumber" />
            </FormControl>

            <hr />

            <div class="note note-info mt-6">
              <svg class="note-icon"><use xlink:href="#info" /></svg>
              <p class="">
                Clicking 'Purchase now' will complete the purchase of your membership and trigger an invoice which will
                be emailed to your finance contact.
              </p>
            </div>

            <div class="btns4 mt-6">
              <div class="flex-grow"></div>
              <!-- <button class="btn4-clear" type="button" @click="close">Cancel</b  utton> -->
              <button class="btn4 bg-theme" type="submit">Purchase Now</button>
            </div>
          </div>
        </div>
      </form>
    </AdminSpinner>
  </div>
</template>
