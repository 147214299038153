<script>
import SupportPage from '@/admin/views/SupportPage.vue';
import NavLink from '@/global/components/NavLink.vue';
import query from '@/queries/support.gql';
import { mapGetters } from 'vuex';

export default {
  components: { SupportPage, NavLink },
  data: () => ({ imgs: ['monkey', 'space', 'magic'], page: {} }),

  metaInfo() {
    return { title: ['Support', this.node?.title, this.page.title].filter(x => x).join(' / ') };
  },

  computed: {
    slug1() {
      return this.$route.params.slug1;
    },
    node() {
      if (this.slug1) return this.groups.find(({ slug }) => slug === this.slug1) || {};
    },

    groups() {
      return this.support.map(group => {
        const { colour } = this.$products(group.product) || { colour: 'pe' };
        return { ...group, colour, subnav: group.children?.length > 1 };
      });
    },

    ...mapGetters(['support']),
    ...mapGetters('user', ['isHome']),
  },

  methods: {
    fetch() {
      if (!this.$route.params.slug1) return;

      this.page = {};
      this.$gql(query, { uri: this.$route.path }, { required: ['entry'] }).then(res => {
        this.page = res.page;
      });
    },
  },

  created() {
    this.fetch();
  },

  watch: { $route: 'fetch' },
};
</script>

<template>
  <div class="container mt-6">
    <div class="flex -mx-3">
      <div v-if="!isHome" class="px-3 shrink-0">
        <div class="bg-white inline-block curves-sm overflow-hidden w-48 p-1">
          <div v-for="group in groups" :key="group.id" class="border-xb border-grey-l3">
            <NavLink :uri="group.uri" :colour="group.colour" class="flex justify-between items-center tixght mb-1">
              {{ group.title }}
              <svg v-if="group.subnav" class="w-3 h-3 inline fill-grey">
                <use xlink:href="#ring-add" />
              </svg>
            </NavLink>

            <div v-if="group.subnav && slug1 === group.slug" class="pb-1">
              <RouterLink
                v-for="child in group.children"
                :key="child.id"
                :to="child.uri"
                :class="`hover:text-${group.colour}`"
                class="block pl-5 py-1 text-sm"
                active-class="font-bold"
                exact
              >
                {{ child.title }}
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 flex-grow">
        <SupportPage v-if="slug1" :parent="node" :page="page" />

        <div v-else>
          <div class="p-2 px-5 mb-2 bg-white curves-sm flex items-center">
            <span class="text-3xl font-bold lg:text-4xl" :class="`text-pe`">Support</span>
          </div>
          <div class="my-4 curves-sm bg-white p-3 px-5 py-6 text-xl">
            <h2 class="text-2xl mb-5 font-semibold">Welcome to the Support area!</h2>

            <p class="mb-5">
              Simply select from the folders on the left to view documents, videos and resources to support you to
              deliver an outstanding PE curriculum.
            </p>

            <!-- <p>
              Have a question? Get in touch via
              <a href="mailto:info@realpe.co.uk" class="undr">info@realpe.co.uk</a>
              or call us on
              <a href="tel:020 8863 0304" class="undr">020 8863 0304</a>
              <span>.</span>
            </p> -->
          </div>

          <div class="flex justify-center mt-16">
            <img
              v-for="img in imgs"
              :key="img"
              :src="`/images/story-${img}.svg`"
              class="curves-sm w-40 mx-6 bg-white"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-legacy"></div>
  </div>
</template>
