<script>
import FormControl from '@/admin/components/FormControl.vue';
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

const types = [
  'text/csv',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const fileSize = file => (file.size ? file.size < 50000 : true);
const fileType = file => (file.size ? types.includes(file.type) : true);

export default {
  mixins: [validationMixin],
  components: { FormControl, AdminSpinner },
  data: () => ({ file: {}, ready: true }),
  props: ['account'],

  validations: {
    file: { required, fileSize, fileType },
  },

  methods: {
    select(event) {
      this.file = event.target.files[0] || {};
      this.$v.$touch();
    },

    submit() {
      this.$v.$touch();

      if (this.ready && !this.$v.$invalid) {
        this.ready = false;

        let formData = new FormData();
        formData.append('file', this.file);

        this.$api
          .post('users/home/upload', formData, { params: { accountId: this.account.id } })
          .then(res => {
            this.ready = true;
            this.$emit('update', res.data);
            this.close();
          })
          .catch(error => {
            this.ready = true;
            console.error(error);
          });
      }
    },

    close() {
      this.$v.$reset();
      this.$emit('close');
      this.file = {};
    },
  },
};
</script>

<template>
  <AdminSpinner :ready="ready">
    <form @submit.prevent="submit" class="relative">
      <p>
        Upload children using a CSV or Excel file.
        <br />
        Please use the following format:
      </p>

      <div class="my-3 bg-grey-l3 inline-block py-1 px-3 rounded">
        <code>First Name, Last Name, Group</code>
      </div>

      <p class="mb-3">First and Last Names are required, Group is optional.</p>

      <FormControl :v="$v" slug="file" label="Childrens Import File">
        <div class="faux-file-contain relative cursor-pointer">
          <input @change="select" type="file" id="file" class="absolute inset-0 opacity-0 cursor-pointer" />
          <label class="faux-file" for="file">{{ file.name }}</label>
        </div>

        <template v-slot:errors>
          <div v-if="$v.file.fileType === false">Please choose a correct file type (csv, txt, xlsx)</div>
          <div v-else-if="$v.file.fileSize === false">The file you are trying to upload is too large</div>
        </template>
      </FormControl>

      <div class="btns4 mt-6">
        <a href="/templates/childrenUploadTemplate.xlsx" download class="btn4">
          Excel Template
          <svg class="btn4-icon"><use xlink:href="#export" /></svg>
        </a>
        <div class="flex-grow"></div>
        <button @click="close" type="button" class="btn4-clear">Cancel</button>
        <button class="btn4 bg-pe" type="submit">Import Children</button>
      </div>
    </form>
  </AdminSpinner>
</template>

<style lang="postcss" scoped>
.faux-file {
  @apply w-full px-3 py-3 rounded-tl-lg rounded-br-lg bg-grey-l3 block;
  height: 46px;
  box-shadow: inset 0 0 10px rgba(191, 191, 191, 0.7);
}
input[type='file']:focus + .faux-file {
  outline: none;
  box-shadow: inset 0 0 10px rgba(191, 191, 191, 0.7), 0 0 0 2px rgba(229, 0, 125, 0.4);
}
.has-error .faux-file {
  box-shadow: inset 0 0 10px rgba(191, 191, 191, 0.7), 0 0 0 2px rgba(255, 0, 0, 0.6);
}
</style>
