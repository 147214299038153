<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import Pagination from '@/admin/components/Pagination.vue';
import AtbHead from '@/admin/components/AtbHead.vue';
import { $student } from '@/scripts/models.js';
import sortie from '@/mixins/sortie.js';
import '@/assets/svgs/bursts/clock.svg';
import '@/assets/svgs/bursts/bolt2.svg';
import '@/assets/svgs/bursts/ribbon.svg';

export default {
  components: { AdminSpinner, Pagination, AtbHead },
  props: ['label', 'groupId', 'status', 'uri'],
  data: () => ({ students$: $student.all(), students: [] }),
  mixins: [sortie('name', 'fetch')],

  computed: {
    students2() {
      console.log(this.uri);
      return this.students.map(s => ({ ...s, uri: this.uri + s.id }));
    },
  },

  methods: {
    async fetch(params = {}) {
      this.students = await this.students$.$get({
        page: params.page || 1,
        groupId: this.groupId,
        status: this.status,
        sort: this.$sortParam,
        perPage: 16,
      });
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :res="students$" hide>
    <div class="flex items-center mb-2">
      <h2 class="head-2xl capitalize text-theme">{{ label }} Accounts</h2>
      <span class="title-meta">{{ students$.totalItems }}</span>
    </div>

    <div v-if="students$.empty" class="empty">There are no {{ label }} children in this class</div>

    <div v-else class="atb-wrap mt-5">
      <div class="atb-body">
        <div class="atb-row atb-hrow">
          <AtbHead class="grow" slug="name" asc v-model="srt">Name</AtbHead>
          <AtbHead class="w-28 flex justify-center items-center" slug="totalSeconds" v-model="srt">
            <svg class="w-4 h-4 fill-grey mr-2 shrink-0"><use xlink:href="#clock" /></svg>
            Active <br />minutes
          </AtbHead>
          <AtbHead class="w-28 flex justify-center items-center" slug="adventureTotal" v-model="srt">
            <svg class="w-4 h-5 fill-grey mr-2 shrink-0"><use xlink:href="#bolt2" /></svg>
            FMS <br />progress
          </AtbHead>
          <AtbHead class="w-28 flex justify-center items-center" slug="level" v-model="srt">
            <svg class="w-4 h-5 fill-grey mr-2 shrink-0"><use xlink:href="#ribbon" /></svg>
            XP <br />level
          </AtbHead>
        </div>

        <RouterLink v-for="student in students2" :key="student.id" :to="student.uri" class="atb-row atb-link">
          <div class="grow atb-col atb-min1">{{ student.name }}</div>
          <div class="w-28 atb-col text-center">
            {{ student.totalMinutes }}
          </div>
          <div class="w-28 atb-col text-center">
            {{ student.adventureTotal }}
          </div>
          <div class="w-28 atb-col text-center">
            {{ student.level }}
          </div>
        </RouterLink>

        <Pagination :collection="students$" @change="fetch" class="mx-1 mt-2 -mb-2" />
      </div>
    </div>
  </AdminSpinner>
</template>
