<script>
import LessonPreview from '@/content/components/previews/LessonPreview.vue';
import PrintButton from '@/content/components/buttons/PrintButton.vue';
import UnitAbility from '@/content/components/lesson/UnitAbility.vue';
import HaloButton from '@/content/components/buttons/HaloButton.vue';
import PageTitle from '@/content/components/partials/PageTitle.vue';
import DocButton from '@/content/components/buttons/DocButton.vue';
import UnitSkill from '@/content/components/lesson/UnitSkill.vue';
import routeInfo from '@/mixins/routeInfo.js';
import { activityTypes, peActivityOverwrite } from '@/scripts/strings.js';
import { mapGetters } from 'vuex';
import '@/assets/svgs/buttons/organiser.svg';
import '@/assets/svgs/icons/arrow.svg';

const col2Titles = { gym: 'Gym Skills', dance: 'Dance Focus' };

export default {
  components: { LessonPreview, PageTitle, PrintButton, UnitAbility, UnitSkill, HaloButton, DocButton },
  props: ['unit', 'ability', 'uri'],
  mixins: [routeInfo],

  computed: {
    title() {
      return this.crumbsTitle.slice(this.isPlay ? 2 : 1).join(', ');
    },

    col2Title() {
      return col2Titles[this.productSlug] || 'Fundamental Movement Skills';
    },

    lessons() {
      return this.unit.lessons.map((lesson, i) => ({
        ...lesson,
        title: this.productInfo.lesson[i].title,
        parts: activityTypes.map(({ slug, label }, i) => {
          const activity = this.isPlay ? lesson.parts[i].part[0] : lesson[slug]?.[0];
          return { ...activity, typeLabel: (peActivityOverwrite[lesson.lessonType] || {})[i] || label };
        }),
      }));
    },

    ...mapGetters('menu', ['crumbsTitle']),
  },
};
</script>

<template>
  <div class="container">
    <PageTitle :title="title" subtitle="Medium Term Plan">
      <DocButton :entry="unit" />
      <HaloButton v-if="!isPlay" :uri="`${uri}/organiser`" icon="organiser" label="Knowledge<br>Organiser" />
      <PrintButton />
    </PageTitle>

    <div class="px-2 mb-2 bg-white curves">
      <div class="py-3 text-3xl font-bold text-center">Learning Goals</div>

      <div class="flex mb-2 text-lg font-semibold text-center">
        <h2 class="w-1/2">Learning Behaviour</h2>
        <h2 class="w-1/2">{{ col2Title }}</h2>
      </div>

      <div class="t-row">
        <UnitAbility :unit="unit" :ability="ability" />
        <UnitSkill :unit="unit" />
      </div>
    </div>

    <div v-for="lesson in lessons" :key="lesson.slug" class="mb-5 bg-white rounded-r-3xl lesson">
      <RouterLink
        :to="lesson.uri"
        :class="`bg-${productSlug} print:text-black`"
        class="flex items-center p-3 pl-6 text-white colour-3s rounded-r-3xl hover:bg-pe"
      >
        <span class="mr-10 text-3xl font-bold">{{ lesson.title }}</span>
        <svg class="w-12 h-12 ml-auto fill-current print:hidden"><use xlink:href="#arrow" /></svg>
      </RouterLink>

      <LessonPreview :lesson="lesson" />
    </div>
  </div>
</template>
