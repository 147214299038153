/* eslint-disable no-param-reassign */
import Vue from 'vue';
import $api from '@/scripts/api.js';
import { abilityStrings } from '@/scripts/strings.js';
import { djMed, djMedT, $dayjs } from '@/scripts/day';

const baseUser = {
  id: null,
  uid: '',
  accountId: '',
  kind: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  abilityChoices: { dance: {}, gym: {} },
  lastVisited: {},
  showJukebox: false,
  leadPe: 0,
  leadBursts: 0,
  wondeId: '',
  status: 0,
  username: '',
  password: '',
  admin: 0,
  groupIds: [],
  roles: [],
  ruPaths: [],
  dateEndTemp: null,
};

const baseAccount = {
  accountType: '',
  access: {},
  wheelLink: '',
  hasRealPe: 0,
  hasBursts: 0,
  hasRealPeFull: 0,
  hasRealPeTrial: 0,
  wondeRealPe: 0,
  wondeBursts: 0,
  renewalRealPe: '',
  trialExpRealPe: '',
  totalTraining: 0,
  realLeads: [],
};

export default {
  namespaced: true,
  state: { ...baseUser, ...baseAccount, showTempModal: false },

  mutations: {
    set(state, user) {
      const base = { ...baseUser, ...baseAccount };

      Object.keys(baseUser).forEach(field => {
        state[field] = user[field] || base[field];
      });

      Object.keys(baseAccount).forEach(field => {
        state[field] = user.account?.[field] || base[field];
      });

      if (localStorage.getItem('abilityChoices') !== null) {
        state.abilityChoices = JSON.parse(localStorage.abilityChoices);
      }

      if (user.dateEndTemp) {
        state.showTempModal = true;
      }
    },

    patch(state, user) {
      Object.keys(user).forEach(field => {
        state[field] = user[field];
      });
    },

    clear(state) {
      const base = { ...baseUser };

      Object.keys(base).forEach(field => {
        state[field] = base[field];
      });
    },

    abilitySelect(state, choices) {
      Vue.set(state, 'abilityChoices', choices);
    },

    toggleJukebox(state) {
      state.showJukebox = !state.showJukebox;
    },

    setTempModal(state, val) {
      state.showTempModal = val;
    },
  },

  getters: {
    userId: state => state.id,
    userUri: state => `users/${state.kind}/${state.id}`,
    isHome: state => state.kind === 'home',
    isCdAdmin: state => state.kind === 'create' && state.admin,
    isCdSuper: state => state.kind === 'create' && state.admin === 2,
    isCreate: state => state.kind === 'create',
    isAdmin: state => state.admin,
    isPartner: state => state.accountType === 'partner',
    isRealLead: state => state.leadPe === 1,
    kind: state => state.kind,
    groupIds: state => state.groupIds,
    debug: state => ['UC3'].includes(state.uid),

    // from account
    wheelUrl: state => state.wheelLink,
    wondeBursts: state => !!state.wondeBursts,
    wondeRealPe: state => !!state.wondeRealPe,
    renewalRealPe: state => djMed(state.renewalRealPe),
    trialExpRealPe: state => djMed(state.trialExpRealPe),
    hasTraining: state => !!state.totalTraining,
    hasHome: state => state.access.home && state.access.home.length,

    hasRealPe: (state, { isCreate }) => isCreate || state.hasRealPe,
    hasBursts: (state, { isCreate }) => isCreate || state.hasBursts,
    hasRealPeFull: (state, { isCreate }) => !isCreate && state.hasRealPeFull,
    hasRealPeTrial: (state, { isCreate }) => !isCreate && state.hasRealPeTrial,

    hasAccess({ access }) {
      return slug => access[slug] || [];
    },
    hasProduct(state, { isCreate, hasAccess }) {
      return slug => isCreate || hasAccess(slug).length;
    },
    hasSection(state, { hasAccess, isCreate }) {
      return (slug, section) => isCreate || hasAccess(slug).includes(section);
    },

    chosenAbilitySlug(state, getters, { route }) {
      return (slug, product = null) => {
        const product2 = product || route.params.product;
        return state.abilityChoices[product2 || {}][(slug || '').substring(0, 5)];
      };
    },

    chosenAbility(state, getters) {
      return ({ ability, abilities, slug, id }) => {
        if (!id) return {};
        const abilitySlug = ability || getters.chosenAbilitySlug(slug);

        return {
          title: abilityStrings[abilitySlug] || '',
          slug: abilitySlug || 'blank',
          ...(abilities && abilities.find(ability => ability.slug === abilitySlug)),
        };
      };
    },
  },

  actions: {
    abilitySelect({ commit, state, rootState }, { unit, ability }) {
      const choices = { ...state.abilityChoices };
      choices[rootState.route.params.product][unit] = ability;

      commit('abilitySelect', choices);
      localStorage.abilityChoices = JSON.stringify(choices);
    },

    last({ state, rootGetters, rootState }, { title, type }) {
      if (state.kind !== 'home') {
        const last = {
          url: rootState.route.path,
          title,
          subtitle: rootGetters['menu/crumbs'].reduce((carry, crumb, index) => {
            return type !== crumb.type ? `${carry}${index ? ' - ' : ''}${crumb.title}` : carry;
          }, ''),
          product: rootState.route.params.product || '',
        };

        $api.post('/bookmarks/last', last).then(res => (state.lastVisited = res.data));
      }
    },

    getLast({ state }) {
      $api.get('/bookmarks/last').then(res => (state.lastVisited = res.data));
    },
  },
};
