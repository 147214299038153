<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import WondeGroups from '@/admin/components/users/WondeGroups.vue';
import Pagination from '@/admin/components/Pagination.vue';
import AdminModal from '@/admin/components/AdminModal.vue';
import ActiveMins from '@/bursts/components/ActiveMins.vue';
import ActiveUsers from '@/bursts/components/ActiveUsers.vue';
import Ribbons from '@/bursts/components/Ribbons.vue';
import Vimeo from '@/global/components/Vimeo2.vue';
import { $group } from '@/scripts/models.js';
import { mapGetters } from 'vuex';

export default {
  components: { AdminSpinner, AdminModal, WondeGroups, Ribbons, ActiveMins, ActiveUsers, Pagination, Vimeo },
  data: () => ({ groups$: $group.all(), groups: [], showEdit: false, showVideo: false, showVideo2: false }),
  props: ['account'],

  computed: {
    canManage() {
      return (this.kind === 'create' && this.isCdSuper) || (this.kind === 'account' && this.isAdmin);
    },
    mapped() {
      return this.groups.map(group => ({
        ...group,
        active: this.canManage || this.groupIds.includes(group.id),
        uri: `${this.account.burstsUri}groups/${group.id}`,
      }));
    },

    ...mapGetters('user', ['isCdSuper', 'isAdmin', 'groupIds', 'kind']),
  },

  methods: {
    nav(link) {
      this.fetch(link.page);
    },

    async fetch(page) {
      this.groups = await this.groups$.$get({ page, accountId: this.account.id });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <div v-if="!account.hasBursts" class="empty">
      <div class="text-2xl">This account does not have access to BURSTS</div>
    </div>
    <div v-else-if="!account.wondeBursts" class="empty">
      <div class="text-2xl">This account has not approved BURSTS on Wonde</div>
      <div v-if="account.wondeMessage" class="mt-4">"{{ account.wondeMessage }}"</div>
    </div>

    <template v-else>
      <Ribbons :record="account" />
      <hr />

      <AdminSpinner :res="groups$" hide>
        <div class="flex items-center justify-between mb-6">
          <div class="head-4xl text-bursts">BURSTS Classes</div>
          <div v-if="canManage" class="btns4">
            <button class="btn4 bg-gymcard" @click="showVideo = true">
              Getting Started
              <!-- Guide -->
              <svg class="btn4-icon"><use xlink:href="#video" /></svg>
            </button>
            <button class="btn4 bg-gymcard" @click="showVideo2 = true">
              Celebration Guide
              <svg class="btn4-icon"><use xlink:href="#video" /></svg>
            </button>

            <button class="mx-1 btn4 bg-theme" @click="showEdit = true">
              Add Classes
              <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
            </button>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2">
          <div v-for="group in mapped" :key="group.id" class="w-1/3 px-2 my-2">
            <component
              :is="group.active ? 'RouterLink' : 'div'"
              :to="group.uri"
              class="card text-bursts"
              :class="{ outlne: group.active }"
            >
              <div class="font-semibold text-2xl mb-2">{{ group.name }}</div>

              <div class="flex items-center leading-none text-xl -mx-2">
                <ActiveUsers :record="group" class="px-2" />
                <ActiveMins :record="group" class="px-2" />
              </div>
            </component>
          </div>
        </div>

        <div v-if="groups$.empty" class="empty">No classes have been added</div>
        <Pagination :collection="groups$" @change="nav" class="mt-3" />
      </AdminSpinner>

      <AdminModal :active="showEdit" title="Import Classes" wide @close="showEdit = false">
        <WondeGroups :account="account" @update="fetch()" />
      </AdminModal>

      <AdminModal :active="showVideo" title="Getting Started and Adding Classes" wide @close="showVideo = false">
        <Vimeo vid="900850130?h=42e46a13cb" class="-m-2 rounded-br-xl" />
      </AdminModal>

      <AdminModal :active="showVideo2" title="Support and Celebration" wide @close="showVideo2 = false">
        <Vimeo vid="781864410?h=497cc2cb19" class="-m-2 rounded-br-xl" />
      </AdminModal>
    </template>
  </div>
</template>
