<script>
import AdminSpinner from '@/admin/components/AdminSpinner.vue';
import ProductIntro from '@/admin/views/ProductIntro.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  components: { ProductIntro, AdminSpinner },
  metaInfo: { titleTemplate: t => (t ? `${t} | BURSTS` : 'BURSTS') },

  computed: {
    ...mapGetters('user', ['isAdmin', 'isCreate', 'wondeBursts', 'hasBursts']),
    ...mapState(['account']),
  },

  methods: {
    checkUrl() {
      if (this.$route.path !== '/bursts') return;
      if (this.isCreate) this.$router.replace('/bursts/stats/year');
      else if (this.hasBursts && this.wondeBursts) this.$router.replace('/bursts/groups');
    },
  },

  watch: { $route: 'checkUrl' },

  mounted() {
    this.checkUrl();
    if (!this.isCreate) this.$store.dispatch('account/fetch');
  },
};
</script>

<template>
  <AdminSpinner :res="account" class="loader-lg" hide>
    <template v-if="wondeBursts && hasBursts">
      <div class="container">
        <div class="flex justify-between items-center my-8">
          <img src="/brands/bursts.svg" alt="BURSTS" class="h-16 -my-3 -ml-1" />

          <div v-if="isAdmin" class="items-center justify-center px-2 text-center bg-white rounded flex">
            <RouterLink to="/bursts/groups" class="ma-link2">BURSTS Classes</RouterLink>
            <RouterLink to="/bursts/year" class="ma-link2">BURSTS Data</RouterLink>
            <RouterLink to="/support/bursts" class="ma-link2">Support</RouterLink>
          </div>
        </div>
      </div>

      <RouterView :account="account" />
    </template>

    <ProductIntro v-else-if="hasBursts" slug="wonde-guide" />
    <ProductIntro v-else slug="intro-bursts" />
  </AdminSpinner>
</template>
