<script>
import FormControl from '@/admin/components/FormControl.vue';
import FormTypeSelect from '@/admin/components/FormTypeSelect.vue';
import MultiCheck from '@/admin/components/MultiCheck.vue';
import Datepicker from 'vuejs-datepicker';
import '@/assets/svgs/icons/triangle.svg';

const base = {
  search: '',
  county: '',
  authority: '',
  manager: {},
  accountType: [],
  status: [],
  licences: [],
  frame: [],
  wondeRealPeStatus: [],
  wondeBurstsStatus: [],
};

const peLicences = [
  { label: 'Legacy', value: 'legacy' },
  { label: 'Next', value: 'next' },
  { label: 'Essentials', value: 'essentials' },
  { label: 'Legacy Memb.', value: 'legacy-membership' },
  { label: 'Membership', value: 'member' },
  { label: 'Gateway', value: 'gateway' },
  { label: 'Other', value: 'other' },
  { label: 'Trial', value: 150 },
  // { label: 'WS', value: 'ws' },
  // { label: 'CLC', value: 116 },
  // { label: 'Other', value: 117 },
  // { label: 'Archive', value: 119 },
  // { label: 'Partner', value: 118 },
];

const brLicences = [
  { label: 'Member', value: 200 },
  { label: 'Non Member', value: 201 },
  { label: 'Project', value: 202 },
];
const accountStatus = [
  { label: 'Active', value: 30 },
  { label: 'Inactive', value: 10 },
  { label: 'Prospect', value: 0 },
  { label: 'Frozen', value: 'f' },
];
const accountTypes = [
  { label: 'Education', value: 'education' },
  { label: 'Partner', value: 'partner' },
  { label: 'Organisation', value: 'organisation' },
  { label: 'Individual', value: 'individual' },
];
const frames = [
  { label: 'Current', value: 'now' },
  { label: 'Future', value: 'future' },
  { label: 'Expired', value: 'past' },
];
const hasWonde = [
  { label: 'Approved', value: 'approved' },
  { label: 'Pending', value: 'pending' },
  { label: 'Declined', value: 'declined' },
  { label: 'Revoked', value: 'revoked' },
  // { label: 'None', value: 'none' },
];

export default {
  components: { FormTypeSelect, FormControl, Datepicker, MultiCheck },
  props: ['view'],

  // prettier-ignore
  data: () => ({
    ...base, ready: false,
    regions: [], counties: [], authorities: [],
    peLicences, brLicences, accountTypes, accountStatus, frames, hasWonde,
  }),

  computed: {
    query() {
      return {
        ...(this.search && { search: this.search }),

        ...(this.status.length && { status: this.status }),
        ...(this.accountType.length && { accountType: this.accountType }),
        ...(this.licences.length && { licences: this.licences }),
        ...(this.frame.length && { frame: this.frame }),
        ...(this.wondeRealPeStatus.length && { wondeRealPeStatus: this.wondeRealPeStatus }),
        ...(this.wondeBurstsStatus.length && { wondeBurstsStatus: this.wondeBurstsStatus }),

        ...(this.manager.type === 'region' && { regionId: this.manager.id }),
        ...(this.manager.type === 'contact' && { managerId: this.manager.id }),
        ...(this.county && { countyId: this.county.id }),
        ...(this.authority && { authorityId: this.authority.id }),
      };
    },

    managers() {
      return this.regions.reduce((carry, regionData) => {
        let { managers, ...region } = regionData;
        region.key = `r${region.id}`;
        managers = managers.map(manager => ({ ...manager, key: `m${manager.id}` }));
        return carry.concat([{ name: '---', disabled: true }, region], managers);
      }, []);
    },
  },

  methods: {
    emit() {
      this.$emit('filter', this.query);
    },

    reset() {
      Object.keys(base).forEach(key => {
        this[key] = base[key];
      });

      this.emit();
    },
  },

  watch: {
    query(query) {
      if (this.ready) localStorage.setItem('accountFilter', JSON.stringify(query));
    },
  },

  created() {
    const saved = JSON.parse(localStorage.getItem('accountFilter')) || {};
    Object.keys(saved).forEach(key => {
      this[key] = saved[key];
    });

    localStorage.removeItem('schoolsFilter');
    localStorage.removeItem('partnersFilter');

    this.$api.get('accounts/filters').then(res => {
      this.regions = res.data.regions;
      this.counties = res.data.counties;
      this.authorities = res.data.authorities;

      const managerId = saved.regionId || saved.managerId;
      if (managerId) this.manager = this.managers.find(i => i.id === managerId);

      if (saved.countyId) this.county = this.counties.find(i => i.id === saved.countyId);
      if (saved.authorityId) this.authority = this.authorities.find(i => i.id === saved.authorityId);

      this.$nextTick(() => {
        this.emit();
        this.ready = true;
      });
    });
  },
};
</script>

<template>
  <form @submit.prevent="emit" class="p-3 pt-2 pb-1 mb-10 bg-white rounded-lg shadow">
    <div class="flex -mx-1">
      <div class="flex-grow px-1">
        <FormControl id="search" label="Search" theme="ring" :active="search.length" hasClear @clear="search = ''">
          <input type="text" id="search" v-model.trim="search" />
        </FormControl>
      </div>
      <div class="w-1/4 px-1">
        <FormControl id="manager" label="Region / Manager" theme="ring" :active="manager.id">
          <select id="manager" v-model="manager">
            <option :value="{}">Any</option>
            <option v-for="item in managers" :key="item.key" :disabled="item.disabled" :value="item">
              {{ item.name }}
            </option>
          </select>
        </FormControl>
      </div>
      <div class="w-1/4 px-1">
        <FormControl slug="county" label="County" theme="ring" :active="county.id">
          <FormTypeSelect v-model="county" :items="counties" id="county" />
        </FormControl>
      </div>
      <div class="w-1/4 px-1">
        <FormControl slug="authority" label="Authority" theme="ring" :active="authority.id">
          <FormTypeSelect v-model="authority" :items="authorities" id="authority" />
        </FormControl>
      </div>
    </div>

    <div class="flex flex-wrap items-center justify-between -mx-1">
      <div class="px-1 flex-grow"><MultiCheck v-model="status" :items="accountStatus" label="Account Status" /></div>
      <div class="px-1 flex-grow"><MultiCheck v-model="accountType" :items="accountTypes" label="Account Type" /></div>
      <div class="px-1 flex-grow"><MultiCheck v-model="frame" :items="frames" label="Licence State" /></div>
      <div class="px-1 flex-grow"><MultiCheck v-model="licences" :items="peLicences" label="PE Licence" /></div>
      <div class="px-1 flex-grow"><MultiCheck v-model="licences" :items="brLicences" label="BURSTS Licence" /></div>
      <div class="px-1 flex-grow xl:grow-0">
        <MultiCheck v-model="wondeRealPeStatus" :items="hasWonde" label="Wonde - real Pe" />
      </div>
      <div class="px-1 flex-grow xl:grow-0">
        <MultiCheck v-model="wondeBurstsStatus" :items="hasWonde" label="Wonde - BURSTS" />
      </div>

      <div class="btns4 p-2 py-3 flex-grow">
        <button type="button" @click="reset()" class="btn4-clear">Reset</button>
        <button class="btn4 bg-pe" type="submit">Search</button>
      </div>
    </div>
  </form>
</template>
