<script>
import ActivityPlanning from '@/content/components/parts/ActivityPlanning.vue';
import LessonPreview from '@/content/components/previews/LessonPreview.vue';
import SkillLevels from '@/content/components/partials/SkillLevels.vue';
import EquipmentList from '@/content/components/partials/EquipmentList.vue';
import AbilityGoals from '@/content/components/partials/AbilityGoals.vue';
import SkillGoals from '@/content/components/partials/SkillGoals.vue';
import TextBlock from '@/content/components/partials/TextBlock2.vue';
import routeInfo from '@/mixins/routeInfo.js';

export default {
  mixins: [routeInfo],
  props: ['lesson', 'ability', 'skill'],
  components: { LessonPreview, TextBlock, EquipmentList, AbilityGoals, SkillGoals, ActivityPlanning, SkillLevels },

  computed: {
    learningColour() {
      return this.isGym ? 'gymcard' : 'physical';
    },

    params() {
      return this.$route.params;
    },
  },
};
</script>

<template>
  <div class="container">
    <LessonPreview :lesson="lesson" class="mb-2 print:hidden" />

    <div class="px-2 mb-2 bg-white curves print:p-0 print:mb-0">
      <div class="py-3 text-3xl font-bold text-center">Learning Goals and Points</div>

      <div :class="{ 'flex-wrap': !isPlay }" class="t-row">
        <TextBlock icon="cog" :colour="ability.slug">
          <h2 v-if="isPlay" class="teacher-title text-center">{{ lesson.unit.island }} Island</h2>
          <h2 v-else class="teacher-title text-center">{{ ability.title }} Skills</h2>
          <AbilityGoals :ability="ability" :lesson="lesson" class="text-lg" />
        </TextBlock>

        <TextBlock :colour="learningColour" highlight>
          <SkillGoals :skill="skill" class="text-lg">
            <SkillLevels class="my-6 text-base" flex />
          </SkillGoals>
        </TextBlock>
      </div>
    </div>

    <!-- equipment -->
    <TextBlock icon="equipment" colour="pe">
      <h2 class="teacher-title">Equipment</h2>
      <EquipmentList :list="lesson.equipment" class="rdct rdct-teacher" />
    </TextBlock>

    <!-- organisation -->
    <TextBlock v-if="lesson.organisation" icon="organisation" colour="physical">
      <h2 class="teacher-title">Organisation</h2>
      <div v-html="lesson.organisation" class="rdct rdct-teacher"></div>
    </TextBlock>

    <!-- teaching tips -->
    <TextBlock v-if="lesson.tips" class="t-wide">
      <h2 class="teacher-title">Teaching Tips</h2>
      <div v-html="lesson.tips" class="rdct rdct-teacher"></div>
    </TextBlock>

    <ActivityPlanning
      v-for="part in lesson.parts"
      :key="part.id"
      :lesson="lesson"
      :ability="ability"
      :part="part"
      overview
      class="my-5"
    />
  </div>
</template>
